import { UserProfileContext } from '@/contexts'
import { useEditUser } from '@/hooks/useEditUser'
import { useTranslation } from '@/utils/i18n'
import { IconButton } from '@chakra-ui/button'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  MenuGroup,
  MenuItem
} from '@chakra-ui/menu'
import {
  Checkbox,
  HStack,
  Switch,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { useRouter } from 'next/router'

import React, { useContext } from 'react'
import { MdMoreVert } from 'react-icons/md'
import { useConfirm } from '../dialogProvider/useConfirm'
import { LinkUserModal } from './linkUserModal'
import { PhotoIdModal } from './photoIdModal'

export interface IUserProfileMenuProps {
  mode: 'compact' | 'normal'
}

export const UserProfileMenu = ({ mode }: IUserProfileMenuProps) => {
  const t = useTranslation()
  const { user, refetchUser } = useContext(UserProfileContext)
  const { editUser } = useEditUser()
  const toast = useToast()
  const linkAccountDisclosure = useDisclosure()
  const photoIdDisclosure = useDisclosure()

  const { confirm, dismiss } = useConfirm()
  const router = useRouter()

  const handleVNSChange = async (e) => {
    await editUser({
      variables: {
        nonSafetyVial: e.target.checked,
        oAuthId: user?.oAuthId
      }
    })
    refetchUser()
  }

  const handleDeceasedChange = async (e) => {
    await editUser({
      variables: {
        deceased: e.target.checked,
        oAuthId: user?.oAuthId
      }
    })
    refetchUser()
  }

  const handleDeleteAccount = () => {
    confirm({
      title: t('common.areYouSureYouWantToDeleteIt'),
      confirmText: t('common.delete'),
      colorScheme: 'red',
      size: 'md',
      onConfirm: async () => {
        try {
          await editUser({
            variables: {
              oAuthId: user?.oAuthId,
              killUser: true
            }
          })
          dismiss()
          router.push('/users')
        } catch (e) {
          console.error(e)
        }
      }
    })
  }

  const handleEditUser = () => {
    router.push(`/users/${user.oAuthId.substring(6)}/edit`)
  }

  const sendResetPasswordForm = () => {
    const uri = process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL
    const clientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
    try {
      fetch(`${uri}/dbconnections/change_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          client_id: `${clientId}`,
          email: user?.email,
          connection: 'Username-Password-Authentication'
        })
      }).then((d) => {
        toast({
          title: 'Password reset form successfully sent!!',
          status: 'info',
          isClosable: true,
          position: 'top'
        })
      })
    } catch (error) {
      toast({
        title: 'Having issue sending password reset form',
        status: 'error',
        position: 'top'
      })
      console.error(error)
    }
  }

  return (
    <>
      <Menu>
        <MenuButton>
          <IconButton
            aria-label="more"
            icon={<MdMoreVert />}
            bg="white"
            size={mode === 'compact' ? 'sm' : 'md'}
            borderWidth={1}
            color="gray.500"
            _hover={{ bg: 'gray.100' }}
            fontSize="2xl"
          />
        </MenuButton>
        <MenuList boxShadow="lg" fontSize="sm">
          <MenuGroup>
            <HStack w="full" px={4} py={1} spacing={2}>
              <Switch
                isChecked={user?.nonSafetyVial}
                onChange={handleVNSChange}
              />
              <Text>VNS</Text>
            </HStack>
          </MenuGroup>
          <MenuDivider />
          <MenuItem onClick={() => photoIdDisclosure.onOpen()}>
            {t('profile.photoId')}
          </MenuItem>
          <MenuItem onClick={() => handleEditUser()}>
            {t('profile.editUser')}
          </MenuItem>
          <MenuItem onClick={() => linkAccountDisclosure.onOpen()}>
            {t('common.linkedAccounts')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              sendResetPasswordForm()
            }}
          >
            {t('profile.sendPasswordResetForm')}
          </MenuItem>

          <MenuDivider />
          <MenuGroup>
            <HStack w="full" px={4} py={1} spacing={2}>
              <Checkbox
                isChecked={user?.deceased}
                onChange={handleDeceasedChange}
              />
              <Text>{t('common.deceased')}</Text>
            </HStack>
          </MenuGroup>
          <MenuDivider />
          <MenuItem color="red.600" onClick={handleDeleteAccount}>
            {t('profile.deleteAccount')}
          </MenuItem>
        </MenuList>
      </Menu>
      <LinkUserModal
        isOpen={linkAccountDisclosure.isOpen}
        onClose={linkAccountDisclosure.onClose}
      />
      <PhotoIdModal
        isOpen={photoIdDisclosure.isOpen}
        onClose={photoIdDisclosure.onClose}
      />
    </>
  )
}
