import React from 'react';
import { Box } from '@chakra-ui/react';
import { IoFolderOpen } from 'react-icons/io5';
import { AirixLogo } from '@/helpers/logo';

const AirixFolderIcon = () => {
    return (
        <Box position="relative" height="100px" width="100px">
            <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                zIndex={1}

            >
                <Box fontSize={70} color="blue.500">
                    <IoFolderOpen />
                </Box>
            </Box>
            <Box
                position="absolute"
                top="57%"
                left="50%"
                transform="translate(-50%, -50%)"
                zIndex={2}
            >
                <AirixLogo />
            </Box>
        </Box>
    );
}

export default AirixFolderIcon;