import React, { useContext } from 'react'
import { AddressBox } from './addressBox'
import { AddUpdateAddress } from './addUpdateAddress'
import { IAddress } from '@/utils/types'
import { UserProfileContext } from '@/contexts'
import {
  useAddAddress,
  useArchive,
  useEditAddress,
  useGetAddresses
} from '@/hooks'

import {
  Box,
  Button,
  Flex,
  Grid,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { useTranslation } from '@/utils/i18n'

export const UserAddress = () => {
  const t = useTranslation()
  const { userId } = useContext(UserProfileContext)
  const { data, refetch } = useGetAddresses({
    oAuthId: userId
  })
  const addresses: IAddress[] = data?.getAddresses
  const { editAddress } = useEditAddress()
  const { addAddress } = useAddAddress()
  const { archive } = useArchive()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const refetchAll = () => {
    refetch({ oAuthId: userId })
  }

  const handleEditAddress = async (a, addressId) => {
    await editAddress({
      variables: {
        ...a,
        addressId: addressId,
        oAuthId: userId
      }
    })
    refetchAll()
  }

  const hanldeDeleteAddress = async (addressId) => {
    await editAddress({
      variables: {
        deactivated: true,
        addressId: addressId,
        oAuthId: userId
      }
    })
    refetchAll()
  }

  const handleArchive = async (addressId) => {
    await archive(addressId, 'address')
    refetchAll()
  }

  const handleAddressSubmit = async (value) => {
    await addAddress({
      variables: {
        addressInput: value,
        oAuthId: userId
      }
    })
    refetchAll()
  }

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        mb={4}
        bg="gray.50"
        borderRadius="md"
        p={4}
      >
        <VStack alignItems="start" spacing={1}>
          {' '}
          <Text
            fontSize="lg"
            fontWeight="medium"
            alignItems="flex-end"
          >
            {t('common.address')}
          </Text>
          <Text fontSize="sm" color="gray.500">
            {addresses?.length || t('profile.no')}
            {' '}
            {addresses?.length > 1 ? t('profile.addressesFound') : t('profile.addressFound')}
          </Text>
        </VStack>
        <Button colorScheme="blue" onClick={() => onOpen()}>
          {t('profile.addAddress')}
        </Button>
      </Flex>

      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        {addresses?.map((address) => (
          <AddressBox
            key={address?.addressId}
            address={address}
            onEdit={handleEditAddress}
            onDelete={hanldeDeleteAddress}
            onArchive={handleArchive}
          />
        ))}
      </Grid>
      <AddUpdateAddress
        isOpen={isOpen}
        type="new"
        onSubmit={handleAddressSubmit}
        onClose={onClose}
      />
    </Box>
  )
}
