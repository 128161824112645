import React, { useContext } from 'react'
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import { ITask } from '@/utils/types'
import { Loader } from '@/components'
import { useTranslation } from '@/utils/i18n'
import { useGetUserById } from '@/hooks/useGetUserById'
import { useGetInsuranceById } from '@/hooks/useGetInsuranceById'
import { useEditUser } from '@/hooks/useEditUser'
import { useEditInsurance } from '@/hooks/useEditInsurance'
import { PharmaciesContext } from '@/contexts/PharmacyProvider'
import { Tasks } from './Tasks'
import { useGetTasksCategories } from '@/hooks'
import { MdRemoveRedEye } from 'react-icons/md'

export interface ITaskModal {
  onDoneTask: (taskId: number) => void
  task: ITask
}

export const TaskModal = ({ onDoneTask, task }: ITaskModal) => {
  const { openOrder } = useContext(PharmaciesContext)
  const t = useTranslation()
  const [forceDisabled, setForceDisabled] = React.useState(false)
  const { editUser } = useEditUser()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { editInsurance } = useEditInsurance()
  const { data: taskTypes } = useGetTasksCategories()
  const typeOfTask = taskTypes?.taskCategories.find((taskType) => taskType.id === task.typeOfTask)
  const {
    data,
    loading
  } = typeOfTask?.name === 'insurance' ? useGetInsuranceById({ insuranceId: task.data.insuranceId }) : useGetUserById({ userId: task.oAuthId })

  return (
    <>
      <MdRemoveRedEye cursor="pointer" style={{ width: '20px', height: '20px' }} color="gray" onClick={onOpen} />

      {isOpen &&
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
          <ModalOverlay bg="blackAlpha.700"></ModalOverlay>
          <ModalContent overflow="hidden">
            {loading ? (
              <Loader fullWidth height="100px" />
            ) : (
              <>
                {' '}
                <ModalHeader>{`#${task?.taskId}`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{Tasks(task)[typeOfTask?.name]}</ModalBody>
                <ModalFooter>
                  <HStack spacing={2}>
                    <Button onClick={onClose}>{t('common.cancel')}</Button>
                    {typeOfTask?.name === 'insurance' && (
                      <Button
                        colorScheme="green"
                        onClick={async () => {
                          try {
                            await editInsurance({
                              variables: {
                                insuranceId: data?.getInsurance?.insuranceId,
                                verified: true
                              }
                            })
                            setForceDisabled(true)
                          } catch (e) {
                            console.error(e)
                          }
                        }}
                        ml={3}
                        disabled={loading || !data?.getInsurance || data.getInsurance.verified || forceDisabled}
                      >
                        {data?.getInsurance?.verified === true ? t('common.insuranceAlreadyApproved') : t('common.approve')}
                      </Button>
                    )}
                    {typeOfTask?.name === 'idVerification' && (
                      <Button
                        colorScheme="green"
                        onClick={async () => {
                          try {
                            await editUser({
                              variables: {
                                oAuthId: data?.user?.oAuthId,
                                verifiedPhotoIdNotification: true,
                                verifiedPhotoId: 'verified'
                              }
                            })
                            setForceDisabled(true)
                          } catch (e) {
                            console.error(e)
                          }
                        }}
                        ml={3}
                        disabled={data?.user?.verifiedPhotoId === 'verified'}
                      >
                        {data?.user?.verifiedPhotoId === 'verified' ? t('common.photoAlreadyVerified') : t('common.verifyPhotoId')}
                      </Button>
                    )}
                    {['order', 'paid'].includes(typeOfTask?.name) && <Button
                      onClick={() => openOrder(task.data?.order?.orderId || task.data?.orderId)}>{t('tasks.order')}</Button>}
                    <Button
                      colorScheme="blue"
                      onClick={() => {
                        onDoneTask(task.taskId)
                        onClose()
                      }}
                      ml={3}
                    >
                      {t('tasks.doneTask')}
                    </Button>
                  </HStack>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>}
    </>
  )
}
