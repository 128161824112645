import React, { useEffect } from 'react'
import { Image } from '@chakra-ui/image'
import { useConfirm } from '../dialogProvider/useConfirm'
import { Flex } from '@chakra-ui/layout'
import { useGetImages } from '@/hooks/useGetImages'
import { HStack } from '@chakra-ui/react'
import { useTranslation } from '@/utils/i18n'

export interface ITaskImagesProps {
  imgIds: number[]
}
export const TaskImages = ({ imgIds }: ITaskImagesProps) => {
  const t = useTranslation()
  const { confirm } = useConfirm()

  const { imageUris } = useGetImages({ imagesIds: imgIds })

  return imageUris ? (
    <HStack spacing={1}>
      {imageUris?.map((imageUri) => (
        <Image
          py={3}
          key={imageUri?.id}
          src={imageUri?.uri}
          fallbackSrc="https://via.placeholder.com/100"
          width="100px"
          height="150px"
          bg="gray.100"
          borderWidth={1}
          borderColor="blue.500"
          objectFit="cover"
          onClick={() => {
            confirm({
              title: `#${imageUri?.id}`,
              size: 'xl',
              noAction: true,
              body: (
                <Flex w="full" justifyContent="center">
                  <Image
                    src={imageUri?.uri}
                    fallbackSrc="https://via.placeholder.com/400"
                    width="400px"
                    height="auto"
                  />
                </Flex>
              ),
              onConfirm: () => {},
              confirmText: t('tasks.done')
            })
          }}
        />
      ))}
    </HStack>
  ) : (
    <></>
  )
}
