import { gql, useQuery } from '@apollo/client'

const DELIVERIES = gql`
  query {
    deliveries {
      orderId
      oAuthId
      orderItems {
        orderItemId
        prescriptionId
        refillQuantity
        fillDate
        priceInCents
      }
      state
      orderType
      comment
      addressId
      time
      stripeSource
      insuranceId
      createdAt
      userFirstName
      userLastName
    }
  }
`

export const useGetDeliveries = () =>
  useQuery(DELIVERIES, {
    fetchPolicy: 'network-only'
  })
