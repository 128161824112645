import {
  Box,
  ButtonGroup,
  HStack,
  IconButton,
  Text
} from '@chakra-ui/react'
import moment from 'moment'
import React, { useState } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'
import { MdCheck, MdClose } from 'react-icons/md'
import { useTranslation } from '@/utils/i18n'

export interface IEditDeliveryDateProps {
  defaultDate: Date
  onChange: (date: Date) => void
}
export const EditDeliveryDate = ({
  defaultDate,
  onChange
}: IEditDeliveryDateProps) => {
  const t = useTranslation()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [selectedDay, setSelectedDay] = useState<Date>(
    moment(defaultDate).toDate()
  )

  const handleDayChange = (day: Date) => {
    setSelectedDay(moment(day).toDate())
    setShowConfirm(true)
  }

  const handleSave = () => {
    setShowConfirm(false)
    onChange(selectedDay)
  }

  const handleCancel = () => {
    setSelectedDay(moment(defaultDate).toDate())
    setShowConfirm(false)
  }
  return (
    <Box>
      <Text fontSize="sm" color="gray.500" mb={1}>
      {t('orders.deliveryTime')}
      </Text>
      <HStack spacing={2}>
        <Box width="200px" className="daypicker">
          <DayPickerInput
            value={selectedDay}
            onDayChange={handleDayChange}
            formatDate={() => MomentLocaleUtils.formatDate(selectedDay, 'LL', t('common.locale'))}
            parseDate={MomentLocaleUtils.parseDate}
            dayPickerProps={{
              selectedDays: selectedDay,
              disabledDays: {
                daysOfWeek: [0, 6]
              },
              localeUtils: MomentLocaleUtils,
              locale: t('common.locale')
            }}
          />
        </Box>
        {showConfirm && (
          <ButtonGroup justifyContent="center" size="sm" ml={2}>
            <IconButton
              aria-label="check"
              icon={<MdCheck />}
              onClick={handleSave}
            />
            <IconButton
              aria-label="uncheck"
              icon={<MdClose />}
              onClick={handleCancel}
            />
          </ButtonGroup>
        )}
      </HStack>
    </Box>
  )
}
