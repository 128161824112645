import React, { useState, useEffect } from 'react'
import { Text, Box, Flex, Button, Menu, MenuItem, MenuList, IconButton, MenuButton, useDisclosure } from '@chakra-ui/react'
import { ColDef, DataTable, SearchBox } from '@/components'
import { useTranslation } from '@/utils/i18n'
import { MdAdd, MdMoreHoriz } from 'react-icons/md'
import CreateDrug from './CreateDrug'
import { useGetDrugs } from '@/hooks'
import { Select } from '@chakra-ui/select'

const Drugs = () => {
  const t = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [searchText, setSearchText] = useState<string>('')
  const [language, setLanguage] = useState<string>('en')
  const [entity, setEntity] = useState<Record<string, any>>(null)
  const [queries, setQueries] = useState({
    page: 1,
    perPage: 10
  })

  const { data, loading, refetch } = useGetDrugs({
    query: searchText,
    language,
    ...queries
  })

  const colNames = ['drugId', 'drugIdentificationNumber', 'name', 'genericInfo', 'legalStatusQc', 'form', 'strength']

  const handleOpenModal = ({ row, type }: any) => {
    onOpen()
    setEntity(type === 'edit' ? row : null)
  }

  const updateQueries = (key, value) => {
    setQueries((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  useEffect(() => {
    refetch()
  }, [queries])

  const cols: ColDef<any>[] = [
    ...colNames.map((name: string) => ({
      colName: t(`drugs.${name}`),
      render: (row) => <Text>{name === 'genericInfo' ? row[name]?.name : row[name]}</Text>,
      key: name
    })),
    {
      colName: '',
      render: (row) => (
        <Menu>
          <MenuButton as={IconButton} size="sm" fontSize="lg" aria-label="Options" icon={<MdMoreHoriz />} variant="outline" />
          <MenuList fontSize="md">
            <MenuItem onClick={() => handleOpenModal({ row, type: 'edit' })}>{t('drugs.editDrug')}</MenuItem>
          </MenuList>
        </Menu>
      ),
      key: 'menu'
    }
  ]

  return (
    <>
      <Box p={2}>
        <Box px={4} pb={2}>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex gridGap={6} alignItems="center">
              <Box>
                <SearchBox placeholder={t('drugs.searchDrugs')} searchValue={searchText} onSearch={setSearchText} />
              </Box>
              <Select size="sm" value={language} onChange={(val) => setLanguage(val.target.value)}>
                <option value="en">{t('common.english')}</option>
                <option value="fr">{t('common.french')}</option>
              </Select>
            </Flex>
            <Button leftIcon={<MdAdd />} variant="solid" size="sm" colorScheme="blue" onClick={handleOpenModal}>
              {t('drugs.createDrug')}
            </Button>
          </Flex>
        </Box>
        <Box bg="white" m={4} borderWidth={1} borderRadius="md">
          <DataTable
            cols={cols}
            rowPadding={3}
            tableKey="drugId"
            rows={data?.drugs?.drugDetails}
            isLoading={loading}
            serverPaginate={{
              totalCount: data?.drugs?.total_count,
              size: queries.perPage,
              page: queries.page - 1
            }}
            onPageUpdate={(val) => updateQueries('page', val + 1)}
            onSizeUpdate={(val) => {
              setQueries({
                perPage: val,
                page: 1
              })
            }}
          ></DataTable>
        </Box>
      </Box>

      {isOpen && <CreateDrug isOpen={isOpen} onClose={onClose} entity={entity} refetch={refetch} />}
    </>
  )
}

export default Drugs
