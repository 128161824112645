import React, { useState } from 'react'
import { MdClose, MdModeEdit } from 'react-icons/md'
import {
  Box,
  HStack,
  IconButton,
  Input,
  Kbd,
  Text
} from '@chakra-ui/react'
import { useTranslation } from '@/utils/i18n'

export interface IEditQtyProps {
  label: string
  fieldLabel1?: string
  fieldLabel2?: string
  value: string
  defaultValue1?: number
  defaultValue2?: number
  onChange?: (v1: number, v2: number) => void
  editable?: boolean
}

export const EditQty = ({
  fieldLabel1 = null,
  fieldLabel2 = null,
  label,
  value,
  defaultValue1 = 0,
  defaultValue2 = 0,
  onChange,
  editable = true
}: IEditQtyProps) => {
  const t = useTranslation()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [value1, setValue1] = useState<number>(defaultValue1)
  const [value2, setValue2] = useState<number>(defaultValue2)

  const handleKeyChange = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false)
      onChange(value1, value2)
    }
  }

  const handleValue1OnChange = (e) => {
    setValue1(+e.target.value)
  }

  const handleValue2OnChange = (e) => {
    setValue2(+e.target.value)
  }

  const handleEdit = () => setIsEditing(true)

  const handleCancel = () => setIsEditing(false)

  return (
    <Box
      bg="gray.50"
      borderWidth={0}
      borderColor="gray.200"
      p={2}
      borderRadius="md"
    >
      <HStack w="full" justifyContent="space-between">
        <Text
          fontSize="sm"
          color="gray.400"
          mb={1}
        >
          {label}
        </Text>
        {!isEditing && editable && (
          <IconButton
            aria-label="uncheck"
            size="sm"
            color="gray.500"
            fontSize="md"
            icon={<MdModeEdit />}
            onClick={handleEdit}
          />
        )}
        {isEditing && (
          <IconButton
            aria-label="close"
            size="sm"
            color="gray.500"
            fontSize="md"
            icon={<MdClose />}
            onClick={handleCancel}
          />
        )}
      </HStack>
      {!isEditing && <Text fontWeight="medium">{value}</Text>}
      {isEditing && (
        <Box>
          {fieldLabel1 && (
            <Box mt={1}>
              <Text
                fontSize="x-small"
                color="gray.500"
                textTransform="uppercase"
                fontWeight="semibold"
              >
                {fieldLabel1}
              </Text>
              <Input
                value={value1}
                bg="white"
                placeholder={fieldLabel1}
                onKeyDown={handleKeyChange}
                onChange={handleValue1OnChange}
              />
            </Box>
          )}

          {fieldLabel2 && (
            <Box mt={1}>
              <Text
                fontSize="x-small"
                color="gray.500"
                textTransform="uppercase"
                fontWeight="semibold"
              >
                {fieldLabel2}
              </Text>
              <Input
                value={value2}
                bg="white"
                placeholder={fieldLabel2}
                onKeyDown={handleKeyChange}
                onChange={handleValue2OnChange}
              />
            </Box>
          )}

          <Text fontSize="sm" mt={1}>
            {t('prescription.press')} <Kbd>{t('prescription.keyboardEnter')}</Kbd> {t('prescription.toSave')}
          </Text>
        </Box>
      )}
    </Box>
  )
}
