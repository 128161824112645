import moment from 'moment'
import React from 'react'
import { IOrder } from '@/utils/types'
import { LabelInfo } from '@/components'
import { OrderModal } from './orderModal'
import {
  Badge,
  Box,
  Flex,
  Grid,
  HStack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useTranslation } from '@/utils/i18n'

export interface IOrderCard {
  order: IOrder
  mode?: 'normal' | 'compact'
}

export const renderStatus = (order: IOrder) => {
  if (order?.state === 'unapproved') {
    return 'red'
  }
  if (order?.state === 'approved') {
    return 'green'
  }
  if (order?.state === 'fulfilled') {
    return 'purple'
  }
  if (order?.state === 'shipped') {
    return 'teal'
  }
  if (order?.state === 'paid') {
    return 'blue'
  }
  return 'gray'
}
export const OrderCard = ({ order, mode }: IOrderCard) => {
  const t = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box
        borderWidth={1}
        p={mode !== 'compact' ? 6 : 2}
        bg="gray.50"
        borderColor="gray.200"
        borderRadius={6}
        borderLeftColor={`${renderStatus(order)}.200`}
        borderLeftWidth={4}
        onClick={() => onOpen()}
      >
        <Flex
          flexDirection={
            mode !== 'compact' ? 'row' : 'column-reverse'
          }
          justifyContent={
            mode !== 'compact' ? 'space-between' : 'start'
          }
          mb={2}
          alignItems={mode !== 'compact' ? 'center' : 'start'}
        >
          <HStack
            spacing={mode !== 'compact' ? 2 : 0}
            flexDirection={mode !== 'compact' ? 'row' : 'column'}
            alignItems={mode !== 'compact' ? 'center' : 'start'}
          >
            <Text fontSize="md" fontWeight="semibold">
              {t(`orders.${order?.orderType}`)}
            </Text>
            <Text fontSize="sm" fontWeight="normal">
              {t('orders.placedOn')}{' '}
              {moment(order?.time).format('LLL')}
            </Text>
          </HStack>
          <Text fontSize="md" fontWeight="normal" color="gray.500">
            # {order?.orderId}
          </Text>
        </Flex>

        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
          <LabelInfo
            label={t('common.status')}
            value={
              <Badge
                colorScheme={renderStatus(order)}
                variant="solid"
              >
                {t(`common.${order?.state}`)}
              </Badge>
            }
          />
          <LabelInfo
            label={t('orders.fillDate')}
            value={moment(order.time).format('LL')}
          />
          <LabelInfo
            label={t('common.comment')}
            value={order?.comment}
          />
        </Grid>
      </Box>
      {isOpen && (
        <OrderModal
          isOpen={isOpen}
          onClose={onClose}
          orderId={order?.orderId}
        />
      )}
    </>
  )
}
