export const posologyDictionary = {
  // VERB
  app: {
    en: 'apply',
    fr: 'appliquer'
  },
  garg: {
    en: 'gargle with 1 tablespoon (15ml) for 30 seconds',
    fr: 'gargariser avec 1 cueillère à soupe (15ml) pendant 30 secondes'
  },
  pr: {
    en: 'take',
    fr: 'prenez'
  },
  fr: {
    en: 'rub',
    fr: 'frotter'
  },
  do: {
    en: 'give',
    fr: 'donnez'
  },
  inst: {
    en: 'instill',
    fr: 'instiller'
  },
  ins: {
    en: 'insert',
    fr: 'inserer'
  },
  inj: {
    en: 'injection',
    fr: 'injection'
  },
  cr: {
    en: 'chew',
    fr: 'croquer'
  },
  bo: {
    en: 'drink',
    fr: 'boire'
  },
  loc: {
    en: 'local application',
    fr: 'en application locale'
  },
  apl: {
    en: 'apply locally',
    fr: 'en application locale'
  },
  ra: {
    en: 'affected area',
    fr: 'région affectée'
  },
  vag: {
    en: 'vaginal',
    fr: 'vaginale'
  },
  ds: {
    en: 'in',
    fr: 'dans'
  },

  // WHERE
  oos: {
    en: 'in the left eye',
    fr: "dans l'oeil gauche"
  },
  og: {
    en: 'in the left eye',
    fr: "dans l'oeil gauche"
  },
  os: {
    en: 'in the left eye',
    fr: "dans l'oeil gauche"
  },
  ood: {
    en: 'in the right eye',
    fr: "dans l'oeil droite"
  },
  od: {
    en: 'in the right eye',
    fr: "dans l'oeil droite"
  },
  ouu: {
    en: 'in both eyes',
    fr: 'dans les deux yeux'
  },
  aas: {
    en: 'in the left ear',
    fr: "dans l'oreille gauche"
  },
  aad: {
    en: 'in the right ear',
    fr: "dans l'oreille droite"
  },
  auu: {
    en: 'in both ears',
    fr: 'dans les deux oreilles'
  },
  po: {
    en: 'by the mouth',
    fr: 'par la bouche'
  },
  sl: {
    en: 'under the tongue',
    fr: 'sous la langue'
  },
  nar: {
    en: 'in each nostril',
    fr: 'dans chaque narine'
  },
  IM: {
    en: 'intramuscular',
    fr: 'intramusculaire'
  },
  SC: {
    en: 'subcutaneous',
    fr: 'souscutanee'
  },
  rec: {
    en: 'rectal',
    fr: 'rectale'
  },
  IV: {
    en: 'intraveous',
    fr: 'intraveineuse'
  },

  cas: {
    en: 'tablespoon',
    fr: 'cuillère à soupe'
  },
  cat: {
    en: 'teaspoon',
    fr: 'cuillère à thé'
  },

  // FORM
  amp: {
    en: 'ampule',
    fr: 'ampoule'
  },
  amps: {
    en: 'ampules',
    fr: 'ampoules'
  },
  co: {
    en: 'tablet',
    fr: 'comprimé'
  },
  cos: {
    en: 'tablets',
    fr: 'comprimés'
  },
  tab: {
    en: 'tablet',
    fr: 'comprimé'
  },
  tabs: {
    en: 'tablets',
    fr: 'comprimés'
  },
  cap: {
    en: 'capsule',
    fr: 'capsule'
  },
  dr: {
    en: 'pill',
    fr: 'dragée'
  },
  drs: {
    en: 'pills',
    fr: 'dragées'
  },
  caps: {
    en: 'capsules',
    fr: 'capsules'
  },
  inh: {
    en: 'inhalation',
    fr: 'inhalation'
  },
  inhs: {
    en: 'inhalations',
    fr: 'inhalations'
  },
  vap: {
    en: 'spray',
    fr: 'vaporisation'
  },
  vaps: {
    en: 'sprays',
    fr: 'vaporisations'
  },
  supp: {
    en: 'suppository',
    fr: 'suppositoire'
  },
  supps: {
    en: 'suppositories',
    fr: 'suppositoires'
  },
  tsp: {
    en: 'teaspoon (5ml)',
    fr: 'cuillère à the (5ml)'
  },
  tbs: {
    en: 'tablespoon (15ml)',
    fr: 'cuillère à soupe (15ml)'
  },
  g: {
    en: 'gram',
    fr: 'gramme'
  },
  gs: {
    en: 'grams',
    fr: 'grammes'
  },
  gtte: {
    en: 'drop',
    fr: 'goutte'
  },
  gttes: {
    en: 'drops',
    fr: 'gouttes'
  },
  lot: {
    en: 'lotion',
    fr: 'lotion'
  },
  cre: {
    en: 'cream',
    fr: 'crème'
  },
  ong: {
    en: 'ointment',
    fr: 'onguent'
  },
  ov: {
    en: 'ovule',
    fr: 'ovule'
  },
  ovs: {
    en: 'ovules',
    fr: 'ovules'
  },
  ui: {
    en: 'unit',
    fr: 'units'
  },

  // FREQUENCY
  qd: {
    en: 'once daily',
    fr: '1 fois par jour'
  },
  die: {
    en: 'once daily',
    fr: '1 fois par jour'
  },
  bid: {
    en: 'twice daily',
    fr: '2 fois par jour'
  },
  tid: {
    en: '3 times daily',
    fr: '3 fois par jour'
  },
  qid: {
    en: '4 times daily',
    fr: '4 fois par jour'
  },
  'd-bid': {
    en: 'once or twice a day',
    fr: '1 ou 2 fois par jour'
  },
  'b-tid': {
    en: '2 or 3 times a day',
    fr: '2 ou 3 fois par jour'
  },
  'b-qid': {
    en: '2 to 4 times a day',
    fr: '2 a 4 fois par jour'
  },
  't-qid': {
    en: '3 to 4 times a day',
    fr: '3 a 4 fois par jour'
  },
  q2j: {
    en: 'every 2 days',
    fr: 'aux 2 jours'
  },
  q3j: {
    en: 'every 3 days',
    fr: 'aux 3 jours'
  },
  '1-2': {
    en: '1 or 2',
    fr: '1 ou 2'
  },
  '2-3': {
    en: '2 or 3',
    fr: '2 ou 3'
  },
  '3-4': {
    en: '3 or 4',
    fr: '3 ou 4'
  },
  '4-5': {
    en: '4 or 5',
    fr: '4 ou 5'
  },
  '5-6': {
    en: '5 or 6',
    fr: '5 ou 6'
  },
  fps: {
    en: 'times a week',
    fr: 'fois par semaine'
  },
  mjs: {
    en: 'same day every week',
    fr: 'même jour chaque semaine'
  },
  '1/sem': {
    en: 'once a week',
    fr: '1 fois par semaine'
  },
  '2/sem': {
    en: 'twice a week',
    fr: '2 fois par semaine'
  },
  '3/sem': {
    en: 'three times a week',
    fr: '3 fois par semaine'
  },
  qsem: {
    en: 'every week',
    fr: 'à chaque semaine'
  },
  qam: {
    en: 'every morning',
    fr: 'tous les avant-midi'
  },
  stat: {
    en: 'immediately',
    fr: 'immédiatement'
  },

  // PRN
  prn: {
    en: 'as needed',
    fr: 'si besoin'
  },
  prd: {
    en: 'as needed for pain',
    fr: 'si besoin contre douleur'
  },
  pri: {
    en: 'as needed for insomnia',
    fr: 'si besoin contre insomnie'
  },

  // INTERVAL
  q1h: {
    en: 'every hour',
    fr: 'a chaque heure'
  },
  q2h: {
    en: 'every 2 hours',
    fr: 'aux 2 heures'
  },
  q3h: {
    en: 'every 3 hours',
    fr: 'aux 3 heures'
  },
  q4h: {
    en: 'every 4 hours',
    fr: 'aux 4 heures'
  },
  q5h: {
    en: 'every 5 hours',
    fr: 'aux 5 heures'
  },
  q6h: {
    en: 'every 6 hours',
    fr: 'aux 6 heures'
  },
  q8h: {
    en: 'every 8 hours',
    fr: 'aux 8 heures'
  },
  q12h: {
    en: 'every 12 hours',
    fr: 'aux 12 heures'
  },
  'q1-2h': {
    en: 'every 1 to 2 hours',
    fr: 'toutes les 1 à 2 heures'
  },
  'q2-3h': {
    en: 'every 2 to 3 hours',
    fr: 'aux 2 à 3 heures'
  },
  'q4-5h': {
    en: 'every 4 to 5 hours',
    fr: 'aux 4 à 5 heures'
  },
  'q4-6h': {
    en: 'every 4 to 6 hours',
    fr: 'aux 4 à 6 heures'
  },
  'q6-8h': {
    en: 'every 6 to 8 hours',
    fr: 'aux 6 à 8 heures'
  },

  // WHEN
  am: {
    en: 'in the morning',
    fr: 'le matin'
  },
  midi: {
    en: 'at noon',
    fr: 'le midi'
  },
  pm: {
    en: 'in the afternoon',
    fr: 'en apres-midi'
  },
  hs: {
    en: 'at bedtime',
    fr: 'au coucher'
  },
  ms: {
    en: 'morning and night',
    fr: 'matin et soir'
  },
  ac: {
    en: 'before eating',
    fr: 'avant le repas'
  },
  av: {
    en: 'before',
    fr: 'avant'
  },
  cc: {
    en: 'while eating',
    fr: 'en mangeant'
  },
  em: {
    en: 'while eating',
    fr: 'en mangeant'
  },
  pc: {
    en: 'after eating',
    fr: 'apres le repas'
  },
  rep: {
    en: 'at mealtimes',
    fr: 'aux repas'
  },
  aj: {
    en: 'on an empty stomach',
    fr: 'a jeun'
  },
  aje: {
    en: 'on an empty stomach 1 hour before or 2 hours after meals',
    fr: 'a jeun 1 heure avant ou 2 heures après les repas'
  },
  reg: {
    en: 'regularly',
    fr: 'regulierement'
  },
  mhj: {
    en: 'same time every day',
    fr: 'même heure chaque jour'
  },
  dej: {
    en: 'at breakfast',
    fr: 'au déjeuner'
  },
  din: {
    en: 'at lunch',
    fr: 'au diner'
  },
  sou: {
    en: 'at supper',
    fr: 'au souper'
  },
  des: {
    en: 'at breakfast and at supper',
    fr: 'au déjeuner et au souper'
  },

  // Duration
  x: {
    en: 'for',
    fr: 'pendant'
  },
  '2j': {
    en: 'for 2 days',
    fr: 'pendant 2 jours'
  },
  '3j': {
    en: 'for 3 days',
    fr: 'pendant 3 jours'
  },
  '4j': {
    en: 'for 4 days',
    fr: 'pendant 4 jours'
  },
  '5j': {
    en: 'for 5 days',
    fr: 'pendant 5 jours'
  },
  '6j': {
    en: 'for 6 days',
    fr: 'pendant 6 jours'
  },
  '7j': {
    en: 'for 7 days',
    fr: 'pendant 7 jours'
  },
  '8j': {
    en: 'for 8 days',
    fr: 'pendant 8 jours'
  },
  '9j': {
    en: 'for 9 days',
    fr: 'pendant 9 jours'
  },
  '10j': {
    en: 'for 10 days',
    fr: 'pendant 10 jours'
  },
  '14j': {
    en: 'for 14 days',
    fr: 'pendant 14 jours'
  },
  jrs: {
    en: 'days',
    fr: 'jours'
  },
  jr: {
    en: 'day',
    fr: 'jour'
  },
  sem: {
    en: 'week',
    fr: 'semaine'
  },
  sems: {
    en: 'weeks',
    fr: 'semaines'
  },
  mo: {
    en: 'month',
    fr: 'mois'
  },
  mos: {
    en: 'months',
    fr: 'mois'
  },

  // general tips
  ba: {
    en: 'shake well',
    fr: 'bien agiter'
  },
  ecm: {
    en: 'in a thin layer',
    fr: 'en couche mince'
  },
  pu: {
    en: 'and then',
    fr: 'puis'
  },
  ref: {
    en: '(refrigerate)',
    fr: '(réfrigérer)'
  },
  uc: {
    en: 'as directed by your doctor',
    fr: 'tel que prescrit par votre médecin'
  },
  up: {
    en: 'for professional use',
    fr: 'pour usage professionnel'
  },

  // CONDITIONS
  dia: {
    en: '(diabetes)',
    fr: '(diabète)'
  },
  cs: {
    en: '(blood circulation)',
    fr: '(circulation sanguine)'
  },
  coe: {
    en: '(heart)',
    fr: '(coeur)'
  },
  ost: {
    en: '(osteoporosis)',
    fr: '(ostéoporose)'
  },
  hor: {
    en: '(hormone)',
    fr: '(hormone)'
  },
  som: {
    en: '(drowsiness)',
    fr: '(somnolence)'
  },
  ai: {
    en: '(anti-inflammatory)',
    fr: '(anti-inflammatoire)'
  },
  ab: {
    en: '(antibiotic)',
    fr: '(antibiotique)'
  },
  af: {
    en: '(antifungal)',
    fr: '(antifongique)'
  },
  cor: {
    en: '(cortisone)',
    fr: '(cortisone)'
  },
  dou: {
    en: '(for pain)',
    fr: '(contre douleur)'
  },
  es: {
    en: '(stomach)',
    fr: '(estomac)'
  },
  con: {
    en: '(constipation)',
    fr: '(constipation)'
  },

  th: {
    en: '(thyroid)',
    fr: '(thyroide)'
  },
  ace: {
    en: '(anticoagulant)',
    fr: '(anticoagulant)'
  },
  cho: {
    en: '(cholesterol)',
    fr: '(cholestérol)'
  },
  all: {
    en: '(against allergies)',
    fr: '(contre les allergies)'
  },
  gla: {
    en: '(glaucoma)',
    fr: '(glaucome)'
  },
  tad: {
    en: '(blood pressure - diuretic)',
    fr: '(hypertension - diurétique)'
  },
  ta: {
    en: '(blood pressure)',
    fr: '(hypertension)'
  },
  diu: {
    en: '(diuretic)',
    fr: '(diurétique)'
  },
  rb: {
    en: 'rinse mouth after use',
    fr: 'rincer bouche après usage'
  },

  cipro: {
    en: '1 tablet twice a day at breakfast and suppertime',
    fr: '1 comprimé deux fois par jour au déjeuner et au souper'
  },
  cipro2: {
    en: '1 tablet twice a day at breakfast and suppertime for 1 to 3 days if severe diarrhea (fever, blood in stool, or 3+ episodes in 24h)',
    fr: '1 comprimé deux fois par jour au déjeuner et au souper pendant 1 à 3 jours si diarrhée sévère (fièvre, sang dans les selles, ou 3+ épisodes en 24h)'
  },
  ciproxl: {
    en: '2 tablets once a day during a meal',
    fr: '2 comprimés une fois par jour en mangeant'
  },
  zithro: {
    en: '2 tablets at once and 1 tablet every day during 4 days (at the same time every day)',
    fr: '2 comp. immédiatement puis 1 comp par jour durant 4 jours (à la même heure chaque jour)'
  },
  zithro2: {
    en: '2 tablets once daily for 1 to 3 days if severe diarrhea (fever, blood in stool, or 3+ episodes in 24h)',
    fr: '2 comprimés une fois par jour pendant 1 à 3 jours si diarrhée sévère (fièvre, sang dans les selles, ou 3+ épisodes en 24h)'
  },
  acetaz: {
    en: '1/2 tablet twice daily while eating. Start 24 to 48 hours before ascent or before attaining 2500m. Continue for 48h after attaining peak or beginning descent',
    fr:
      "1/2 comprimé deux fois par jour en mangeant. Débuter 24 à 48 heures avant ascension ou avant d'atteindre 2500m. Continuer pendant 48h après d'avoir atteint le sommet ou d'avoir débuter la déscente"
  },
  imodium: {
    en: 'Take 2 tablets immediately and 1 tablet after every loose stool (max 8 tablets per 24h)',
    fr: 'Prenez 2 comprimés immédiatement puis 1 comprimé après chaque selle liquide (max 8 comprimés par 24h)'
  },
  epipen: {
    en:
      'Inject into the outer thigh and hold for several seconds at first sign of severe allergic reaction. Seek immediate medical attention. A second injection may be given after 5 to 15 minutes if the reaction continues or worsens.',
    fr:
      "Injecter dans la cuisse extérieur et tenir pour quelques secondes lors du premier signe d'allergie sévère. Consulter immédiatement un médecin. Une deuxième injection pourrait être administré après 5 à 15 minutes si la réaction persiste ou s'aggrave."
  },
  // NUMBERED CODES
  111: {
    en: '1 tablet once daily',
    fr: '1 comprimé 1 fois par jour'
  },
  112: {
    en: '1 tablet twice daily',
    fr: '1 comprimé 2 fois par jour'
  },
  113: {
    en: '1 tablet three times a day',
    fr: '1 comprimé 3 fois par jour'
  },
  114: {
    en: '1 tablet four times a day',
    fr: '1 comprimé 4 fois par jour'
  },
  115: {
    en: '1 tablet every 4 to 6 hours',
    fr: '1 comprimé aux 4 à 6 heures'
  },
  116: {
    en: '1 tablet every 4 hours',
    fr: '1 comprimé aux 4 heures'
  },
  117: {
    en: '1 tablet 1 or 2 times a day',
    fr: '1 comprimé 1 ou 2 fois par jour'
  },
  118: {
    en: '1 tablet 3 or 4 times a day',
    fr: '1 comprimé 3 ou 4 fois par jour'
  },
  119: {
    en: '1 tablet every second day',
    fr: '1 comprimé aux 2 jours'
  },

  121: {
    en: '1 capsule once daily',
    fr: '1 capsule 1 fois par jour'
  },
  122: {
    en: '1 capsule twice daily',
    fr: '1 capsule 2 fois par jour'
  },
  123: {
    en: '1 capsule three times a day',
    fr: '1 capsule 3 fois par jour'
  },
  124: {
    en: '1 capsule four times a day',
    fr: '1 capsule 4 fois par jour'
  },
  125: {
    en: '1 capsule every 4 to 6 hours',
    fr: '1 capsule aux 4 à 6 heures'
  },
  126: {
    en: '1 capsule every 4 hours',
    fr: '1 capsule aux 4 heures'
  },
  127: {
    en: '1 capsule 1 or 2 times a day',
    fr: '1 capsule 1 ou 2 fois par jour'
  },
  128: {
    en: '1 capsule 3 or 4 times a day',
    fr: '1 capsule 3 ou 4 fois par jour'
  },
  129: {
    en: '1 capsule every second day',
    fr: '1 capsule aux 2 jours'
  },

  131: {
    en: '1 teaspoon (5ml) once daily',
    fr: '1 cuillère à thé (5ml) 1 fois par jour'
  },
  132: {
    en: '1 teaspoon (5ml) twice daily',
    fr: '1 cuillère à thé (5ml) 2 fois par jour'
  },
  133: {
    en: '1 teaspoon (5ml) three times a day',
    fr: '1 cuillère à thé (5ml) 3 fois par jour'
  },
  134: {
    en: '1 teaspoon (5ml) four times a day',
    fr: '1 cuillère à thé (5ml) 4 fois par jour'
  },
  135: {
    en: '1 teaspoon (5ml) every 4 to 6 hours',
    fr: '1 cuillère à thé (5ml) aux 4 à 6 heures'
  },
  136: {
    en: '1 teaspoon (5ml) every 4 hours',
    fr: '1 cuillère à thé (5ml) aux 4 heures'
  },
  137: {
    en: '1 teaspoon (5ml) 1 or 2 times a day',
    fr: '1 cuillère à thé (5ml) 1 ou 2 fois par jour'
  },
  138: {
    en: '1 teaspoon (5ml) 3 or 4 times a day',
    fr: '1 cuillère à thé (5ml) 3 ou 4 fois par jour'
  },
  139: {
    en: '1 teaspoon (5ml) every second day',
    fr: '1 cuillère à thé (5ml) aux 2 jours'
  },

  151: {
    en: '1 tablespoon (15ml) once daily',
    fr: '1 cuillère à soupe (15ml) 1 fois par jour'
  },
  152: {
    en: '1 tablespoon (15ml) twice daily',
    fr: '1 cuillère à soupe (15ml) 2 fois par jour'
  },
  153: {
    en: '1 tablespoon (15ml) three times a day',
    fr: '1 cuillère à soupe (15ml) 3 fois par jour'
  },
  154: {
    en: '1 tablespoon (15ml) four times a day',
    fr: '1 cuillère à soupe (15ml) 4 fois par jour'
  },
  155: {
    en: '1 tablespoon (15ml) every 4 to 6 hours',
    fr: '1 cuillère à soupe (15ml) aux 4 à 6 heures'
  },
  156: {
    en: '1 tablespoon (15ml) every 4 hours',
    fr: '1 cuillère à soupe (15ml) aux 4 heures'
  },
  157: {
    en: '1 tablespoon (15ml) 1 or 2 times a day',
    fr: '1 cuillère à soupe (15ml) 1 ou 2 fois par jour'
  },
  158: {
    en: '1 tablespoon (15ml) 3 or 4 times a day',
    fr: '1 cuillère à soupe (15ml) 3 ou 4 fois par jour'
  },
  159: {
    en: '1 tablespoon (15ml) every second day',
    fr: '1 cuillère à soupe (15ml) aux 2 jours'
  },

  181: {
    en: '1 inhalation once daily',
    fr: '1 inhalation 1 fois par jour'
  },
  182: {
    en: '1 inhalation twice daily',
    fr: '1 inhalation 2 fois par jour'
  },
  183: {
    en: '1 inhalation three times a day',
    fr: '1 inhalation 3 fois par jour'
  },
  184: {
    en: '1 inhalation four times a day',
    fr: '1 inhalation 4 fois par jour'
  },
  185: {
    en: '1 inhalation every 4 to 6 hours',
    fr: '1 inhalation aux 4 à 6 heures'
  },
  186: {
    en: '1 inhalation every 4 hours',
    fr: '1 inhalation aux 4 heures'
  },
  187: {
    en: '1 inhalation 1 or 2 times a day',
    fr: '1 inhalation 1 ou 2 fois par jour'
  },
  188: {
    en: '1 inhalation 3 or 4 times a day',
    fr: '1 inhalation 3 ou 4 fois par jour'
  },
  189: {
    en: '1 inhalation every second day',
    fr: '1 inhalation aux 2 jours'
  },

  211: {
    en: '2 tablets once daily',
    fr: '2 comprimés 1 fois par jour'
  },
  212: {
    en: '2 tablets twice daily',
    fr: '2 comprimés 2 fois par jour'
  },
  213: {
    en: '2 tablets three times a day',
    fr: '2 comprimés 3 fois par jour'
  },
  214: {
    en: '2 tablets four times a day',
    fr: '2 comprimés 4 fois par jour'
  },
  215: {
    en: '2 tablets every 4 to 6 hours',
    fr: '2 comprimés aux 4 à 6 heures'
  },
  216: {
    en: '2 tablets every 4 hours',
    fr: '2 comprimés aux 4 heures'
  },
  217: {
    en: '2 tablets 1 or 2 times a day',
    fr: '2 comprimés 1 ou 2 fois par jour'
  },
  218: {
    en: '2 tablets 3 or 4 times a day',
    fr: '2 comprimés 3 ou 4 fois par jour'
  },
  219: {
    en: '2 tablets every second day',
    fr: '2 comprimés aux 2 jours'
  },

  221: {
    en: '2 capsules once daily',
    fr: '2 capsules 1 fois par jour'
  },
  222: {
    en: '2 capsules twice daily',
    fr: '2 capsules 2 fois par jour'
  },
  223: {
    en: '2 capsules three times a day',
    fr: '2 capsules 3 fois par jour'
  },
  224: {
    en: '2 capsules four times a day',
    fr: '2 capsules 4 fois par jour'
  },
  225: {
    en: '2 capsules every 4 to 6 hours',
    fr: '2 capsules aux 4 à 6 heures'
  },
  226: {
    en: '2 capsules every 4 hours',
    fr: '2 capsules aux 4 heures'
  },
  227: {
    en: '2 capsules 1 or 2 times a day',
    fr: '2 capsules 1 ou 2 fois par jour'
  },
  228: {
    en: '2 capsules 3 or 4 times a day',
    fr: '2 capsules 3 ou 4 fois par jour'
  },
  229: {
    en: '2 capsules every second day',
    fr: '2 capsules aux 2 jours'
  },

  231: {
    en: '2 teaspoons (10ml) once daily',
    fr: '2 cuillères à thé (10ml) 1 fois par jour'
  },
  232: {
    en: '2 teaspoons (10ml) twice daily',
    fr: '2 cuillères à thé (10ml) 2 fois par jour'
  },
  233: {
    en: '2 teaspoons (10ml) three times a day',
    fr: '2 cuillères à thé (10ml) 3 fois par jour'
  },
  234: {
    en: '2 teaspoons (10ml) four times a day',
    fr: '2 cuillères à thé (10ml) 4 fois par jour'
  },
  235: {
    en: '2 teaspoons (10ml) every 4 to 6 hours',
    fr: '2 cuillères à thé (10ml) aux 4 à 6 heures'
  },
  236: {
    en: '2 teaspoons (10ml) every 4 hours',
    fr: '2 cuillères à thé (10ml) aux 4 heures'
  },
  237: {
    en: '2 teaspoons (10ml) 1 or 2 times a day',
    fr: '2 cuillères à thé (10ml) 1 ou 2 fois par jour'
  },
  238: {
    en: '2 teaspoons (10ml) 3 or 4 times a day',
    fr: '2 cuillères à thé (10ml) 3 ou 4 fois par jour'
  },
  239: {
    en: '2 teaspoons (10ml) every second day',
    fr: '2 cuillères à thé (10ml) aux 2 jours'
  },

  251: {
    en: '2 tablespoons (30ml) once daily',
    fr: '2 cuillères à soupe (30ml) 1 fois par jour'
  },
  252: {
    en: '2 tablespoons (30ml) twice daily',
    fr: '2 cuillères à soupe (30ml) 2 fois par jour'
  },
  253: {
    en: '2 tablespoons (30ml) three times a day',
    fr: '2 cuillères à soupe (30ml) 3 fois par jour'
  },
  254: {
    en: '2 tablespoons (30ml) four times a day',
    fr: '2 cuillères à soupe (30ml) 4 fois par jour'
  },
  255: {
    en: '2 tablespoons (30ml) every 4 to 6 hours',
    fr: '2 cuillères à soupe (30ml) aux 4 à 6 heures'
  },
  256: {
    en: '2 tablespoons (30ml) every 4 hours',
    fr: '2 cuillères à soupe (30ml) aux 4 heures'
  },
  257: {
    en: '2 tablespoons (30ml) 1 or 2 times a day',
    fr: '2 cuillères à soupe (30ml) 1 ou 2 fois par jour'
  },
  258: {
    en: '2 tablespoons (30ml) 3 or 4 times a day',
    fr: '2 cuillères à soupe (30ml) 3 ou 4 fois par jour'
  },
  259: {
    en: '2 tablespoons (30ml) every second day',
    fr: '2 cuillères à soupe (30ml) aux 2 jours'
  },

  281: {
    en: '2 inhalations once daily',
    fr: '2 inhalations 1 fois par jour'
  },
  282: {
    en: '2 inhalations twice daily',
    fr: '2 inhalations 2 fois par jour'
  },
  283: {
    en: '2 inhalations three times a day',
    fr: '2 inhalations 3 fois par jour'
  },
  284: {
    en: '2 inhalations four times a day',
    fr: '2 inhalations 4 fois par jour'
  },
  285: {
    en: '2 inhalations every 4 to 6 hours',
    fr: '2 inhalations aux 4 à 6 heures'
  },
  286: {
    en: '2 inhalations every 4 hours',
    fr: '2 inhalations aux 4 heures'
  },
  287: {
    en: '2 inhalations 1 or 2 times a day',
    fr: '2 inhalations 1 ou 2 fois par jour'
  },
  288: {
    en: '2 inhalations 3 or 4 times a day',
    fr: '2 inhalations 3 ou 4 fois par jour'
  },
  289: {
    en: '2 inhalations every second day',
    fr: '2 inhalations aux 2 jours'
  },

  511: {
    en: '½ tablet once daily',
    fr: '½ comprimé 1 fois par jour'
  },
  512: {
    en: '½ tablet twice daily',
    fr: '½ comprimé 2 fois par jour'
  },
  513: {
    en: '½ tablet three times a day',
    fr: '½ comprimé 3 fois par jour'
  },
  514: {
    en: '½ tablet four times a day',
    fr: '½ comprimé 4 fois par jour'
  },
  515: {
    en: '½ tablet every 4 to 6 hours',
    fr: '½ comprimé aux 4 à 6 heures'
  },
  516: {
    en: '½ tablet every 4 hours',
    fr: '½ comprimé aux 4 heures'
  },
  517: {
    en: '½ tablet 1 or 2 times a day',
    fr: '½ comprimé 1 ou 2 fois par jour'
  },
  518: {
    en: '½ tablet 3 or 4 times a day',
    fr: '½ comprimé 3 ou 4 fois par jour'
  },
  519: {
    en: '½ tablet every second day',
    fr: '½ comprimé aux 2 jours'
  },
  // specific medications
  prolia: {
    en: 'a single subcutaneous injection of 60mg, once every 6 months',
    fr: 'une injection sous-cutanée de 60mg, une fois tous les 6 mois'
  },
  nic: {
    en: 'Chew a piece of gum whenever you feel the urge to smoke (maximum of 20 pieces a day)',
    fr: 'Mastiquez une gomme quand vous ressentez un besoin incontrolable de fumer (max 20/jour)'
  },
  laxaday: {
    en: 'Take 17g of powder (stirred in 250ml of liquid) once daily',
    fr: 'Prenez 17g de poudre (dilué dans 250ml de liquide) 1 fois par jour'
  },
  nitro: {
    en: '1 spray under the tongue if chest pain. Repeat in 5 minutes if needed (max 3 times in 15 minutes)',
    fr: '1 vaporisation sous la langue si douleur thoracique. Répéter après 5 minutes si besoin (max 3 fois en 15 minutes)'
  }
}
