import { useGetTransferById } from '@/hooks/useGetTransferById'
import { ITransfer } from '@/utils/types'
import { Box, VStack, Text } from '@chakra-ui/layout'
import React from 'react'
import { Loader } from '..'
import { TaskImages } from './taskImages'
import { useTranslation } from '@/utils/i18n'

export interface ITransferTaskProps {
  transferId: string
}
export const TransferTask = ({ transferId }: ITransferTaskProps) => {
  const t = useTranslation()
  const { data, loading } = useGetTransferById({
    transferId: transferId
  })

  const transfer: ITransfer = data?.getTransfer

  if (loading) {
    return (
      <Box p={10}>
        <Loader fullWidth />
      </Box>
    )
  }
  return (
    <VStack alignItems="start" spacing={2} p={4} bg="gray.50">
      <Box mb={2}>
        #{transferId}
        {transfer && <TaskImages imgIds={transfer?.imageIds} />}
      </Box>
      {transfer?.pharmacyName && (
        <VStack alignItems="start" spacing={0}>
          <Text fontSize="sm" color="gray.500">{t('tasks.pharmacy')}</Text>
          <Text fontSize="sm" color="gray.500">
            {transfer?.pharmacyName}
          </Text>
        </VStack>
      )}

      {transfer?.comments && (
        <VStack alignItems="start" spacing={0}>
          <Text fontSize="sm" color="gray.500">
            {t('common.comment')} :
          </Text>
          <Text fontSize="sm" color="gray.500">
            {transfer?.comments}
          </Text>
        </VStack>
      )}

      {transfer?.pharmacyPhoneNumber && (
        <VStack alignItems="start" spacing={0}>
          <Text fontSize="sm" color="gray.500">
            {t('common.establishmentPhoneNumber')} :
          </Text>
          <Text fontSize="sm" color="gray.500">
            {transfer?.pharmacyPhoneNumber}
          </Text>
        </VStack>
      )}
    </VStack>
  )
}
