import { useTranslation } from '@/utils/i18n'
import { IInsurance } from '@/utils/types'
import {
  Box,
  Button,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Switch,
  Select
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'

export interface IEditAddInsuranceProps {
  isOpen: boolean
  onClose: () => void
  insurance?: IInsurance
  onSubmit: (values: any, insuranceId: number) => void
}

export const EditAddInsurance = ({
  isOpen,
  onClose,
  insurance,
  onSubmit
}: IEditAddInsuranceProps) => {
  const t = useTranslation()
  const handleSave = () => {
    onSubmit(formik.values, insurance?.insuranceId)
    onClose()
  }
  const formik = useFormik({
    initialValues: {
      name: insurance?.name || '',
      fields: insurance?.fields || '',
      priority: +insurance?.priority || 1,
      verified: insurance?.verified || false
    },
    onSubmit: handleSave
  })

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={formik.handleSubmit}>
            <ModalHeader>
              {insurance ? t('insurance.editInsurance') : t('insurance.addInsurance')}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <Box>
                  <Text fontSize="sm" color="gray.400">
                    {t('insurance.insuranceName')}
                  </Text>
                  <Input
                    id="name"
                    name="name"
                    bg="white"
                    placeholder={t('insurance.insuranceName')}
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </Box>
                <Box>
                  <Text fontSize="sm" color="gray.400">
                    {t('insurance.fields')}
                  </Text>
                  <Input
                    id="fields"
                    name="fields"
                    type="text"
                    placeholder={t('insurance.fields')}
                    bg="white"
                    onChange={formik.handleChange}
                    value={formik.values.fields}
                  />
                </Box>
                <Box>
                  <Text fontSize="sm" color="gray.400">
                    {t('insurance.priority')}
                  </Text>
                  <Select
                    id="priority"
                    bg="white"
                    name="priority"
                    placeholder={t('insurance.priority')}
                    onChange={formik.handleChange}
                    value={formik.values.priority}
                  >
                    {[0, 1, 2, 3, 4, 5].map((v) => (
                      <option value={v} key={v}>
                        {v}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box>
                  <Text fontSize="sm" color="gray.400">
                    {t('insurance.verified')}
                  </Text>{' '}
                  <Switch
                    id="verified"
                    isChecked={formik.values.verified}
                    onChange={formik.handleChange}
                  />
                </Box>
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="solid"
                colorScheme="blue"
                type="submit"
              >
                {t('common.save')}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}
