import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from '.'

const CREATE_ORDER = gql`
  mutation createOrder($orderItems: [InputOrderItem], $orderType: OrderType!, $oAuthId: String!, $comment: String!, $addressId: Int, $time: String!, $stripeSource: String!, $insuranceId: Int) {
    createOrder(orderItems: $orderItems, orderType: $orderType, comment: $comment, oAuthId: $oAuthId, addressId: $addressId, time: $time, stripeSource: $stripeSource, insuranceId: $insuranceId) {
      orderId
    }
  }
`
export const useCreateOrder = () => {
  const [createOrder, createOrderQuery] = useMutation(CREATE_ORDER)
  useErrorToast({ error: createOrderQuery?.error })

  return {
    createOrder,
    createOrderQuery
  }
}
