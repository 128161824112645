import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from './useErrorToast'
import { useSuccessToast } from './useSuccessToast'

const UPDATE_TASK_STATUS = gql`
  mutation updateTaskStatus($taskId: Int!, $status: TaskStatus!) {
    updateTaskStatus(taskId: $taskId, status: $status) {
      taskId
    }
  }
`

export const useUpdateTaskStatus = () => {
  const t = useTranslation()
  const [updateTaskStatus, updateTaskStatusQuery] = useMutation(UPDATE_TASK_STATUS)
  useErrorToast({ error: updateTaskStatusQuery?.error })
  useSuccessToast({
    success: !!updateTaskStatusQuery?.data,
    message: t('common.taskUpdatedSuccessfully')
  })
  return { updateTaskStatus, updateTaskStatusQuery }
}
