import { gql, useQuery } from '@apollo/client'

const INSURANCE = gql`
  query($insuranceId: Int!) {
    getInsurance(insuranceId: $insuranceId) {
      insuranceId
      oAuthId
      imageIds
      name
      fields
      verified
    }
  }
`

export interface IUseGetInsuranceByIdProps {
  insuranceId: string
}

export const useGetInsuranceById = ({
  insuranceId
}: IUseGetInsuranceByIdProps) =>
  useQuery(INSURANCE, {
    variables: {
      insuranceId: insuranceId
    },
    fetchPolicy: 'network-only'
  })
