import React, { useContext } from 'react'
import { InsuranceBox } from './insuranceBox'
import { UserInsuranceContext, UserProfileContext } from '@/contexts'
import {
  useAddInsurance,
  useArchive,
  useDeleteInsurance,
  useEditInsurance
} from '@/hooks'

import {
  Box,
  Button,
  Flex,
  Grid,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useTranslation } from '@/utils/i18n'
import { EditAddInsurance } from './editAddInsurance'

export const UserInsurance = () => {
  const t = useTranslation()
  const { insurances, refetchAll } = useContext(UserInsuranceContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { userId } = useContext(UserProfileContext)
  const { archive } = useArchive()
  const { editInsurance } = useEditInsurance()
  const { addInsurance } = useAddInsurance()
  const { deleteInsurance } = useDeleteInsurance()

  const handleAddInsurance = async (values) => {
    await addInsurance({
      variables: {
        ...values,
        oAuthId: userId
      }
    })
    refetchAll()
  }

  const handleEditInsurance = async (values, insuranceId) => {
    await editInsurance({
      variables: {
        ...values,
        insuranceId: insuranceId
      }
    })
    refetchAll()
  }

  const handleDeleteInsurance = async (insuranceId) => {
    await deleteInsurance({
      variables: {
        insuranceId
      }
    })
    refetchAll()
  }

  const handleInsuranceArchive = async (insuranceId) => {
    await archive(insuranceId, 'insurance')
    refetchAll()
  }

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        w="full"
        mb={4}
        alignItems="center"
        bg="gray.50"
        borderRadius="md"
        p={4}
      >
        <Text fontSize="sm" color="gray.500">
          {insurances?.length || t('profile.no')}
          {' '}
          {insurances?.length > 1
            ? t('profile.insurancesFound')
            : t('profile.insuranceFound')}
        </Text>
        <Button onClick={() => onOpen()} colorScheme="blue">
          {t('profile.addInsurance')}
        </Button>
      </Flex>

      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        {insurances?.map((i) => (
          <InsuranceBox
            key={i?.insuranceId}
            insurance={i}
            onSubmit={handleEditInsurance}
            onDelete={handleDeleteInsurance}
            onArchive={handleInsuranceArchive}
          />
        ))}
      </Grid>
      <EditAddInsurance
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleAddInsurance}
      />
    </Box>
  )
}
