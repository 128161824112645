import React from 'react'
import { Box, Grid, Text } from '@chakra-ui/react'
import { IOrder } from '@/utils/types'
import { OrderCard } from './orderCard'
import { useTranslation } from '@/utils/i18n'
export interface IUserOrdersProps {
  orders: IOrder[]
}
export const UserOrders = ({ orders }: IUserOrdersProps) => {
  const t = useTranslation()
  return (
    <Box>
      <Box bg="gray.50" borderRadius="md" p={4} mb={4} >
        {' '}
        <Text fontSize="sm" color="gray.500">
          {orders?.length || t('profile.no')}{' '}
          {orders?.length > 1
            ? t('profile.ordersFound')
            : t('profile.orderFound')}
        </Text>
      </Box>

      <Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          {orders?.map((o: IOrder) => (
            <OrderCard order={o} key={o?.orderId} />
          ))}
        </Grid>
      </Box>
    </Box>
  )
}
