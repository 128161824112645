import { posologyDictionary } from '@/helpers/posologies'
import { useRouter } from 'next/router'

export const useTranslatePosology = () => {
  const router = useRouter()
  const { locale } = router
  const translatePosology = (poso: string) => {
    let posology: any = poso
    for (var key in posologyDictionary) {
      posology = posology.replace(
        new RegExp('\\b' + key + '\\b', 'gi'),
        posologyDictionary[key][locale]
      )
    }

    return posology.split(' ').join(' ')
  }

  return {
    translatePosology
  }
}
