import { UserProfileContext } from '@/contexts'
import { useGetAddresses } from '@/hooks/useGetAddresses'
import { useTranslation } from '@/utils/i18n'
import { IAddress } from '@/utils/types'
import { Button } from '@chakra-ui/button'
import {
  Badge,
  Box,
  HStack,
  ModalCloseButton,
  Text
} from '@chakra-ui/react'
import React, { useContext } from 'react'
import { MdLocationPin } from 'react-icons/md'
import { useConfirm } from '../dialogProvider/useConfirm'

export interface IQuickAddressInfoProps {
  mode: 'compact' | 'normal'
}

export const QuickAddressInfo = ({
  mode
}: IQuickAddressInfoProps) => {
  const t = useTranslation()
  const { confirm } = useConfirm()
  const { userId } = useContext(UserProfileContext)

  const { data } = useGetAddresses({ oAuthId: userId })

  const renderAddresses = () => {
    return data?.getAddresses?.map((address: IAddress) => (
      <Box
        borderWidth={1}
        mb={2}
        key={address?.addressId}
        borderColor="gray.200"
        p={3}
        fontSize="sm"
        bg="gray.50"
        borderRadius={'md'}
      >
        {address?.defaultAddress !== 'notDefault' && (
          <Badge colorScheme="green" variant="solid">
            {address?.defaultAddress}
          </Badge>
        )}
        <Text fontSize="sm" color="gray.500">
          #{address?.addressId}
        </Text>

        <Text>{address?.streetAddressLineOne}</Text>
        <Text>{address?.streetAddressLineTwo}</Text>
        <HStack spacing={1}>
          <Text>{address?.city}</Text>,
          <Text>{address?.province}</Text>
          <Text>{address?.postalCode}</Text>
        </HStack>
        <HStack spacing={2} mt={2}>
          <Badge colorScheme="blue" variant="solid">
            {address?.label}
          </Badge>
        </HStack>
      </Box>
    ))
  }

  const openAddresses = () => {
    confirm({
      title: t('profile.addresses'),
      body: (
        <Box>
          <ModalCloseButton />
          {renderAddresses()}
        </Box>
      ),
      noAction: true
    })
  }
  return (
    <Box>
      <Button
        size={mode === 'compact' ? 'sm' : 'md'}
        leftIcon={<MdLocationPin />}
        variant="outline"
        onClick={openAddresses}
      >
        {t('profile.addresses')}
      </Button>
    </Box>
  )
}
