import { Loader } from '@/components/loader'
import { OrderModal } from '@/components/userOrders/orderModal'
import {
  useLazyGetUserPharmacies
} from '@/hooks/useGetUserPharmacies'
import { IUserPharmacy } from '@/utils/types'
import { Box, useDisclosure, useToast } from '@chakra-ui/react'
import { noop } from 'lodash'
import { useRouter } from 'next/router'
import React, {
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { GraphQLContext } from './GraphQLProvider'
import { MeContext } from './me/meContext'

export interface IPharmaciesContextProps {
  pharamacies: IUserPharmacy[]
  selectedPharmacy: IUserPharmacy
  changePharmacyId: any
  getPharmacyById: (id: number) => IUserPharmacy
  openOrder: (orderId: string) => void
}

export const PharmaciesContext = createContext<IPharmaciesContextProps>(
  {
    pharamacies: null,
    selectedPharmacy: null,
    changePharmacyId: noop,
    getPharmacyById: null,
    openOrder: noop
  }
)
export const PharmaciesProvider = ({ children }: any) => {
  const { me } = useContext(MeContext)
  const { pharmacyId, changePharmacyId } = useContext(GraphQLContext)
  const [
    getUserPharmacies,
    { data, loading, called }
  ] = useLazyGetUserPharmacies()
  const toast = useToast()

  const router = useRouter()

  const [selectedOrderId, setSelectedOrderId] = useState<string>('')

  const orderDisclouser = useDisclosure()

  useEffect(() => {
    if (me) {
      getUserPharmacies()
    }
  }, [me])

  useEffect(() => {
    if (!loading && called && !data?.getUserPharmacies?.length) {
      toast({
        title: 'Unauthorized Access',
        status: 'error',
        position: 'top'
      })
      router.push('/api/auth/logout')
    }
  }, [data])

  return (
    <PharmaciesContext.Provider
      value={{
        pharamacies: data?.getUserPharmacies as IUserPharmacy[],
        selectedPharmacy: data?.getUserPharmacies?.find(
          (userPharm) => userPharm.pharmacyId === pharmacyId
        ),
        changePharmacyId,
        openOrder: (orderId: string) => {
          setSelectedOrderId(orderId)
          orderDisclouser.onOpen()
        },
        getPharmacyById: (id: number) =>
          data?.getUserPharmacies?.find(
            (userPharm) => userPharm.pharmacyId === id
          )
      }}
    >
      {loading && pharmacyId !== 0 ? (
        <Box
          position="fixed"
          top={0}
          right={0}
          left={0}
          bottom={0}
          justifyContent="center"
          alignItems="center"
          display="flex"
          bg="white"
        >
          <Loader fullWidth />
        </Box>
      ) : (
        children
      )}
      {orderDisclouser.isOpen && (
        <OrderModal
          isOpen={orderDisclouser.isOpen}
          onClose={orderDisclouser.onClose}
          orderId={selectedOrderId}
        />
      )}
    </PharmaciesContext.Provider>
  )
}
