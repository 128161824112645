import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useSuccessToast } from '.'
import { useErrorToast } from './useErrorToast'

const LINK_USER = gql`
  mutation adminLinkUser($oAuthId: String!, $childId: String!) {
    adminLinkUser(oAuthId: $oAuthId, childId: $childId)
  }
`

export const useLinkUser = () => {
  const t = useTranslation()
  const [linkUser, linkUserQuery] = useMutation(LINK_USER)
  useErrorToast({ error: linkUserQuery?.error })
  useSuccessToast({
    success: linkUserQuery?.data,
    message: t('common.userLinkedSuccessfully')
  })

  return {
    linkUser,
    linkUserQuery
  }
}
