import React from 'react'
import Select from 'react-select'
import { Box, Text } from '@chakra-ui/react'
import { ISelectValueProps } from '@/utils/types'
import { useTranslation } from '@/utils/i18n'

export interface IEditClassification {
  onChange: (value: ISelectValueProps) => void
  defaultValue: string
}

export const EditClassification = ({
  onChange,
  defaultValue
}: IEditClassification) => {
  const t = useTranslation()
  return (
    <Box
      maxW="200px"
      minW="150px"
      fontSize="small"
      fontWeight="semibold"
    >
      <Text
        fontSize="small"
        color="gray.500"
        fontWeight="normal"
        mb={1}
      >
        {t('prescription.classification')}
      </Text>
      <Select
        defaultValue={{
          label: defaultValue,
          value: defaultValue
        }}
        options={[
          { label: t('common.none'), value: 'none' },
          { label: t('prescription.chronic'), value: 'chronic' },
          { label: 'PRN', value: 'prn' }
        ]}
        onChange={onChange}
      />
    </Box>
  )
}
