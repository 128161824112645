import { ReactElement, useEffect } from 'react'
import moment from 'moment'
import { useRouter } from 'next/router'

const LocaleProvider = ({ children }: { children: ReactElement }) => {
  const router = useRouter()

  useEffect(() => {
    moment.locale(router?.locale)
  }, [router.locale])

  return children
}

export default LocaleProvider
