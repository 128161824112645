import _ from 'lodash'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { DocumentModal } from './documentModal'
import { IUserImage } from '@/utils/types'
import { useGetDocuments } from '@/hooks/useGetDocuments'
import { UserProfileContext } from '@/contexts'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Tag,
  useDisclosure,
  Image
} from '@chakra-ui/react'
import { useTranslation } from '@/utils/i18n'

export const UserDocuments = () => {
  const t = useTranslation()

  const { userId } = useContext(UserProfileContext)
  const { data, loading } = useGetDocuments({ oAuthId: userId })
  const documents = data?.getUserImages?.data
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [
    selectedImage,
    setSelectedImage
  ] = useState<IUserImage | null>(null)

  const handleImageSelection = (d: IUserImage) => {
    setSelectedImage(d)
    onOpen()
  }

  if (loading) {
    return (
      <Box>
        <Box justifyContent="center" display="flex">
          <Text fontSize="sm"> {t('common.loading')}</Text>
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      <Box background="white">
        <Accordion allowToggle variant="unstyled">
          {documents ? (
            Object.keys(documents).map((k) => (
              <AccordionItem
                key={k}
                borderWidth={1}
                mt="-px"
                bg="gray.50"
              >
                <h2>
                  <AccordionButton as={Box}>
                    <Box flex="1" textAlign="left" p={4}>
                      {t(`documents.${k}`)}
                      <Tag
                        borderRadius="full"
                        variant="outline"
                        colorScheme="blue"
                        ml={1}
                      >
                        {documents[k]?.length}
                      </Tag>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box display="flex" flexWrap="wrap">
                    {documents[k]?.length &&
                      _.orderBy(documents[k], ['date'], ['desc']).map(
                        (d: IUserImage) => (
                          <Box key={d.id}>
                            <Image
                              src={d.url}
                              m={1}
                              boxSize="150px"
                              objectFit="cover"
                              alt="airix"
                              onClick={() => {
                                handleImageSelection(d)
                              }}
                              fallbackSrc="https://via.placeholder.com/150"
                            />
                            <Text
                              fontSize="x-small"
                              textAlign="center"
                              mt={1}
                              mb={3}
                              color="gray.500"
                            >
                              {moment(d.date).format('LL')}
                            </Text>
                          </Box>
                        )
                      )}
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ))
          ) : (
            <Text mb={4} fontSize="sm" color="gray.500">
              {t('documents.noDocumentFound')}
            </Text>
          )}
        </Accordion>
      </Box>

      {selectedImage && (
        <DocumentModal
          isOpen={isOpen}
          onClose={onClose}
          data={selectedImage}
        />
      )}
    </Box>
  )
}
