import { UserProfileContext } from '@/contexts'
import { Button, HStack, Input, Link, Text, VStack } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody
} from '@chakra-ui/modal'
import React, { useContext, useState } from 'react'
import { useLinkUser } from '@/hooks/useLinkUser'
import { useUnlinkUser } from '@/hooks/useUnlinkUser'
import { useTranslation } from '@/utils/i18n'

export interface ILinkUserModalProps {
  isOpen: boolean
  onClose: () => void
}
export const LinkUserModal = ({
  isOpen,
  onClose
}: ILinkUserModalProps) => {
  const t = useTranslation()
  const [linkoAuthId, setLinkoAuthId] = useState<string>('')
  const { user, refetchUser } = useContext(UserProfileContext)
  const { linkUser, linkUserQuery } = useLinkUser()
  const { unlinkUser, unlinkUserQuery } = useUnlinkUser()

  const handleLink = async () => {
    await linkUser({
      variables: {
        oAuthId: user?.oAuthId,
        childId: linkoAuthId
      }
    })
    setLinkoAuthId('')
    refetchUser()
  }

  const handleUnlink = async (childId: string) => {
    await unlinkUser({
      variables: {
        parentId: user?.oAuthId,
        childId: childId
      }
    })
    refetchUser()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('common.linkedAccounts')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          <VStack alignItems="start">
            {user?.children?.length ? (
              user?.children?.map((u) => (
                <HStack
                  key={u?.oAuthId}
                  bg="blue.50"
                  borderRadius="md"
                  py={2}
                  px={4}
                  w="full"
                  justifyContent="space-between"
                >
                  {u?.oAuthId && (
                    <Link href={`/users/${u.oAuthId.replace('auth0|', '')}`}>
                      <Text fontSize="sm" color="blue.500">
                        {u?.lastName},{u?.firstName} ({u?.oAuthId})
                      </Text>
                    </Link>
                  )}
                  <Button
                    variant="outline"
                    bg="white"
                    isLoading={unlinkUserQuery?.loading}
                    onClick={() => handleUnlink(u?.oAuthId)}
                    colorScheme="blue"
                    size="sm"
                  >
                    {t('common.unlink')}
                  </Button>
                </HStack>
              ))
            ) : (
              <Text
                bg="gray.50"
                p={2}
                borderRadius="md"
                fontSize="sm"
                w="full"
                color="gray.700"
              >
                {t('common.noUserLinked')}
              </Text>
            )}

            <Text mb={1} pt={4} fontSize="sm" color="gray.700">
              {`${t('common.linkUserTo')} ${user?.lastName}, ${user?.firstName}`}
            </Text>

            <HStack
              bg="blue.50"
              borderRadius="md"
              p={4}
              w="full"
              justifyContent="space-between"
            >
              <Input
                placeholder={t('common.oAuthIdOfUserAccountToLink')}
                bg="white"
                size="sm"
                value={linkoAuthId}
                borderRadius="md"
                onChange={(e) => setLinkoAuthId(e.target.value)}
              />
              <Button
                variant="solid"
                colorScheme="blue"
                size="sm"
                isLoading={linkUserQuery?.loading}
                onClick={handleLink}
              >
                {t('common.link')}
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
