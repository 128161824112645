import React from 'react'
import { Flex, Box, HStack, ModalContent, ModalOverlay, Text, useDisclosure, Button, Modal } from '@chakra-ui/react'
import { useGetImages } from '@/hooks/useGetImages'
import { useTranslation } from '@/utils/i18n'
import { Image } from '@chakra-ui/image'
import { Loader } from '../../components/loader'
import { fileTypeIcon } from '@/utils/index';
import PdfViewer from '@/components/pdfViewer'

type Props = { imagesIds: number[]; text: string }

const ImagePreview = ({ imagesIds, text }: Props) => {
  const t = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { imageUris, loading } = useGetImages({
    enabled: isOpen,
    imagesIds
  })

  const splitedTexg = text.split('.');
  const formatPre = splitedTexg[splitedTexg.length - 1].split('_');
  const format = formatPre[0];
  const Icon = fileTypeIcon(format);

  return (
    <>
      <HStack spacing={1} cursor="pointer" _hover={{ color: 'blue.500' }} onClick={onOpen}>
        <Icon/>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay bg="blackAlpha.700"></ModalOverlay>
        <ModalContent overflow="hidden">
          <Box p={5}>
            {loading ? (
              <Loader fullWidth height="100px" />
            ) : (
              <>
                <Flex justifyContent="center">
                  {format === 'pdf' ? <PdfViewer fileUrl={imageUris[0]?.uri} /> : <Image src={imageUris[0]?.uri} fallbackSrc="/default-placeholder-300x300.png" width="300px" height="auto" />}
                </Flex>
                <Flex justifyContent="flex-end" mt={3}>
                  <Button colorScheme="blue" onClick={onClose} mt={3}>
                    {t('common.back')}
                  </Button>
                </Flex>
              </>
            )}
          </Box>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ImagePreview
