import React, { ReactNode, useEffect } from 'react'
import DtPicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { fr } from 'date-fns/locale';
import { useTranslation } from '@/utils/i18n'

registerLocale('fr', fr);

type Props = {
  value: any
  onChange: (val: Date) => void
  inline?: boolean
  showTimeSelect?: boolean
  disabled?: boolean
  timeIntervals?: number
  minDate?: Date
  maxDate?: Date
  id?: string
  placeHolderText?: string
  dateFormat?: string
  customInput?: ReactNode
  locale: 'fr' | 'en'
}

const DatePicker = ({ value, onChange, id, placeHolderText, dateFormat, customInput, ...props }: Props) => {
  const t = useTranslation()
  useEffect(() => {
    const datePickerHeader = document.querySelector('.react-datepicker-time__header')
    if (datePickerHeader) {
      datePickerHeader.textContent = t('common.time', props.locale)
    }
  }, [props])
  return <DtPicker selected={value}
    onChange={onChange} id={id}
    placeholderText={placeHolderText}
    dateFormat={dateFormat}
    customInput={customInput}
    {...props} />
}

export default DatePicker
