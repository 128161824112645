import { useEffect, useState } from 'react'

export interface IUseSortProps<T> {
  rows: T[]
}
export const useSort = <T extends unknown>({ rows }: IUseSortProps<T>) => {
  const [rawRows, setRawRows] = useState<T[]>(rows)
  const [sortedRows, setSortedRows] = useState<T[]>()
  const [activeSortColKey, setActiveSortColKey] = useState<string>()
  const [isAscending, setIsAscending] = useState<boolean | null>(null)

  const sort = (compareFunc: (a: T, b: T) => number, key: string) => {
    const copyRows = [...(rawRows ?? [])]

    if (key !== activeSortColKey) {
      setActiveSortColKey(key)
      setIsAscending(null)
      setSortedRows(copyRows.sort((a: T, b: T) => -compareFunc(a, b)))
      return
    }

    if (isAscending === null) {
      setSortedRows(copyRows.sort((a: T, b: T) => -compareFunc(a, b)))
      setIsAscending(false)
    } else if (!isAscending) {
      setSortedRows(copyRows.sort((a: T, b: T) => compareFunc(a, b)))
      setIsAscending(true)
    } else {
      setSortedRows(copyRows)
      setIsAscending(null)
      setActiveSortColKey('')
    }
  }

  useEffect(() => {
    setRawRows(rows)
    setSortedRows(null)
    setIsAscending(null)
    setActiveSortColKey('')
  }, [rows])

  return {
    sortedRows: sortedRows ?? rawRows,
    sort,
    isAscending,
    activeSortColKey
  }
}
