import { useTranslation } from '@/utils/i18n'
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from '@chakra-ui/react'
import { noop } from 'lodash'
import React, { useState, useRef, createContext } from 'react'

export interface IDialogProviderContext {
  confirm: (data: IConfirmProps) => void
  dismiss: () => void
}

export interface IConfirmProps {
  title: string
  body?: any
  confirmText?: string
  dismissText?: string
  onConfirm?: any
  closeOnConfirm?: boolean
  onDismiss?: any
  size?: 'md' | 'lg' | 'xl' | '2xl' | '3xl'
  isLoading?: boolean
  colorScheme?: 'blue' | 'red'
  noAction?: boolean
}

export const DialogProviderContext = createContext<IDialogProviderContext>(
  {
    confirm: noop,
    dismiss: noop
  }
)

export interface IDialogProviderProps {
  children: any
}

export const DialogProvider = ({
  children
}: IDialogProviderProps) => {
  const t = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [data, setData] = useState<IConfirmProps>()
  const onClose = () => setIsOpen(false)
  const cancelRef = useRef()

  return (
    <DialogProviderContext.Provider
      value={{
        confirm: (data: IConfirmProps) => {
          setData(data)
          setIsOpen(true)
        },
        dismiss: () => setIsOpen(false)
      }}
    >
      {children}
      <>
        <AlertDialog
          isOpen={isOpen}
          size={data?.size || 'lg'}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {data?.title}
              </AlertDialogHeader>

              {data?.body && (
                <AlertDialogBody>{data?.body}</AlertDialogBody>
              )}

              {!data?.noAction && (
                <AlertDialogFooter>
                  <Button
                    ref={cancelRef}
                    onClick={() => {
                      data?.onDismiss && data?.onDismiss()
                      onClose()
                    }}
                  >
                    {data?.dismissText || t('common.cancel')}
                  </Button>
                  <Button
                    colorScheme={data?.colorScheme || 'blue'}
                    onClick={() => {
                      data?.onConfirm()
                      data?.closeOnConfirm && onClose()
                    }}
                    isLoading={data?.isLoading || false}
                    ml={3}
                  >
                    {data?.confirmText || t('common.ok')}
                  </Button>
                </AlertDialogFooter>
              )}
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    </DialogProviderContext.Provider>
  )
}
