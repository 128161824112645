import React, { useState } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import moment from 'moment'
import MomentLocaleUtils from 'react-day-picker/moment'
import { MdModeEdit } from 'react-icons/md'
import 'react-day-picker/lib/style.css'
import { Box, HStack, IconButton, Text } from '@chakra-ui/react'
import { useTranslation } from '@/utils/i18n'

export interface IEditDateProps {
  defaultDate?: string
  label: string
  isNotServed?: boolean
  onChange: (date: Date) => void
}

export const EditDate = ({ defaultDate, isNotServed = false, label, onChange }: IEditDateProps) => {
  const t = useTranslation()
  const [selectedDay, setSelectedDay] = useState<Date>(new Date(moment(defaultDate).format('YYYY-MM-DD HH:mm:ss')))
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const handleDayChange = (day: Date) => {
    setSelectedDay(moment(day).toDate())
    setIsEditing(false)
    onChange(moment(day).toDate())
  }

  return (
    <Box bg="gray.50" borderWidth={0} borderColor="gray.200" p={2} className="daypicker" borderRadius="md">
      <HStack w="full" justifyContent="space-between">
        <Text fontSize="sm" color="gray.400" mb={1}>
          {label}
        </Text>
        {!isEditing && !isNotServed && <IconButton aria-label="uncheck" size="sm" color="gray.500" fontSize="md" icon={<MdModeEdit />} onClick={() => setIsEditing(true)} />}
      </HStack>
      {isEditing ? (
        <DayPickerInput
          value={selectedDay}
          onDayChange={handleDayChange}
          formatDate={() => MomentLocaleUtils.formatDate(selectedDay, 'LL', t('common.locale'))}
          parseDate={MomentLocaleUtils.parseDate}
          dayPickerProps={{
            selectedDays: selectedDay,

            localeUtils: MomentLocaleUtils,
            locale: t('common.locale')
          }}
        />
      ) : (
        <Text fontWeight="medium">{isNotServed ? t('common.notServed') : moment(selectedDay).format('LL')}</Text>
      )}
    </Box>
  )
}
