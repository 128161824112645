import { gql, useLazyQuery } from '@apollo/client'
import { useErrorToast } from '.'
import { useTranslation } from '@/utils/i18n'

const CHECK_USER_EXIST = gql`
  query checkUserExist($email: String) {
    checkUserExist(email: $email) {
      email
      exist
    }
  }
`

export const useCheckUserExist = () => {
  const t = useTranslation()
  const [checkUserExist, { data, loading, error }] = useLazyQuery(CHECK_USER_EXIST, {
    fetchPolicy: 'network-only'
  })

  useErrorToast({
    error,
    message: t('common.userAlreadyExistInCurrentEstablishment')
  })

  return { checkUserExist, data, loading, error }
}
