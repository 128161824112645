import { useEffect, useState } from 'react'
import { useToken } from '.'

export interface IUsePhotoIdProps {
  photoId: number
}
export const usePhotoId = ({ photoId }: IUsePhotoIdProps) => {
  const [imageUri, setImageUri] = useState<string>()

  const { getToken } = useToken()
  useEffect(() => {
    const getImageById = async (imgId) => {
      fetch(`${process.env.NEXT_PUBLIC_API_URL}/image/${imgId}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await getToken())
        }
      }).then(async (res) => {
        setImageUri(await res.text())
      })
    }
    if (photoId) {
      getImageById(photoId)
    }
  }, [photoId])

  return { imageUri }
}
