import React from 'react'
import { IUserProps } from '@/utils/types'
import {
  Avatar,
  Box,
  HStack,
  VStack,
  Text,
  Button,
  IconButton,
  Tag
} from '@chakra-ui/react'
import {
  MdLocalPhone,
  MdEmail,
  MdCake,
  MdPermIdentity,
  MdChatBubble,
  MdEdit
} from 'react-icons/md'
import { useRouter } from 'next/router'
import { FiExternalLink } from 'react-icons/fi'
import { useTranslation } from '@/utils/i18n'
import { UserProfileMenu } from './userProfileMenu'
import { QuickGenericInfo } from './quickGenericInfo'
import { QuickAddressInfo } from './quickAddressInfo'
import moment from 'moment'
import MomentLocaleUtils from 'react-day-picker/moment'

export interface IUserTopProfile {
  user: IUserProps
  mode?: 'compact' | 'normal'
}
export const UserTopProfile = ({
  user,
  mode = 'normal'
}: IUserTopProfile) => {
  const router = useRouter()
  const t = useTranslation()

  const handleOpenMessage = () => {
    router.push(`/messages/${user.oAuthId.substring(6)}`)
  }

  const handleOpenProfile = () => {
    router.push(`/users/${user?.oAuthId?.substring(6)}`)
  }
  const LayoutDirection = mode === 'compact' ? VStack : HStack
  return (
    <Box p={mode === 'compact' ? 0 : 10} position="relative">
      <VStack alignItems={mode === 'normal' ? 'center' : 'start'}>
        <Box position="relative">
          <Avatar
            name={`${user?.firstName} ${user?.lastName}`}
            src={user?.avatar}
            size={mode === 'compact' ? 'md' : '2xl'}
          />
          {mode !== 'compact' && (
            <IconButton
              aria-label="edit-avatar"
              icon={<MdEdit />}
              top={0}
              boxShadow="sm"
              right={0}
              borderRadius="full"
              colorScheme="blue"
              size="sm"
              position="absolute"
            ></IconButton>
          )}
        </Box>
        <HStack>
          <Text
            fontSize={mode === 'compact' ? 'lg' : '2xl'}
            fontWeight="bold"
          >{`${user?.firstName} ${user?.lastName}`}</Text>
          <Text fontSize="md">{`(${t(
            `common.${user?.gender}`
          )})`}</Text>
        </HStack>
        <LayoutDirection spacing={2} alignItems="start">
          <Tag
            variant="solid"
            colorScheme={
              user?.verifiedPhotoId === 'notVerified'
                ? 'red'
                : 'green'
            }
          >
            {t(`profile.${user?.verifiedPhotoId}`)}
          </Tag>
          {user?.ramqNumber ? (
            <Tag colorScheme="blue">RAMQ: {user?.ramqNumber}</Tag>
          ) : (
            <Tag variant="solid" colorScheme="red">
              {t('profile.ramqMissing')}
            </Tag>
          )}
          {user?.logipharmId ? (
            <Tag colorScheme="blue">
              {t('profile.logipharmId')}: {user?.logipharmId}
            </Tag>
          ) : (
            <Tag variant="solid" colorScheme="red">
              {t('profile.logipharmIdMissing')}
            </Tag>
          )}

          {user?.deceased && (
            <Tag variant="solid" colorScheme="red">
              {t('common.deceased')}
            </Tag>
          )}
          {user?.nonSafetyVial && (
            <Tag variant="solid" colorScheme="red">
              VNS
            </Tag>
          )}
        </LayoutDirection>
        <HStack>
          {user?.domedicPatientId && (
            <Tag colorScheme="blue">
              {t('profile.domedicPatientId')}:{' '}
              {user?.domedicPatientId}
            </Tag>
          )}
        </HStack>
        <LayoutDirection
          spacing={2}
          fontSize="sm"
          color="gray.500"
          alignItems="start"
        >
          <HStack>
            <MdLocalPhone />
            <Text>{user?.phoneNumber}</Text>
          </HStack>
          <HStack>
            <MdEmail />
            <Text>{user?.email}</Text>
          </HStack>
          <HStack>
            <MdCake />

            <Text>
              {MomentLocaleUtils.formatDate(
                new Date(
                  moment(user?.birthday).format('YYYY-MM-DD HH:mm:ss')
                ),
                'L',
                t('common.locale')
              )}
            </Text>
          </HStack>
        </LayoutDirection>
        <HStack spacing={4} fontSize="sm" color="gray.500">
          <HStack>
            <MdPermIdentity />
            <Text>{user?.oAuthId}</Text>
          </HStack>
        </HStack>

        <QuickAddressInfo mode={mode} />
        <HStack py={2} spacing={0}>
          {<QuickGenericInfo mode={mode} />}

          <HStack
            position="absolute"
            right={mode === 'compact' ? 0 : 6}
            top={mode === 'compact' ? 0 : 6}
          >
            {mode === 'compact' && (
              <IconButton
                variant="outline"
                aria-label="external"
                size="sm"
                onClick={handleOpenProfile}
                icon={<FiExternalLink />}
              ></IconButton>
            )}
            {mode !== 'compact' && (
              <Button
                variant="outline"
                leftIcon={<MdChatBubble />}
                onClick={handleOpenMessage}
              >
                {t('profile.openChat')}
              </Button>
            )}
            <UserProfileMenu mode={mode} />
            {/* <IconButton
              aria-label="edit"
              variant="outline"
              size={mode === 'compact' ? 'sm' : 'md'}
              icon={<MdEdit />}
              onClick={handleEditUser}
            ></IconButton> */}
          </HStack>
        </HStack>
        <HStack fontSize="sm" spacing={1} color="gray.500">
          <Text>{`${t('common.lastSeen')}: `}</Text>
          {user?.lastCheckedNotifications ? (
            <Text color="gray.500">
              {moment(user?.lastCheckedNotifications).fromNow()}
            </Text>
          ) : (
            <Text color="red.700">{t('common.neverSignedIn')}</Text>
          )}
        </HStack>
      </VStack>
    </Box>
  )
}
