import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'
import { useSuccessToast } from './useSuccessToast'

const ADD_PRESCRIPTION = gql`
  mutation addPrescription($prescriptionInput: PrescriptionInput!) {
    addPrescription(prescriptionInput: $prescriptionInput) {
      prescriptionId
    }
  }
`

export const useAddPrescription = () => {
  const t = useTranslation()
  const [addPrescription, addPrescriptionQuery] = useMutation(ADD_PRESCRIPTION)
  useErrorToast({ error: addPrescriptionQuery?.error })
  useSuccessToast({
    success: !!addPrescriptionQuery?.data,
    message: t('common.prescriptionCreatedSuccessfully')
  })
  return { addPrescription, addPrescriptionQuery }
}
