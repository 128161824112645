import { UserProfileContext } from '@/contexts'
import { Button, HStack, Image, Text } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from '@chakra-ui/modal'
import React, { useContext } from 'react'
import { usePhotoId } from '@/hooks/usePhotoId'
import { useEditUser } from '@/hooks/useEditUser'
import { useDeleteImage } from '@/hooks/useDeleteImage'
import { useTranslation } from '@/utils/i18n'

export interface IPhotoIdModalProps {
  isOpen: boolean
  onClose: () => void
}
export const PhotoIdModal = ({
  isOpen,
  onClose
}: IPhotoIdModalProps) => {
  const t = useTranslation()
  const { user, refetchUser } = useContext(UserProfileContext)
  const { imageUri } = usePhotoId({ photoId: user?.photoId })
  const { editUser, editUserQuery } = useEditUser()

  const { deleteImage } = useDeleteImage()

  const handleApprove = async () => {
    await editUser({
      variables: {
        oAuthId: user?.oAuthId,
        verifiedPhotoIdNotification: true,
        verifiedPhotoId: 'verified'
      }
    })

    refetchUser()
    onClose()
  }

  const handleReject = async () => {
    await deleteImage(user?.photoId)
    await editUser({
      variables: {
        oAuthId: user?.oAuthId,
        verifiedPhotoIdNotification: true,
        verifiedPhotoId: 'notVerified'
      }
    })
    refetchUser()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('common.verifyPhotoId')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" justifyContent="center">
          {!user?.photoId && (
            <Text
              w="full"
              bg="blue.50"
              color="blue.500"
              p={4}
              borderRadius="md"
            >
              {t('common.photoIdNotProvided')}
            </Text>
          )}
          {imageUri && <Image src={imageUri} width="300px" />}
        </ModalBody>

        <ModalFooter>
          <HStack spacing={2}>
            {user?.verifiedPhotoId !== 'notVerified' && (
              <Button
                colorScheme="red"
                variant="solid"
                onClick={handleReject}
              >
                {t('common.reject')}
              </Button>
            )}
            {user?.verifiedPhotoId !== 'verified' && (
              <Button
                colorScheme="blue"
                variant="solid"
                isLoading={editUserQuery?.loading}
                onClick={handleApprove}
              >
                {t('common.approve')}
              </Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
