import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from '@/hooks/useErrorToast'

const GET_PHARMACY_USER_DETAILS_BY_PHARMACY_ID = gql`
  query GetPharmacyUserDetailsByPharmacyId($oAuthId: String!) {
    getPharmacyUserDetailsByPharmacyId(oAuthId: $oAuthId) {
      email
      firstName
      gender
      language
      lastName
      logipharmId
      oAuthId
      note
      birthday
      role
      ramqNumber
      domedicPatientId
      phoneNumber
      stripeCustomerId
      isAdmin
    }
  }
`

export interface IUseGetPharmacyUserDetailsByPharmacyIdProps {
  oAuthId?: string
}

export const useGetPharmacyUserDetailsByPharmacyId = ({ oAuthId }: IUseGetPharmacyUserDetailsByPharmacyIdProps) => {
  const pharmacyUserDetailsByPharmacyIdQuery = useQuery(GET_PHARMACY_USER_DETAILS_BY_PHARMACY_ID, {
    variables: {
      oAuthId
    },
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: pharmacyUserDetailsByPharmacyIdQuery?.error })

  return pharmacyUserDetailsByPharmacyIdQuery
}
