import { useToken } from '.'

export const useDeleteImage = () => {
  const { getToken } = useToken()
  const deleteImage = async (imgId, type = 'photoId') => {
    return await fetch(`${process.env.NEXT_PUBLIC_API_URL}/deleteimage`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getToken())
      },
      body: JSON.stringify({
        type,
        imageId: imgId
      })
    })
  }

  return { deleteImage }
}
