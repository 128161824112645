import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useSuccessToast } from '.'
import { useErrorToast } from './useErrorToast'

const UNLINK_USER = gql`
  mutation unlinkUser($childId: String!, $parentId: String!) {
    unlinkUser(childId: $childId, parentId: $parentId)
  }
`

export const useUnlinkUser = () => {
  const t = useTranslation()
  const [unlinkUser, unlinkUserQuery] = useMutation(UNLINK_USER)
  useErrorToast({ error: unlinkUserQuery?.error })
  useSuccessToast({
    success: unlinkUserQuery?.data,
    message: t('common.userUnlinkedSuccessfully')
  })

  return {
    unlinkUser,
    unlinkUserQuery
  }
}
