import React from 'react'
import { IInsurance } from '@/utils/types'
import { LabelInfo } from '@/components/labelInfo'
import { useConfirm } from '@/components/dialogProvider/useConfirm'
import { useGetImages } from '@/hooks'
import {
  Badge,
  Box,
  Flex,
  Grid,
  IconButton,
  Text,
  HStack,
  Image,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import {
  MdArchive,
  MdDelete,
  MdModeEdit,
  MdMoreHoriz,
  MdUnarchive
} from 'react-icons/md'
import { useTranslation } from '@/utils/i18n'
import { EditAddInsurance } from './editAddInsurance'

export interface IInsuranceBoxProps {
  insurance: IInsurance
  onSubmit: (values: any, insuranceId: number) => void
  onDelete: (insuranceId: number) => void
  onArchive: (insuranceId: number) => void
}

export const InsuranceBox = ({
  insurance,
  onSubmit,
  onDelete,
  onArchive
}: IInsuranceBoxProps) => {
  const t = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { confirm } = useConfirm()

  const { imageUris } = useGetImages({
    imagesIds: insurance?.imageIds
  })

  const handleDelete = () => {
    confirm({
      title: t('insurance.areYouSureYouWantToDeleteThis'),
      onConfirm: () => onDelete(insurance?.insuranceId),
      colorScheme: 'red',
      confirmText: t('common.delete')
    })
  }

  const handleArchive = () => {
    confirm({
      title: `${t('insurance.areYouSureYouWantTo')} ${
        ((insurance?.archived ? t('common.reactivate') : t('common.archive')) as string).toLocaleLowerCase()
      } ${t('insurance.thisInterogation')}`,
      onConfirm: () => onArchive(insurance?.insuranceId),
      confirmText: insurance?.archived ? t('common.reactivate') : t('common.archive')
    })
  }

  return (
    <Box
      bg="gray.50"
      p={4}
      borderRadius="md"
      borderWidth={1}
      borderLeftColor={insurance?.verified ? 'blue.200' : 'red.200'}
      borderLeftWidth={4}
    >
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          w="full"
          mb={2}
        >
          <HStack spacing={2}>
            <Text>#{insurance.insuranceId}</Text>
            <Badge
              colorScheme={insurance?.archived ? 'gray' : 'green'}
              variant="solid"
            >
              {insurance?.archived ? t('common.archived') : 'Active'}
            </Badge>
            {insurance?.verified ? (
              <Badge colorScheme="blue" variant="solid">
                {t('common.verified')}
              </Badge>
            ) : (
              <Badge colorScheme="red" variant="solid">
                {t('notVerified')}
              </Badge>
            )}
          </HStack>

          <HStack spacing={2}>
            <Menu>
              <MenuButton
                as={IconButton}
                variant="outline"
                bg="white"
                aria-label="Options"
                icon={<MdMoreHoriz />}
                size="sm"
                fontSize="lg"
              />
              <MenuList>
                <MenuItem
                  icon={<MdModeEdit />}
                  onClick={() => {
                    onOpen()
                  }}
                >
                  {t('common.edit')}
                </MenuItem>
                <MenuItem icon={<MdDelete />} onClick={handleDelete}>
                  {t('common.delete')}
                </MenuItem>
                <MenuItem
                  icon={
                    insurance?.archived ? (
                      <MdUnarchive />
                    ) : (
                      <MdArchive />
                    )
                  }
                  onClick={handleArchive}
                >
                  {t('common.archive')}
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Box>

        <HStack>
          {!!imageUris?.length && (
            <Box mt={2}>
              <Flex flexWrap="wrap">
                {imageUris?.map((imgs) => (
                  <Image
                    key={imgs?.id}
                    boxSize="100px"
                    objectFit="cover"
                    mr={10}
                    mb={1}
                    src={imgs?.uri}
                    fallbackSrc="https://via.placeholder.com/100"
                  />
                ))}
              </Flex>
            </Box>
          )}
          <Box width="full">
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <LabelInfo
                label={t('insurance.insuranceName')}
                value={insurance?.name}
                size="sm"
              />
              <LabelInfo
                size="sm"
                label={t('insurance.fields')}
                value={insurance?.fields}
              />
              <LabelInfo
                label={t('insurance.priority')}
                value={insurance?.priority}
                size="sm"
              />
            </Grid>
          </Box>
        </HStack>
      </Box>
      <EditAddInsurance
        insurance={insurance}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Box>
  )
}
