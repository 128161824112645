import React, { useState, useEffect } from 'react'
import { SearchBox, DataTable, ColDef } from '@/components'
import { Image, Box, Flex, HStack, VStack, Text, ButtonGroup, Avatar, Menu, MenuButton, Button, MenuItem, MenuList, IconButton } from '@chakra-ui/react'
import { MdEmail, MdLocalPhone, MdMoreHoriz, MdAdd, MdWeb } from 'react-icons/md'
import { useTranslation } from '@/utils/i18n'
import { useRouter } from 'next/router'
import { useGetClients } from '@/hooks'

export default function ClientsPage() {
  const [searchText, setSearchText] = useState<string>('')
  const [showActive, setShowActive] = useState(true)
  const t = useTranslation()
  const router = useRouter()

  const type = showActive ? 'CLIENT' : 'NON_CLIENT'

  const { data, loading, refetch } = useGetClients({
    type,
    search: searchText
  })

  useEffect(() => {
    refetch()
  }, [type])

  const subUrl = 'establishments'

  const renderUser = (row: { [k: string]: any }) => {
    return (
      <Box width="full" borderBottomWidth={1} borderColor="gray.100" p={4} cursor="pointer" _hover={{ bg: 'blue.50' }}>
        <Flex justifyContent="space-between" alignItems="center">
          <HStack spacing={4}>
            {row.bannerLogo ? (
              <Image objectFit="contain" height={10} width={10} overflow="hidden" borderRadius="50%" src={row.bannerLogo} bg="#fff" />
            ) : (
              <Avatar fontWeight="normal" color="white" name={row.name} />
            )}
            <VStack alignItems="start">
              <HStack spacing={5}>
                <Text fontSize="md" fontWeight="semibold" cursor="pointer">
                  {row.name}
                </Text>
                <Text fontSize="sm" fontWeight="semibold" cursor="pointer">
                  {row.bannerName}
                </Text>
              </HStack>

              <HStack spacing={4} fontSize="sm" color="gray.500">
                <HStack>
                  <MdLocalPhone />
                  <Text>{row?.phone}</Text>
                </HStack>
                <HStack>
                  {row?.email && (
                    <>
                      <MdEmail />
                      <Text>{row?.email}</Text>
                    </>
                  )}
                </HStack>
                <HStack>
                  {row?.website && (
                    <>
                      <MdWeb />
                      <Text>{row?.website}</Text>
                    </>
                  )}
                </HStack>
              </HStack>
            </VStack>
          </HStack>

          <Menu>
            <MenuButton as={IconButton} size="sm" fontSize="lg" aria-label="Options" icon={<MdMoreHoriz />} variant="outline" />
            <MenuList fontSize="md">
              <MenuItem onClick={() => router.push(`/super-admin/${subUrl}/${row.pharmacyId}/edit`)}>{t('clients.modifyClient')}</MenuItem>
              <MenuItem onClick={() => router.push(`/super-admin/${subUrl}/${row.pharmacyId}`)}>{t('clients.manageUsers')}</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
    )
  }

  const cols: ColDef<any>[] = [
    {
      colName: t('clients.name'),
      render: renderUser,
      key: 'name'
    }
  ]

  return (
    <Box p={2}>
      <Box px={4} pb={2}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex gridGap={2}>
            <HStack spacing={2}>
              <ButtonGroup size="sm" isAttached variant="outline">
                <Button
                  mr="-px"
                  bg={showActive ? 'blue.50' : 'white'}
                  color={showActive ? 'blue.600' : 'gray.600'}
                  onClick={(e) => {
                    setShowActive(true)
                  }}
                >
                  {t('prescription.active')}
                </Button>
                <Button
                  mr="-px"
                  bg={!showActive ? 'blue.50' : 'white'}
                  color={!showActive ? 'blue.600' : 'gray.600'}
                  onClick={(e) => {
                    setShowActive(false)
                  }}
                >
                  {t('prescription.inactive')}
                </Button>
              </ButtonGroup>
            </HStack>
            <SearchBox searchValue={searchText} onSearch={setSearchText} />
          </Flex>
          <Button leftIcon={<MdAdd />} variant="solid" size="sm" colorScheme="blue" onClick={() => router.push(`/super-admin/${subUrl}/new?type=${showActive ? 'active' : 'inactive'}`)}>
            {t('clients.addEstablishment')}
          </Button>
        </Flex>
      </Box>

      <Box borderColor="gray.200" borderWidth={1} bg="white" borderRadius={10} overflow="hidden" m={4}>
        <DataTable rows={data?.clientPharmacies} cols={cols} showHeader={false} sidePadding={false} showRowBorder={false} isLoading={loading} paginationPosition="center" />
      </Box>
    </Box>
  )
}
