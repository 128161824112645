import { Box, Select, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { ICard } from '@/utils/types'
import { useTranslation } from '@/utils/i18n'

export interface IEditPaymentProps {
  stripeSourceId: string
  cards: ICard[]
  onChange: (stripeSourceId: string) => void
}

export const EditPayment = ({
  stripeSourceId,
  cards,
  onChange
}: IEditPaymentProps) => {
  const t = useTranslation()
  const [value, setValue] = useState<string>(
    stripeSourceId || 'noPayment'
  )

  const handleSelectChange = (e) => {
    setValue(e.target.value)
    onChange(e.target.value)
  }
  return (
    <Box minWidth="300px">
      <Text fontSize="sm" color="gray.500" mb={1}>
        {t('orders.payment')}
      </Text>
      <Select value={value} onChange={handleSelectChange}>
        <option key={'noPayment'} value={'noPayment'}>
          {t('orders.noPaymentSelected')}
        </option>

        {cards?.map((card) => {
          return (
            <option value={card?.id} key={card?.id}>
              {card.brand} {card.last4}, {card.exp_month}/
              {card.exp_year}{' '}
              {card.defaultSource ? `(${t('common.default')})` : null}
            </option>
          )
        })}
      </Select>
    </Box>
  )
}
