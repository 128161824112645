import { Box, Select, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { IAddress } from '@/utils/types'
import { useTranslation } from '@/utils/i18n'

export interface IEditAddressProps {
  addressId: string
  userAddresses: IAddress[]
  onChange: (vakue: string) => void
}

export const EditAddress = ({
  addressId,
  userAddresses,
  onChange
}: IEditAddressProps) => {
  const t = useTranslation()
  const [value, setValue] = useState<string>(addressId || 'noAddress')

  const handleInputChange = (e) => {
    setValue(e.target.value)
    onChange(e.target.value)
  }
  return (
    <Box minWidth="300px">
      <Text fontSize="sm" color="gray.500" mb={1}>
        {t('common.address')}
      </Text>
      <Select value={value} onChange={handleInputChange}>
        <option key={'noAddress'} value={'noAddress'}>
          {t('orders.noAddressSelected')}
        </option>
        {userAddresses?.map((userAddress) => {
          return (
            <option
              value={userAddress?.addressId}
              key={userAddress?.addressId}
            >
              {userAddress.streetAddressLineOne}{' '}
              {userAddress.streetAddressLineTwo} {userAddress.city}
            </option>
          )
        })}
      </Select>
    </Box>
  )
}
