import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Select,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IPrescription } from '@/utils/types'
import { PrescriptionCard } from './prescriptionCard'
import { AddPrescriptionModal } from './addPrescriptionModal'
import { useCreateOrder } from '@/hooks/useCreateOrder'
import moment from 'moment'
import {
  UserOrdersContext,
  UserPrescriptionsContext,
  UserProfileContext
} from '@/contexts'
import { useConfirm } from '../dialogProvider/useConfirm'
import { useTranslation } from '@/utils/i18n'
import _ from 'lodash'
import { HiSortAscending, HiSortDescending } from 'react-icons/hi'

export const UserPrescriptions = () => {
  const t = useTranslation()
  const {
    filteredPrescription,
    isLoading: isPrescriptionsLoading,
    changeStatus,
    changeClassification
  } = useContext(UserPrescriptionsContext)
  const { userId } = useContext(UserProfileContext)
  const { refetchAll: refetchOrders } = useContext(UserOrdersContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedPrescriptions, setSelectedPrescription] = useState<
    IPrescription[]
  >([])
  const [orderBy, setOrderBy] = useState<
    'fillDate' | 'prescribedDate' | 'drug.name'
  >('fillDate')
  const [isAsc, setIsAsc] = useState<boolean>(true)

  const { confirm } = useConfirm()

  const { createOrder, createOrderQuery } = useCreateOrder()

  const handlePrescriptionSelection = (
    check: boolean,
    p: IPrescription
  ) => {
    if (check) {
      setSelectedPrescription((sp) => [...sp, p])
    } else {
      setSelectedPrescription((sp) =>
        sp.filter((pp) => pp.prescriptionId !== p.prescriptionId)
      )
    }
  }

  const handleOnAddRx = () => {
    onOpen()
  }

  const handleBulkRenew = async () => {
    const inputOrderItems = selectedPrescriptions.map((sp) => ({
      prescriptionId: sp.prescriptionId,
      refillQuantity:
        sp.orderItems.length > 0
          ? sp.orderItems[0].refillQuantity
          : sp.unitsPerRefill
    }))

    confirm({
      title: t(
        'prescription.areYouSureYouWantToRenewSelectedPrescriptions'
      ),
      confirmText: t('profile.renewAll'),
      onConfirm: async () => {
        await createOrder({
          variables: {
            orderItems: inputOrderItems,
            orderType: 'pickup',
            comment: t('prescription.renewedByPharmacy'),
            time: moment().format(),
            stripeSource: '',
            oAuthId: userId
          }
        })
        refetchOrders()
        setSelectedPrescription([])
      }
    })
  }

  if (isPrescriptionsLoading) {
    return (
      <Box justifyContent="center" display="flex">
        <Text fontSize="sm">{t('common.loading')}</Text>
      </Box>
    )
  }
  return (
    <Box>
      <Flex justifyContent="space-between" mb={2}>
        <HStack my={2} spacing={2}>
          <Select
            onChange={(e) => changeStatus(e.target.value as any)}
            width="200px"
          >
            <option value={'active'}>{t('prescription.active')}</option>
            <option value={'stopped'}>{t('prescription.stopped')}</option>
          </Select>
          <Select
            onChange={(e) =>
              changeClassification(e.target.value as any)
            }
            width="200px"
          >
            <option value={'all'}>{t('common.all')}</option>
            <option value={'chronic'}>{t('common.chronic')}</option>
            <option value={'PRN'}>PRN</option>
          </Select>
        </HStack>
        <HStack spacing={2}>
          <Text>{t('common.orderBy')}</Text>
          <Select
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value as any)}
            width="200px"
          >
            <option value={'fillDate'}>{t('common.dateDispensed')}</option>
            <option value={'prescribedDate'}>{t('common.dateWritten')}</option>
            <option value={'drug.name'}>{t('common.drugName')}</option>
          </Select>
          <Button
            variant="outline"
            fontSize="lg"
            onClick={() => {
              setIsAsc(!isAsc)
            }}
          >
            {isAsc ? <HiSortAscending /> : <HiSortDescending />}
          </Button>
        </HStack>
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        bg="gray.50"
        borderRadius="md"
        p={4}
      >
        <Text fontSize="sm" color="gray.500">
          {filteredPrescription?.length || t('prescription.no')}{' '}
          {filteredPrescription?.length > 1
            ? t('profile.prescriptionsFound')
            : t('profile.prescriptionFound')}
        </Text>

        <HStack>
          {!!selectedPrescriptions.length && (
            <Button
              variant="solid"
              colorScheme="green"
              isLoading={createOrderQuery.loading}
              onClick={handleBulkRenew}
            >
              {t('profile.bulkRenew')}
            </Button>
          )}
          <Button
            variant="solid"
            colorScheme="blue"
            onClick={handleOnAddRx}
          >
            {t('profile.addRx')}
          </Button>
        </HStack>
      </Flex>

      <Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          {_.orderBy(
            filteredPrescription,
            [orderBy],
            [isAsc ? 'asc' : 'desc']
          )?.map((p: IPrescription) => (
            <PrescriptionCard
              selectedPrescription={selectedPrescriptions}
              onSelect={handlePrescriptionSelection}
              prescription={p}
              key={p?.prescriptionId}
            />
          ))}
        </Grid>
      </Box>
      {isOpen && (
        <AddPrescriptionModal isOpen={isOpen} onClose={onClose} />
      )}
    </Box>
  )
}
