import React, { useContext, useMemo } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Grid,
  Tag,
  Text
} from '@chakra-ui/react'
import { GenericView } from './GenericView'
import { IAllergy } from '@/utils/types'
import { MedicalInfoPopover } from './medicalInfoPopover'
import { UserProfileContext } from '@/contexts'
import { useTranslation } from '@/utils/i18n'

export const UserMedicalHistory = () => {
  const t = useTranslation()
  const { user, refetchUser } = useContext(UserProfileContext)

  const allergies = useMemo(() => {
    return user?.allergies?.filter(
      (a) => a.allergyGENcode !== 'noAllergies'
    )
  }, [user])

  const intolerances = useMemo(() => {
    return user?.intolerances?.filter(
      (a) => a.intoleranceGENcode !== 'noIntolerances'
    )
  }, [user])
  return (
    <Box>
      <Accordion allowMultiple>
        <AccordionItem
          bg="gray.50"
          p={4}
          border="none"
          borderRadius="md"
          mb={4}
        >
          <h2>
            <AccordionButton _hover={{ bg: 'gray.50' }}>
              <Box flex="1" textAlign="left">
                <Text fontSize="lg" fontWeight="medium">
                  {t('profile.allergies')}
                  <Tag
                    size="md"
                    colorScheme="blue"
                    variant="outline"
                    borderRadius="full"
                    ml={2}
                  >
                    {allergies?.length}
                  </Tag>
                </Text>
              </Box>
              <AccordionIcon fontSize="xl" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text fontSize="sm" color="gray.500">
              {allergies?.length || t('medicalHistory.no')}{' '}
              {allergies?.length > 1
                ? t('medicalHistory.allergiesFound')
                : t('medicalHistory.allergyFound')}
            </Text>
            <Grid templateColumns="repeat(3, 1fr)" gap={4} my={4}>
              {allergies?.map((al: IAllergy) => (
                <GenericView
                  key={al?.allergyGENcode}
                  gencode={al.allergyGENcode}
                  descriptor={al.descriptor}
                  userId={user?.oAuthId}
                  type="allergy"
                  userRefetch={refetchUser}
                />
              ))}
            </Grid>
            <MedicalInfoPopover
              userId={user?.oAuthId}
              onRefetch={refetchUser}
              type="allergy"
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          bg="gray.50"
          p={4}
          border="none"
          borderRadius="md"
          mb={4}
        >
          <h2>
            <AccordionButton _hover={{ bg: 'gray.50' }}>
              <Box flex="1" textAlign="left">
                <Text fontSize="lg" fontWeight="medium">
                  {t('profile.pathologies')}
                  <Tag
                    size="md"
                    colorScheme="blue"
                    variant="outline"
                    borderRadius="full"
                    ml={2}
                  >
                    {user?.pathologies?.length}
                  </Tag>
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text fontSize="sm" color="gray.500">
              {user?.pathologies?.length || t('medicalHistory.no')}{' '}
              {user?.pathologies?.length > 1
                ? t('medicalHistory.pathologiesFound')
                : t('medicalHistory.pathologyFound')}
            </Text>
            <Grid templateColumns="repeat(3, 1fr)" gap={4} my={4}>
              {user?.pathologies?.map((pth) => (
                <GenericView
                  key={pth?.pathologyId}
                  pathologyId={+pth.pathologyId}
                  descriptor={pth.descriptor}
                  userId={user?.oAuthId}
                  type="pathology"
                  userRefetch={refetchUser}
                />
              ))}
            </Grid>
            <MedicalInfoPopover
              userId={user?.oAuthId}
              onRefetch={refetchUser}
              type="pathology"
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          bg="gray.50"
          p={4}
          border="none"
          borderRadius="md"
        >
          <h2>
            <AccordionButton _hover={{ bg: 'gray.50' }}>
              <Box flex="1" textAlign="left">
                <Text fontSize="lg" fontWeight="medium">
                  {t('profile.intolerance')}
                  <Tag
                    size="md"
                    colorScheme="blue"
                    variant="outline"
                    borderRadius="full"
                    ml={2}
                  >
                    {intolerances?.length}
                  </Tag>
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text fontSize="sm" color="gray.500">
              {intolerances?.length || t('medicalHistory.no')}{' '}
              {intolerances?.length > 1
                ? t('medicalHistory.intolerancesFound')
                : t('medicalHistory.intoleranceFound')}
            </Text>
            <Grid templateColumns="repeat(3, 1fr)" gap={4} my={4}>
              {intolerances?.map((int) => (
                <GenericView
                  key={int?.intoleranceGENcode}
                  gencode={int?.intoleranceGENcode}
                  descriptor={int.descriptor}
                  userId={user?.oAuthId}
                  type="intolerance"
                  userRefetch={refetchUser}
                />
              ))}
            </Grid>
            <MedicalInfoPopover
              userId={user?.oAuthId}
              onRefetch={refetchUser}
              type="intolerance"
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}
