import moment from 'moment'
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef
} from 'react'
import {
  Text,
  Badge,
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel
} from '@chakra-ui/react'
import {
  EditAddress,
  EditDeliveryDate,
  EditOrderItems,
  EditOrderType,
  EditPayment
} from './modal'
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa'
import { IOrder, IOrderItem } from '@/utils/types'
import { LabelInfo, Loader } from '@/components'
import { renderStatus } from './orderCard'
import { useApproveOrder } from '@/hooks/useApproveOrder'
import { useChargeOrder } from '@/hooks/useChargeOrder'
import { useConfirm } from '../dialogProvider/useConfirm'
import { useEditOrder } from '@/hooks/useEditOrder'
import { useEditOrderItemPrice } from '@/hooks/useEditOrderItemPrice'
import { useFulFillOrder } from '@/hooks/useFulFillOrder'
import { useGetAddresses } from '@/hooks/useGetAddresses'
import { useGetCards } from '@/hooks/useGetCards'
import { useGetOrder } from '@/hooks/useGetOrder'
import { UserOrdersContext } from '@/contexts'
import { useTranslation } from '@/utils/i18n'
import { Receipt } from './receipt'
import { useReactToPrint } from 'react-to-print'

export interface IOrderModal {
  isOpen: boolean
  onClose: () => void
  orderId: string
}

export const OrderModal = ({
  isOpen,
  onClose,
  orderId
}: IOrderModal) => {
  const t = useTranslation()
  const toast = useToast()
  const { data, loading, refetch } = useGetOrder({
    orderId: +orderId
  })

  const { confirm, dismiss } = useConfirm()

  const order: IOrder = useMemo(() => data?.getOrder, [data])
  const userAddressesQuery = useGetAddresses({
    oAuthId: order?.oAuthId
  })
  const userCardsQuery = useGetCards({ oAuthId: order?.oAuthId })
  const { refetchAll } = useContext(UserOrdersContext)
  const { editOrder, deleteOrder } = useEditOrder()
  const { editOrderItemPrice } = useEditOrderItemPrice()
  const { fullFillOrder } = useFulFillOrder()
  const { approveOrder } = useApproveOrder()
  const { chargeOrder, chargeOrderQuery } = useChargeOrder()
  const receiptRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current
  })

  const updateOrder = async (data) => {
    await editOrder({
      variables: {
        orderId: +orderId,
        ...data
      }
    })

    refetch({
      orderId: +orderId
    })

    refetchAll()
  }

  const updateOrderItemPrice = useCallback(async (id, price) => {
    await editOrderItemPrice({
      variables: {
        orderItemId: id,
        priceInCents: price
      }
    })

    refetch({
      orderId: +orderId
    })

    refetchAll()
  }, [])

  const handleApprove = async (isPaid: boolean = false) => {
    await approveOrder({
      variables: {
        orderId: +orderId,
        notifyUser: true,
        approveAsPaid: isPaid
      }
    })
    refetch()
    refetchAll()
  }

  const handleFulFill = async () => {
    await fullFillOrder({
      variables: {
        orderId: +orderId
      }
    })
    refetch()
    refetchAll()
  }

  const handleUnApprove = async () => {
    await updateOrder({
      orderId: +orderId,
      state: 'unapproved'
    })
    refetch()
    refetchAll()
  }

  const handleDeleteOrder = async () => {
    await deleteOrder({
      variables: {
        orderId: +orderId,
        deleteOrder: true
      }
    })
    onClose()
    refetchAll()
  }
  const getOrderSubTotal = useMemo(
    () =>
      +(
        order?.orderItems.reduce((a, b) => a + b.priceInCents, 0) /
        100
      ).toFixed(2),
    [order]
  )

  const getTotalQST = useMemo(() => {
    return +order?.orderItems
      .reduce((a, b: IOrderItem) => {
        if (b.prescription.settings?.taxable?.qst) {
          return a + (b.priceInCents / 100) * 0.09975
        }
        return a
      }, 0)
      .toFixed(2)
  }, [order])

  const getTotalGST = useMemo(() => {
    return +order?.orderItems
      .reduce((a, b: IOrderItem) => {
        if (b.prescription.settings?.taxable?.gst) {
          return a + (b.priceInCents / 100) * 0.05
        }
        return a
      }, 0)
      .toFixed(2)
  }, [order])

  const handleChargeCard = async () => {
    const selectedCard = userCardsQuery.data?.getCards?.find(
      (c) => c.id === order?.stripeSource
    )
    !selectedCard &&
      toast({
        title: t('orders.pleaseSelectPaymentMethod'),
        status: 'warning',
        variant: 'subtle'
      })
    selectedCard &&
      confirm({
        title: `${t('orders.chargeCardTitle')}${+order.orderId}`,
        body: (
          <VStack alignItems="start">
            <HStack spacing={2}>
              <Text>{}</Text>
              <Text>
                $
                {(
                  getOrderSubTotal +
                  getTotalGST +
                  getTotalQST
                ).toFixed(2)}
              </Text>
            </HStack>
            <HStack spacing={3}>
              <Box color="gray.600" fontSize="2xl">
                {selectedCard.brand === 'VI' ? (
                  <FaCcVisa />
                ) : (
                  <FaCcMastercard />
                )}
              </Box>
              <Text>
                {selectedCard.brand} {selectedCard.last4},{' '}
                {selectedCard.exp_month}/{selectedCard.exp_year}{' '}
                {selectedCard.defaultSource ? `(${t('common.default')})` : null}
              </Text>
            </HStack>
          </VStack>
        ),
        confirmText: t('orders.charge'),
        dismissText: t('common.cancel'),
        isLoading: chargeOrderQuery?.loading,
        closeOnConfirm: false,
        onConfirm: () => {
          chargeOrder({
            variables: {
              orderId: +order.orderId,
              amount: Math.floor(
                (getOrderSubTotal + getTotalGST + getTotalQST) * 100
              )
            }
          })
            .then((result) => {
              handleApprove(true)
              toast({ title: t('orders.successfullyCharged') })
              dismiss()
            })
            .catch((e) => {
              let message
              if (e.message === 'GraphQL error: Error: 3022') {
                // insufficient funds
                message = t('orders.errorDeclined')
              } else if (
                e.message === 'GraphQL error: Error: 3005' ||
                e.message === 'GraphQL error: Error: 3006' ||
                e.message === 'GraphQL error: Error: 3007'
              ) {
                // failed AVS check
                message = t('orders.errorExpired')
              } else if ((e.message || '').includes('5031')) {
                message = t('orders.alreadyCharged')
              } else {
                // cover all other errors
                message = t('orders.errorProcessingPayment')
              }
              toast({ title: message, status: 'error' })
              dismiss()
            })
        },
        onDismiss: () => {}
      })

    refetch()
    refetchAll()
  }

  const handleEditOrderType = async (newType) => {
    await updateOrder({
      orderType: newType
    })
  }

  const handleEditDeliveryDate = async (date: Date) => {
    await updateOrder({
      time: date?.toString()
    })
  }

  const handleEditAddress = async (addressId) => {
    await updateOrder({
      addressId: +addressId
    })
  }

  const handleEditPayment = async (stripeId) => {
    await updateOrder({
      stripeSource: stripeId
    })
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay bg="blackAlpha.700"></ModalOverlay>
        <ModalContent overflow="hidden">
          {loading ? (
            <Loader fullWidth height="100px" />
          ) : (
            <>
              {' '}
              <ModalHeader>
                {t('orders.order')} #{order?.orderId}{' '}
                <Badge
                  colorScheme={renderStatus(order)}
                  variant="solid"
                >
                  {t(`common.${order?.state}`)}
                </Badge>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody minH="400px">
                <VStack spacing={2} alignItems="start">
                  <EditOrderType
                    order={order}
                    onChange={handleEditOrderType}
                  />
                  <EditDeliveryDate
                    defaultDate={moment(order?.time).toDate()}
                    onChange={handleEditDeliveryDate}
                  />
                  {order?.orderType !== 'pickup' && (
                    <EditAddress
                      addressId={order?.addressId}
                      userAddresses={
                        userAddressesQuery?.data?.getAddresses
                      }
                      onChange={handleEditAddress}
                    />
                  )}
                  <EditPayment
                    stripeSourceId={order?.stripeSource}
                    cards={userCardsQuery?.data?.getCards}
                    onChange={handleEditPayment}
                  />
                  <Box bg="gray.50" borderRadius="md" p={2} mt={1}>
                    <LabelInfo
                      label={t('common.comment')}
                      value={order?.comment}
                    />
                  </Box>
                </VStack>
                <EditOrderItems
                  order={order}
                  updateOrderItemPrice={updateOrderItemPrice}
                />
                <Text
                  p={4}
                  bg="gray.50"
                  borderRadius="md"
                  textAlign="end"
                  fontWeight="md"
                >
                  {loading ? (
                    'loading ...'
                  ) : (
                    <VStack alignItems="end">
                      <Text>
                        {' '}
                        {t('orders.total')}: $
                        {(
                          getOrderSubTotal +
                          getTotalGST +
                          getTotalQST
                        ).toFixed(2)}
                      </Text>
                      <Text fontSize="sm">
                        {' '}
                        {t('orders.subTotal')}: ${getOrderSubTotal}
                      </Text>

                      <Text fontSize="sm"> GST: ${getTotalGST}</Text>
                      <Text fontSize="sm"> QST: ${getTotalQST}</Text>
                    </VStack>
                  )}
                </Text>

                {order?.state !== 'unapproved' && (
                  <Box bg="blue.50" borderRadius="md" p={4} mt={2}>
                    <Button
                      colorScheme="gray"
                      variant="outline"
                      mr={3}
                      disabled={loading || !order?.stripeSource}
                      bg="white"
                      onClick={handleChargeCard}
                    >
                      {t('orders.chargeCard')}
                    </Button>
                    {!order?.stripeSource && (
                      <Text
                        fontSize="x-small"
                        color="gray.400"
                        my={1}
                      >
                        {t('orders.selectPaymentCard')}
                      </Text>
                    )}
                  </Box>
                )}
                {order?.stripeSource && (
                  <Accordion mt={4} bg="gray.50" allowMultiple>
                    <AccordionItem border="none" py={2}>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          <Button
                            variant="outline"
                            bg="white"
                            onClick={handlePrint}
                          >
                            {' '}
                            {t('orders.printInvoice')}
                          </Button>{' '}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel p={0}>
                        <Receipt
                          ref={receiptRef}
                          order={order}
                          totalGST={getTotalGST}
                          totalPrice={getOrderSubTotal}
                          totalQST={getTotalQST}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )}
              </ModalBody>
              <ModalFooter>
                <HStack spacing={2}>
                  {order?.state === 'unapproved' ? (
                    <Button
                      colorScheme="blue"
                      mr={3}
                      disabled={loading}
                      onClick={() => handleApprove(false)}
                    >
                      {t('orders.approveBill')}
                    </Button>
                  ) : (
                    <HStack spacing={2}>
                      {order?.state !== 'fulfilled' && (
                        <Button
                          colorScheme="purple"
                          onClick={handleFulFill}
                        >
                          {t('orders.fulfillOrder')}
                        </Button>
                      )}
                      {order?.state !== 'unapproved' && (
                        <Button
                          colorScheme="gray"
                          onClick={handleUnApprove}
                        >
                          {t('orders.unapprove')}
                        </Button>
                      )}
                    </HStack>
                  )}
                  {!order?.orderItems?.length && (
                    <Button
                      colorScheme="red"
                      variant="solid"
                      onClick={handleDeleteOrder}
                    >
                      Delete
                    </Button>
                  )}
                </HStack>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
