import { UserProfileContext } from '@/contexts'
import {
  Button,
  Text,
  ModalCloseButton,
  VStack
} from '@chakra-ui/react'
import { Box, HStack } from '@chakra-ui/layout'
import React, { useContext } from 'react'
import { MdCheck } from 'react-icons/md'
import { useRouter } from 'next/router'
import { useConfirm } from '../dialogProvider/useConfirm'
import { GenericView } from '../userMedicalHistory/GenericView'
import { IAllergy } from '@/utils/types'
import { useTranslation } from '@/utils/i18n'

export interface IQuickGenericInfoProps {
  mode: 'compact' | 'normal'
}

export const QuickGenericInfo = ({
  mode = 'normal'
}: IQuickGenericInfoProps) => {
  const t = useTranslation()
  const { user, refetchUser } = useContext(UserProfileContext)
  const { confirm } = useConfirm()
  const router = useRouter()

  const renderNotFound = () => {
    return (
      <Text
        bg="blue.50"
        p={3}
        borderRadius="md"
        fontSize="sm"
        color="blue.500"
      >
        {t('common.noFound')}
      </Text>
    )
  }

  const openAllergies = () => {
    router.push('?tab=3')
    confirm({
      title: 'Allergies',
      body: (
        <Box pb={4}>
          <ModalCloseButton />
          {!user?.allergies?.length && renderNotFound()}
          <VStack spacing={2} alignItems="start">
            {user?.allergies?.map((al: IAllergy) => (
              <GenericView
                removable={false}
                key={al?.allergyGENcode}
                gencode={al.allergyGENcode}
                descriptor={al.descriptor}
                userId={user?.oAuthId}
                type="allergy"
                userRefetch={refetchUser}
              />
            ))}
          </VStack>
        </Box>
      ),
      noAction: true
    })
  }

  const openPathologies = () => {
    router.push('?tab=3')
    confirm({
      title: 'Pathologies',
      body: (
        <Box pb={4}>
          <ModalCloseButton />
          {!user?.pathologies?.length && renderNotFound()}
          <VStack spacing={2} alignItems="start">
            {user?.pathologies?.map((pth) => (
              <GenericView
                key={pth?.pathologyId}
                pathologyId={+pth.pathologyId}
                descriptor={pth.descriptor}
                userId={user?.oAuthId}
                type="pathology"
                removable={false}
                userRefetch={refetchUser}
              />
            ))}
          </VStack>
        </Box>
      ),
      noAction: true
    })
  }

  const openIntolerances = () => {
    router.push('?tab=3')
    confirm({
      title: t('common.intolerances'),
      body: (
        <Box pb={4}>
          <ModalCloseButton />
          {!user?.intolerances?.length && renderNotFound()}
          <VStack spacing={2} alignItems="start">
            {user?.intolerances?.map((int) => (
              <GenericView
                key={int?.intoleranceGENcode}
                gencode={int?.intoleranceGENcode}
                descriptor={int.descriptor}
                userId={user?.oAuthId}
                type="intolerance"
                userRefetch={refetchUser}
                removable={false}
              />
            ))}
          </VStack>
        </Box>
      ),
      noAction: true
    })
  }

  const LayoutDirection = mode === 'compact' ? VStack : HStack
  return (
    <LayoutDirection spacing={2} width="full" m={0} p={0}>
      {user?.allergies && (
        <Button
          mr="-px"
          isFullWidth={mode === 'compact'}
          size={mode === 'compact' ? 'sm' : 'md'}
          onClick={openAllergies}
          variant={'outline'}
        >
          <HStack>
            {user?.allergies?.length && <MdCheck />}
            <Text>{t('profile.allergies')}</Text>
          </HStack>
        </Button>
      )}
      {user?.pathologies && (
        <Button
          mr="-px"
          isFullWidth={mode === 'compact'}
          size={mode === 'compact' ? 'sm' : 'md'}
          onClick={openPathologies}
          variant={'outline'}
        >
          <HStack>
            {user?.pathologies?.length && <MdCheck />}
            <Text>{t('profile.pathologies')}</Text>
          </HStack>
        </Button>
      )}
      {user?.intolerances && (
        <Button
          mr="-px"
          isFullWidth={mode === 'compact'}
          size={mode === 'compact' ? 'sm' : 'md'}
          variant={'outline'}
          onClick={openIntolerances}
        >
          <HStack>
            {user?.intolerances?.length && <MdCheck />}
            <Text>{t('common.intolerances')}</Text>
          </HStack>
        </Button>
      )}
    </LayoutDirection>
  )
}
