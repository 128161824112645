import React from 'react'
import { Box, Tab, TabList, Tag } from '@chakra-ui/react'

export interface IUserProfileTabItem {
  name: string
  badge?: string | number
}

export interface IUserProfileTabList {
  tabItems: IUserProfileTabItem[]
}

export const UserProfileTabList = ({
  tabItems
}: IUserProfileTabList) => {
  return (
    <Box position="sticky" top="20px" bg="white" py={0} zIndex={20}>
      <TabList>
        {tabItems.map((item: IUserProfileTabItem, index) => (
          <Tab
            ml={index === 0 ? 10 : 0}
            px={5}
            py={3}
            key={item?.name}
          >
            {item.name}

            {item?.badge ? (
              <Tag
                size="md"
                colorScheme="blue"
                // variant="outline"
                borderRadius="full"
                ml={2}
              >
                {item.badge}
              </Tag>
            ) : (
              <></>
            )}
          </Tab>
        ))}
      </TabList>
    </Box>
  )
}
