import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack
} from '@chakra-ui/react'
import { useTranslation } from '@/utils/i18n'

export type IStoppedReasonEnum =
  | 'newRx'
  | 'pharmacist'
  | 'doctor'
  | 'patient'

export interface IEditStopped {
  onChange: (
    isStopped: boolean,
    reason: IStoppedReasonEnum | null
  ) => void
  value: boolean
}

export const EditStopped = ({ onChange, value }: IEditStopped) => {
  const t = useTranslation()
  const [isStopped, setIsStopped] = useState<boolean>(value)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const close = () => setIsOpen(false)

  const handleStoppedChange = (e) => {
    setIsStopped(e.target.checked)
    setIsOpen(e.target.checked)
    if (!e.target.checked) {
      onChange(false, null)
    }
  }

  const handleReasonClick = (reason: IStoppedReasonEnum) => {
    onChange(true, reason)
    setIsStopped(true)
    close()
  }

  const handleCancel = () => {
    setIsStopped(false)
    close()
  }

  return (
    <Box>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={close}
        placement="right"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Box
            py={2}
            px={4}
            borderRadius="md"
            bg={isStopped ? 'red.50' : 'transparent'}
          >
            <Checkbox
              isChecked={isStopped}
              colorScheme="red"
              color={isStopped ? 'red.500' : 'gray.700'}
              fontWeight={isStopped ? 'semibold' : 'normal'}
              onChange={handleStoppedChange}
            >
              {t('prescription.stopped')}
            </Checkbox>
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Text fontSize="sm" color="gray.500" mb={2}>
              {t('prescriptionStoppedReason.title')}
            </Text>
            <VStack spacing={2}>
              <Button
                colorScheme="gray"
                size="sm"
                variant="outline"
                isFullWidth
                onClick={() => handleReasonClick('newRx')}
              >
                {t('prescriptionStoppedReason.newPrescription')}
              </Button>
              <Button
                colorScheme="gray"
                size="sm"
                variant="outline"
                isFullWidth
                onClick={() => handleReasonClick('patient')}
              >
                {t('prescriptionStoppedReason.patient')}
              </Button>
              <Button
                colorScheme="gray"
                size="sm"
                variant="outline"
                isFullWidth
                onClick={() => handleReasonClick('doctor')}
              >
                {t('prescriptionStoppedReason.doctor')}
              </Button>
              <Button
                colorScheme="gray"
                size="sm"
                variant="outline"
                isFullWidth
                onClick={() => handleReasonClick('pharmacist')}
              >
                {t('prescriptionStoppedReason.pharmacist')}
              </Button>
            </VStack>
            <Button
              colorScheme="red"
              size="sm"
              mt={2}
              variant="solid"
              isFullWidth
              onClick={handleCancel}
            >
              {t('common.cancel')}
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
