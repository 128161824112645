import moment from 'moment'
import React from 'react'
import { IPrescription } from '@/utils/types'
import { LabelInfo } from '@/components'
import { PrescriptionModal } from './prescriptionModal'
import {
  Box,
  Checkbox,
  Flex,
  Grid,
  HStack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useTranslation } from '@/utils/i18n'

export interface IPrescriptionCard {
  prescription: IPrescription
  onSelect?: (check: boolean, p: IPrescription) => void
  mode?: 'normal' | 'compact'
  selectedPrescription?: IPrescription[]
}

export const getPrescriptionStatus = (
  prescription: IPrescription
) => {
  const daysLeft =
    prescription?.quantityRemaining /
    prescription?.validityValues.unitsPerDay
  const monthsToExpiration = moment(prescription?.validUntil).diff(
    moment(),
    'months',
    true
  )
  if (daysLeft >= 90 && monthsToExpiration >= 3) {
    return 'green.200' // green
  }
  if (daysLeft >= 30 && monthsToExpiration >= 1) {
    return 'yellow.200' // yellow
  }
  if (daysLeft >= 1 && monthsToExpiration >= 0.04) {
    return 'orange.200' // orange
  }
  return 'gray.400'
}

export const PrescriptionCard = ({
  prescription,
  onSelect,
  mode = 'normal',
  selectedPrescription
}: IPrescriptionCard) => {
  const t = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handlePrescriptionCheck = (e) => {
    onSelect(e.target.checked, prescription)
  }

  return (
    <>
      <Box
        borderWidth={1}
        p={mode !== 'compact' ? 6 : 2}
        bg="gray.50"
        borderColor="gray.200"
        borderRadius={6}
        borderLeftColor={getPrescriptionStatus(prescription)}
        borderLeftWidth={4}
      >
        <HStack alignItems="start">
          {onSelect && (
            <Box p={1}>
              <Checkbox
                onChange={handlePrescriptionCheck}
                isChecked={
                  !!selectedPrescription?.find(
                    (sp) =>
                      sp.prescriptionId ===
                      prescription.prescriptionId
                  )
                }
              />
            </Box>
          )}
          <Box onClick={() => onOpen()} width="full">
            <Flex
              justifyContent="space-between"
              mb={2}
              flexDirection={mode !== 'compact' ? 'row' : 'column'}
              alignItems={mode !== 'compact' ? 'center' : 'start'}
            >
              <HStack spacing={2}>
                <Text
                  fontSize={mode !== 'compact' ? 'md' : 'sm'}
                  fontWeight="semibold"
                >
                  {prescription?.drug?.name}
                </Text>
                {prescription?.drug?.strength && (
                  <>
                    <Text
                      fontSize={mode !== 'compact' ? 'md' : 'sm'}
                      fontWeight="normal"
                      color="gray.500"
                    >
                      /
                    </Text>
                    <Text
                      fontSize={mode !== 'compact' ? 'md' : 'sm'}
                      fontWeight="normal"
                      color="gray.500"
                    >
                      {prescription?.drug?.strength}
                    </Text>
                  </>
                )}
                {prescription?.drug?.form && (
                  <>
                    <Text
                      fontSize={mode !== 'compact' ? 'md' : 'sm'}
                      fontWeight="normal"
                      color="gray.500"
                    >
                      /
                    </Text>
                    <Text
                      fontSize={mode !== 'compact' ? 'md' : 'sm'}
                      fontWeight="normal"
                      color="gray.500"
                    >
                      {t(`prescription.${prescription?.drug?.form}`)}
                    </Text>
                  </>
                )}
              </HStack>
              <Text
                fontSize={mode !== 'compact' ? 'md' : 'sm'}
                fontWeight="normal"
                color="gray.500"
              >
                Rx{' '}
                {prescription?.externalPrescriptionNumber ??
                  prescription?.prescriptionId}
              </Text>
            </Flex>

            {mode !== 'compact' && (
              <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                <LabelInfo
                  label={t('prescription.quantity')}
                  value={prescription?.fillQuantity || t('common.notServed')}
                />
                <LabelInfo
                  label={t('prescription.fillDate')}
                  value={
                    prescription?.orderItems[0]?.fillDate
                      ? moment(
                          prescription?.orderItems[0]?.fillDate
                        ).format('LL')
                      : t('common.notServed')
                  }
                />
                <LabelInfo
                  label={t('prescription.duration')}
                  value={
                    prescription?.prescribeDuration
                      ? `${prescription?.prescribeDuration} ${
                          prescription?.prescribeDuration > 1
                            ? t('common.days')
                            : t('common.day')
                        }`
                      : 'N/A'
                  }
                />
                <LabelInfo
                  label={t('prescription.posology')}
                  value={`${prescription?.posology}`}
                />
                <LabelInfo
                  label={t('prescription.adherence')}
                  value={`${prescription?.adherence} ${
                    prescription?.adherence > 1
                      ? t('common.days')
                      : t('common.day')
                  }`}
                />
              </Grid>
            )}
          </Box>
        </HStack>
      </Box>
      {isOpen && (
        <PrescriptionModal
          isOpen={isOpen}
          onClose={onClose}
          prescriptionId={prescription?.prescriptionId}
        />
      )}
    </>
  )
}
