import { useTranslation } from '@/utils/i18n'
import { IUserImage } from '@/utils/types'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Text,
  ModalOverlay,
  Image,
  Box
} from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'

export interface IDocumentModalProps {
  data: IUserImage
  isOpen: boolean
  onClose: () => void
}
export const DocumentModal = ({
  data,
  isOpen,
  onClose
}: IDocumentModalProps) => {
  const t = useTranslation()
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pt="50px" pb="20px">
            <Box borderWidth={1}>
              <Image src={data?.url} width="400px" objectFit="fill" />
            </Box>
            <Text
              fontSize="x-small"
              textAlign="center"
              mt={3}
              mb={3}
              color="gray.500"
            >
              {moment(data?.date).format('LLL')}
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
