import React from 'react'
import { Box } from '@chakra-ui/react'
import TimeRangePicker from '@wojtekmaj/react-timerange-picker'
import '@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css'
import 'react-clock/dist/Clock.css'

type ValuePiece = Date | string | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

type Props = {
  value: Value
  setValue: (val: Value) => void
  disableClock?: boolean
  format?: string
}

const TimeRange = ({ value, setValue, ...props }: Props) => {
  return (
    <Box width="120px">
      <TimeRangePicker rangeDivider={"->"} onChange={setValue} value={value} {...props} />
    </Box>
  )
}
export default TimeRange
