import { ColDef, DataTable } from '@/components/datatable'
import { useTranslation } from '@/utils/i18n'
import { IOrder, IOrderItem } from '@/utils/types'
import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { EditPrice } from './editPrice'

export interface IEditOrderItemsProps {
  order: IOrder
  updateOrderItemPrice: (orderItemId: string, price: number) => void
}

export const EditOrderItems = ({
  order,
  updateOrderItemPrice
}: IEditOrderItemsProps) => {
  const t = useTranslation()
  const cols: ColDef<any>[] = [
    {
      colName: 'Rx',
      render: (row: IOrderItem) =>
        `Rx ${
          row?.prescription.externalPrescriptionNumber ??
          row?.prescriptionId
        }`,
      key: 'orderId'
    },
    {
      colName: t('orders.name'),
      render: (row: IOrderItem) => row?.prescription?.drug.name,
      key: 'orderName'
    },
    {
      colName: t('common.quantity'),
      render: (row: IOrderItem) => (
        <Text>
          {row.refillQuantity} {row?.prescription?.drug?.form}
        </Text>
      ),

      key: 'qty'
    },
    {
      colName: t('common.price'),
      render: (row: IOrderItem) => (
        <EditPrice
          price={row.priceInCents / 100}
          onSubmit={async (price) => {
            await updateOrderItemPrice(row?.orderItemId, price)
          }}
        />
      ),
      key: 'price',
      position: 'end'
    }
  ]

  return (
    <Box mt={10}>
      <Text fontSize="lg" mb={2}>
        {t('orders.orderItems')}
      </Text>
      <DataTable
        cols={cols}
        rows={order?.orderItems}
        rowPadding={4}
        showPagination={order?.orderItems?.length >= 20}
        defaultPageSize={20}
        showRowBorder={false}
      />
    </Box>
  )
}
