import { gql, useMutation } from '@apollo/client'
import { useErrorToast, useSuccessToast } from '.'
import { useTranslation } from '@/utils/i18n'

const LINK_USER_EXIST = gql`
  mutation linkExistingUser($email: String) {
    linkExistingUser(email: $email)
  }
`

export const useLinkExistingUser = () => {
  const t = useTranslation()
  const [linkExistingUser, linkExistingUserResult] = useMutation(LINK_USER_EXIST, {
    fetchPolicy: 'network-only'
  })

  useSuccessToast({
    success: !!linkExistingUserResult?.data?.linkExistingUser,
    message: t('common.userLinked')
  })
  useErrorToast({
    error: linkExistingUserResult?.error,
    message: t('common.userAlreadyExistInCurrentEstablishment')
  })

  return { linkExistingUser, linkExistingUserResult }
}
