import React, { useState } from 'react'
import { MdModeEdit } from 'react-icons/md'
import {
  Box,
  HStack,
  IconButton,
  Input,
  Kbd,
  Text,
  Textarea
} from '@chakra-ui/react'
import { useTranslation } from '@/utils/i18n'
import { useTranslatePosology } from '@/hooks/useTranslatePosology'

export interface IEditPosologyProps {
  defaultValue?: string
  onChange: (value: string) => void
  type?: 'input' | 'textarea'
}

export const EditPosology = ({
  defaultValue = '',
  onChange,
  type = 'input'
}: IEditPosologyProps) => {
  const t = useTranslation()

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { translatePosology } = useTranslatePosology()
  const [value, setValue] = useState<string>(defaultValue)
  const handleKeyChange = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false)
      onChange(translatePosology(value))
    }
  }

  const handleOnChange = (e) => {
    setValue(e.target.value)
  }

  const handleEdit = () => setIsEditing(true)

  return (
    <Box
      bg="gray.50"
      borderWidth={0}
      borderColor="gray.200"
      p={2}
      borderRadius="md"
    >
      <HStack w="full" justifyContent="space-between">
        <Text
          fontSize="sm"
          textTransform="capitalize"
          color="gray.400"
          mb={1}
        >
          {t('prescription.posology')}
        </Text>
        {!isEditing && (
          <IconButton
            aria-label="uncheck"
            size="sm"
            color="gray.500"
            fontSize="md"
            icon={<MdModeEdit />}
            onClick={handleEdit}
          />
        )}
      </HStack>
      <Text fontWeight="medium">{translatePosology(value)}</Text>
      {isEditing && (
        <Box mt={2}>
          {type === 'textarea' ? (
            <Textarea
              value={value}
              bg="white"
              placeholder={t('prescription.posology')}
              onKeyDown={handleKeyChange}
              onChange={handleOnChange}
            />
          ) : (
            <Input
              value={value}
              bg="white"
              placeholder={t('prescription.posology')}
              onKeyDown={handleKeyChange}
              onChange={handleOnChange}
            />
          )}
          <Text fontSize="sm" mt={1}>
            {t('prescription.press')} <Kbd>{t('prescription.keyboardEnter')}</Kbd> {t('prescription.toSave')}
          </Text>
        </Box>
      )}
    </Box>
  )
}
