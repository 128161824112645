import React from 'react'
import * as Yup from 'yup'
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Grid, Input, Select, Text, VStack } from '@chakra-ui/react'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal'
import { useCheckUserExist } from '@/hooks/useCheckUserExist'
import { useFormik } from 'formik'
import { useLinkExistingUser } from '@/hooks/useLinkExisitingUser'
import { useLocale, useTranslation } from '@/utils/i18n'
import { useCreateNewUser } from '@/hooks/useCreateNewUser'
import { roles } from '../../utils'
import { customFormat } from '@/utils/customTimeFormat'
import { DateTimePicker } from '@/components'
import validator from 'validator'
import { useRouter } from 'next/router'

export interface INewUserModalProps {
  isOpen: boolean
  onClose: () => void
}

export const NewUserModal = ({ isOpen, onClose }: INewUserModalProps) => {
  const { checkUserExist, data, loading } = useCheckUserExist()
  const { createNewUser, createNewUserQuery } = useCreateNewUser()
  const t = useTranslation()
  const lang = useLocale()
  const router = useRouter()
  const isSuperAdminRoute = router.asPath.startsWith('/super-admin')

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(t('newUser.requiredEmail')),
    phoneNumber: Yup.number().min(9, t('newUser.errorPhoneNumberInvalid')).required(t('newUser.requiredPhoneNumber')),
    firstName: Yup.string().required(t('newUser.requiredFirstName')),
    lastName: Yup.string().required(t('newUser.requiredLastName')),
    password: Yup.string().required(t('newUser.requiredPassword')),
    birthday: Yup.date().required(t('newUser.requiredBirthday')),
    gender: Yup.string().required(t('newUser.requiredGender')),
    language: Yup.string().required(t('newUser.requiredLanguage')),
    role: Yup.string().required(t('newUser.requiredRole'))
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      phoneNumber: '',
      firstName: '',
      lastName: '',
      password: '',
      birthday: '',
      gender: 'male',
      language: 'fr',
      signupMethod: 'lasuit',
      role: isSuperAdminRoute ? 'pharmacist' : 'user'
    },
    validationSchema,
    onSubmit: async (values) => {
      if (validatePassword(values.password)) {
        await createNewUser({
          variables: {
            userInput: values
          }
        })
        onClose()
      }
    }
  })

  const { linkExistingUser, linkExistingUserResult } = useLinkExistingUser()

  const handleLinkExistingUser = async () => {
    await linkExistingUser({
      variables: {
        email: data?.checkUserExist?.email
      }
    })
    onClose()
  }

  const handleDateChange = (val) => {
    formik.setFieldValue('birthday', val)
  }
  const validatePassword = (val) => {
    if (validator.isStrongPassword(val, {
      minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 0
    })) {
      return true
    } else {
      formik.setFieldError('password', t('newUser.passwordValidation'))
      return false
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('newUser.addNewUser')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {data?.checkUserExist?.exist === false && (
            <Box>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={4}>
                <FormControl id="firstName" isInvalid={!!formik.errors.firstName}>
                  <FormLabel>{t('newUser.firstName')}</FormLabel>
                  <Input type="text" name="firstName" placeholder={t('newUser.firstName')} value={formik.values.firstName} onChange={formik.handleChange} />
                  <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
                </FormControl>
                <FormControl id="lastName" isInvalid={!!formik.errors.lastName}>
                  <FormLabel>{t('newUser.lastName')}</FormLabel>
                  <Input type="text" name="lastName" placeholder={t('newUser.lastName')} value={formik.values.lastName} onChange={formik.handleChange} />
                  <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
                </FormControl>
                <FormControl id="birthday" isInvalid={!!formik.errors.birthday}>
                  <FormLabel>{t('newUser.birthday')}</FormLabel>
                  <Box borderRadius={4} borderWidth={1} borderColor="gray.200" fontSize={14} p={2}>
                    <DateTimePicker
                      locale={lang}
                      maxDate={new Date()}
                      value={formik.values.birthday}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      placeHolderText={t('newUser.birthday')}
                      customInput={<input value={customFormat(formik.values.birthday)} placeholder={t('newUser.birthday')} />}
                      />
                  </Box>
                  <FormErrorMessage>{formik.errors.birthday}</FormErrorMessage>
                </FormControl>
                <FormControl id="phoneNumber" isInvalid={!!formik.errors.phoneNumber}>
                  <FormLabel>{t('newUser.phoneNumber')}</FormLabel>
                  <Input type="text" name="phoneNumber" placeholder={t('newUser.phoneNumber')} value={formik.values.phoneNumber} onChange={formik.handleChange} />
                  <FormErrorMessage>{formik.errors.phoneNumber}</FormErrorMessage>
                </FormControl>

                <FormControl id="gender">
                  <FormLabel>{t('newUser.gender')}</FormLabel>
                  <Select name="gender" value={formik.values.gender} onChange={formik.handleChange}>
                    <option value="male">{t('newUser.male')}</option>
                    <option value="female">{t('newUser.female')}</option>
                    <option value="other">{t('newUser.other')}</option>
                  </Select>
                </FormControl>
                <FormControl id="language">
                  <FormLabel>{t('newUser.language')}</FormLabel>
                  <Select name="language" value={formik.values.language} onChange={formik.handleChange}>
                    <option value="fr">{t('newUser.french')}</option>
                    <option value="en">{t('newUser.english')}</option>
                  </Select>
                </FormControl>

                <FormControl id="role" isInvalid={!!formik.errors.role}>
                  <FormLabel>{t('editUser.role')}</FormLabel>
                  <Box>
                    <Select value={formik.values.role} onChange={formik.handleChange}>
                      {roles.map((role) => {
                        if (!isSuperAdminRoute || role !== 'user') {
                          return (
                            <option key={role} value={role}>
                              {t(`roles.${role}`)}
                            </option>
                          );
                        }
                      })}
                    </Select>
                  </Box>
                  <FormErrorMessage>{!!formik.errors.role && formik.errors.role}</FormErrorMessage>
                </FormControl>
              </Grid>
            </Box>
          )}
          <Grid templateColumns={data?.checkUserExist?.exist === false ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'} gap={6}>
            <FormControl id="email" isInvalid={!!formik.errors.email}>
              <FormLabel>{t('newUser.email')}</FormLabel>
              <Input type="text" name="email" placeholder={t('newUser.email')} value={formik.values.email} onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
            {data?.checkUserExist?.exist === false && (
              <FormControl id="password" isInvalid={!!formik.errors.password}>
                <FormLabel>{t('newUser.password')}</FormLabel>
                <Input type="password" name="password" placeholder={t('newUser.password')} value={formik.values.password} onChange={formik.handleChange} />
                <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormControl>
            )}
          </Grid>

          {data?.checkUserExist?.exist && (
            <Box mt={4}>
              <VStack alignItems="start" spacing={2}>
                <Text bg="blue.50" color="blue.500" fontSize="sm" w="full" p={3} borderRadius="md">
                  {t('newUser.userAlreadyExist')}
                </Text>
                <Button onClick={handleLinkExistingUser} isLoading={linkExistingUserResult?.loading}>
                  {t('newUser.linkUser')}
                </Button>
              </VStack>
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          {data?.checkUserExist?.exist === false ? (
            <Button
              variant="solid"
              colorScheme="blue"
              isLoading={createNewUserQuery?.loading}
              onClick={() => {
                console.log(formik.handleSubmit())
              }}
            >
              {t('common.save')}
            </Button>
          ) : (
            <Button
              variant="solid"
              colorScheme="blue"
              isLoading={loading}
              onClick={async () => {
                await checkUserExist({
                  variables: {
                    email: formik.values.email
                  }
                })
              }}
            >
              {t('newUser.next')}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
