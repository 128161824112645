import React, { useEffect, useState } from 'react'
import { useLocale, useTranslation } from '@/utils/i18n'
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure
} from '@chakra-ui/react'
import { Select } from '@chakra-ui/select'
import { Input } from '@chakra-ui/input'
import { FormikValues, useFormik } from 'formik'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control'
import * as Yup from 'yup'

import { useGetMeContext } from '@/contexts'
import { useCreateTask, useGetPharmacyUsers, useGetUsers, useGetUserTasksCategories, useUploadFiles } from '@/hooks'
import { Select as ReactSelect } from 'chakra-react-select'
import { MdAttachFile, MdClose } from 'react-icons/md'
import { AiOutlineLoading } from 'react-icons/ai'
import { DateTimePicker } from '@/components'
import { customFormat } from '@/utils/customTimeFormat'
import { format } from 'date-fns'

const CreateTask = ({ refetch }: { refetch: () => void }) => {
  const t = useTranslation()
  const lang = useLocale()
  const { me } = useGetMeContext()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [fileNames, setFileNames] = useState([])
  const [searchText, setSearchText] = useState<string>('')

  const { data, loading } = useGetPharmacyUsers({
    pharmacyId: Number(me?.pharmacyId),
    type: 'EMPLOYEE',
    enabled: isOpen
  })
  const { data: clients } = useGetUsers({ query: searchText }, isOpen)
  const { data: taskCategories } = useGetUserTasksCategories(isOpen)

  const { createTask, createTaskQuery } = useCreateTask()

  useEffect(() => {
    if (createTaskQuery?.data) {
      refetch()
      onClose()
      formik.resetForm()
    }
  }, [createTaskQuery?.data])

  const handleDateChange = (val) => {
    formik.setFieldValue('dueDate', val)
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('tasks.requiredTitle')),
    type: Yup.string().required(t('tasks.requiredType')),
    priority: Yup.string().required(t('tasks.requiredPriority')),
    assignedTo: Yup.array().required(t('tasks.requiredAssignedTo'))
  })

  const handleSave = async (variables: any) => {
    createTask({
      variables: {
        ...variables,
        assignedTo: variables.assignedTo.map(({ value }) => value),
        oAuthId: variables.oAuthId ? variables.oAuthId.value : ''
      }
    })
  }
  const formik = useFormik<FormikValues>({
    enableReinitialize: true,
    initialValues: {
      title: '',
      description: '',
      status: 'todo',
      assignedTo: [],
      oAuthId: '',
      priority: 'high',
      payload: {},
      type: taskCategories?.userTaskCategories[0].id,
      attachedFileIds: [],
      dueDate: ''
    },
    onSubmit: handleSave,
    validationSchema
  })

  const { isLoading, data: uploadedFiles, uploadFiles } = useUploadFiles()

  const handleFile = async (e: any) => {
    await uploadFiles(e?.target?.files, 'task')
    setFileNames((prev) => [...prev, e.target.files[0].name])
  }

  const taskTypeOptions = (taskCategories?.userTaskCategories ?? []).map((cat) => ({
      label: lang === 'en' ? cat.name : cat.nameFr, value: cat.id
    }
  ))

  const updateFilesSelected = (index: number) => {
    const newFileNames = [...fileNames]
    newFileNames.splice(index, 1)
    setFileNames(newFileNames)
    const newAttachedFileIds = [...formik.values.attachedFileIds]
    newAttachedFileIds.splice(index, 1)
    formik.setFieldValue('attachedFileIds', newAttachedFileIds)
  }

  useEffect(() => {
    if (!isLoading && uploadedFiles) {
      formik.setFieldValue('attachedFileIds', [...(new Set([...formik.values.attachedFileIds, ...uploadedFiles]) as any)])
    }
  }, [isLoading, uploadedFiles])

  const handleBoxClick = () => {
    const dateTimePickerInput = document.getElementById('datetimepicker-input')
    if (dateTimePickerInput) {
      dateTimePickerInput.click()
    }
  }

  const handlePatientInputChange = (inputValue) => {
    setSearchText(inputValue)
  }

  return (
    <>
      <Button colorScheme="blue" variant="solid" onClick={onOpen}>
        {t('tasks.createTask')}
      </Button>

      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay bg="blackAlpha.700"></ModalOverlay>
          <ModalContent>
            <ModalHeader>{t('tasks.createTask')}</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem colSpan={2}>
                  <FormControl id="title" isInvalid={!!formik.errors.title}>
                    <FormLabel>{t('tasks.title')}</FormLabel>
                    <Input type="text" placeholder={t('tasks.title')} value={formik.values.title}
                           onChange={formik.handleChange} />
                    <FormErrorMessage>{!!formik.errors.title && formik.errors.title}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl id="description" isInvalid={!!formik.errors.description}>
                    <FormLabel>{t('tasks.description')}</FormLabel>
                    <Textarea placeholder={t('tasks.description')} value={formik.values.description}
                              onChange={formik.handleChange} />
                    <FormErrorMessage>{!!formik.errors.description && formik.errors.description}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl id="type" isInvalid={!!formik.errors.type}>
                    <FormLabel>{t('tasks.taskType')}</FormLabel>
                    <Select value={formik.values.type} onChange={formik.handleChange}>
                      {taskTypeOptions?.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{!!formik.errors.type && formik.errors.type}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl id="createdAt" isInvalid={!!formik.errors.createdAt}>
                    <FormLabel>{t('tasks.createdAt')}</FormLabel>
                    <Input type="date" disabled placeholder={t('tasks.createdAt')}
                           value={format(new Date(), 'yyyy-MM-dd')} onChange={formik.handleChange} />
                    <FormErrorMessage>{!!formik.errors.createdAt && formik.errors.createdAt}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl id="priority" isInvalid={!!formik.errors.priority}>
                    <FormLabel>{t('tasks.priority')}</FormLabel>
                    <Select value={formik.values.priority} onChange={formik.handleChange}>
                      <option value="high">{t('priority.high')}</option>
                      <option value="medium">{t('priority.medium')}</option>
                      <option value="standard">{t('priority.standard')}</option>
                    </Select>
                    <FormErrorMessage>{!!formik.errors.priority && formik.errors.priority}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl id="status" isInvalid={!!formik.errors.status}>
                    <FormLabel>{t('common.status')}</FormLabel>
                    <Select value={formik.values.status} onChange={formik.handleChange}>
                      <option value="todo">{t('tasks.toDo')}</option>
                      <option value="inprogress">{t('tasks.inProgress')}</option>
                      <option value="completed">{t('tasks.completed')}</option>
                      <option value="canceled">{t('tasks.cancelled')}</option>

                    </Select>
                    <FormErrorMessage>{!!formik.errors.status && formik.errors.status}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl id="dueDate" isInvalid={!!formik.errors.oAuthId}>
                    <FormLabel>{t('tasks.dueDate')}</FormLabel>
                    <Box
                      position="relative"
                      borderRadius={4}
                      borderWidth={1}
                      borderColor="gray.200"
                      fontSize={14}
                      p={2}
                      style={{ cursor: 'pointer' }}
                      onClick={handleBoxClick}
                    >
                      <div>
                        <DateTimePicker
                          locale={lang}
                          id="datetimepicker-input"
                          value={formik.values.dueDate}
                          onChange={handleDateChange}
                          showTimeSelect
                          timeIntervals={60}
                          dateFormat="dd/MM/yyyy HH:mm a"
                          placeHolderText={t('tasks.selectDateAndTime')}
                          customInput={<input value={customFormat(formik.values.dueDate)}
                                              placeholder={t('tasks.selectDateAndTime')} />}
                        />
                      </div>
                    </Box>
                    <FormErrorMessage>{!!formik.errors.dueDate && formik.errors.dueDate}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl id="oAuthId" isInvalid={!!formik.errors.oAuthId}>
                    <FormLabel>{t('tasks.patient')}</FormLabel>
                    <ReactSelect
                      placeholder={t('common.select')}
                      isMulti={false}
                      isDisabled={loading}
                      isLoading={loading}
                      isClearable={true}
                      value={formik.values.oAuthId}
                      onInputChange={handlePatientInputChange}
                      onChange={(options) => {
                        formik.setFieldValue('oAuthId', options)
                      }}
                      options={clients?.users?.map(({ oAuthId, firstName, lastName }) => (
                        {
                          value: oAuthId,
                          label: `${firstName} ${lastName}`
                        }
                      ))}
                    />
                    <FormErrorMessage>{!!formik.errors.client && formik.errors.client}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl id="assignedTo" isInvalid={!!formik.errors.assignedTo}>
                    <FormLabel>{t('tasks.establishmentEmployee')}</FormLabel>
                    <ReactSelect
                      placeholder={t('common.selectOptions')}
                      isMulti
                      isDisabled={loading}
                      isLoading={loading}
                      value={formik.values.assignedTo ?? []}
                      onChange={(options) => {
                        formik.setFieldValue('assignedTo', options)
                      }}
                      options={data?.getPharmacyUsers
                        ?.map(({
                                 userDetails,
                                 oAuthId
                               }) => ({ label: `${userDetails.firstName} ${userDetails.lastName}`, value: oAuthId }))
                        ?.sort((a, b) => (a?.label > b?.label ? 1 : -1))}
                    />
                    <FormErrorMessage>{!!formik.errors.assignedTo && formik.errors.assignedTo}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={2}>
                  <FormLabel htmlFor="attach-file">
                    <Flex alignItems="center" gridGap={1} mb={2} cursor="pointer">
                      <Text>
                        {t('common.attachFile')} ({t('common.optional')})
                      </Text>
                      {!isLoading ? <MdAttachFile /> : <AiOutlineLoading className="spin" />}
                    </Flex>

                    <Input type="file" id="attach-file" disabled={isLoading} hidden onChange={handleFile} />
                  </FormLabel>

                  {fileNames.map((name, index) => (
                    <Flex key={name} alignItems="center" gridGap={1} cursor="pointer"
                          onClick={() => updateFilesSelected(index)}>
                      <Text fontSize={12}>
                        {index + 1}. {name}
                      </Text>
                      <MdClose />
                    </Flex>
                  ))}
                </GridItem>
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Button variant="solid" size="sm" colorScheme="blue" isLoading={createTaskQuery.loading}
                      onClick={() => formik.handleSubmit()}>
                {t('common.save')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default CreateTask
