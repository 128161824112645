import { useTranslation } from '@/utils/i18n'
import {
  Box,
  HStack,
  IconButton,
  Select,
  Text
} from '@chakra-ui/react'
import React from 'react'
import {
  MdChevronLeft,
  MdChevronRight,
  MdFirstPage,
  MdLastPage
} from 'react-icons/md'

export interface IPaginationControl {
  position?: 'start' | 'center' | 'end'
  defaultSize: number
  pageSize: number
  totalCount: number | string
  currentPage: number
  hasFirstPage: boolean
  hasLastPage: boolean
  hasNextPage: boolean
  hasPreviousPage: boolean
  onNextPage: () => void
  onPreviousPage: () => void
  onFirstPage: () => void
  onLastPage: () => void
  onUpdateSize: (size: number) => void
}
export const PaginationControl = ({
  position = 'end',
  defaultSize,
  pageSize,
  totalCount,
  currentPage,
  hasFirstPage,
  hasLastPage,
  hasNextPage,
  hasPreviousPage,
  onNextPage,
  onPreviousPage,
  onFirstPage,
  onLastPage,
  onUpdateSize
}: IPaginationControl) => {
  const t = useTranslation()
  return (
    <Box
      p={5}
      display="flex"
      justifyContent={
        position === 'start'
          ? 'flex-start'
          : position === 'center'
          ? 'center'
          : 'flex-end'
      }
    >
      <HStack spacing={2}>
        <Select
          placeholder={t('common.selectOption')}
          size="sm"
          width="70px"
          onChange={(e) => {
            onUpdateSize(+e.target.value)
          }}
        >
          {[10, 25, 50, 100].map((s) => (
            <option
              value={s}
              key={`size_${s}`}
              selected={s === defaultSize}
            >
              {s}
            </option>
          ))}
        </Select>
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="previous"
            variant="icon"
            size="sm"
            fontSize="xl"
            isDisabled={!hasFirstPage}
            onClick={onFirstPage}
            icon={<MdFirstPage />}
          />
          <IconButton
            aria-label="previous"
            variant="icon"
            size="sm"
            fontSize="xl"
            isDisabled={!hasPreviousPage}
            onClick={onPreviousPage}
            icon={<MdChevronLeft />}
          />
          <Box px={4}>
            <Text fontSize="sm">{`${currentPage * pageSize} - ${
              (currentPage + 1) * pageSize > totalCount
                ? totalCount
                : (currentPage + 1) * pageSize
            } of ${totalCount}`}</Text>
          </Box>
          <IconButton
            aria-label="previous"
            variant="icon"
            size="sm"
            fontSize="xl"
            isDisabled={!hasNextPage}
            onClick={onNextPage}
            icon={<MdChevronRight />}
          />
          <IconButton
            aria-label="previous"
            variant="icon"
            size="sm"
            fontSize="xl"
            isDisabled={!hasLastPage}
            onClick={onLastPage}
            icon={<MdLastPage />}
          />
        </Box>
      </HStack>
    </Box>
  )
}
