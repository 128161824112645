import { gql, useQuery } from '@apollo/client'

const TRANSFER = gql`
  query($transferId: Int!) {
    getTransfer(transferId: $transferId) {
      transferId
      oAuthId
      typeOfTransfer
      completed
      imageIds
      doctorName
      doctorPhoneNumber
      pharmacyName
      pharmacyPhoneNumber
      comments
    }
  }
`

export interface IUseGetTransferByIdProps {
  transferId: string
}

export const useGetTransferById = ({ transferId }: IUseGetTransferByIdProps) =>
  useQuery(TRANSFER, {
    variables: {
      transferId: transferId
    },
    fetchPolicy: 'network-only'
  })
