import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { ColDef, DataTable } from '@/components/datatable'
import { IOrderItem } from '@/utils/types'
import { useTranslation } from '@/utils/i18n'

export interface IOrderItemsTableProps {
  orderItems: IOrderItem[]
}
export const OrderItemsTable = ({
  orderItems
}: IOrderItemsTableProps) => {
  const t = useTranslation()

  const cols: ColDef<any>[] = [
    {
      colName: t('common.id') as string,
      render: (row: IOrderItem) => row.orderId,
      key: 'orderId'
    },
    {
      colName: t('common.quantity') as string,
      render: (row: IOrderItem) => row.refillQuantity,
      key: 'qty'
    },
    {
      colName: t('common.price') as string,
      render: (row: IOrderItem) => `$${row.priceInCents / 100}`,
      key: 'qty'
    },
    {
      colName: 'DIN',
      render: (row: IOrderItem) => row?.drugId,
      key: 'qty'
    }
  ]

  return (
    <Box mt={10}>
      <Text fontSize="lg" mb={2}>
        {t('orders.recentOrders')}
      </Text>
      <DataTable
        cols={cols}
        rows={orderItems}
        rowPadding={2}
        showPagination={false}
        showRowBorder={false}
      />
    </Box>
  )
}
