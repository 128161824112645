import { gql, useQuery } from '@apollo/client'

const PRESCRIBER = gql`
  query prescribers($query: String!) {
    prescribers(query: $query) {
      license
      firstName
      lastName
      profession
    }
  }
`

export interface IUseGetPrescribers {
  query: string
}

export const useGetPrescribers = ({ query }: IUseGetPrescribers) =>
  useQuery(PRESCRIBER, {
    variables: {
      query
    },
    fetchPolicy: 'network-only'
  })
