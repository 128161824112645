import React, { useEffect, useState } from 'react'
import { useTranslation, useLocale } from '@/utils/i18n'
import { Grid, GridItem, Flex, Text, Button, Modal, ModalContent, ModalHeader, ModalBody, ModalOverlay, ModalFooter, useDisclosure, ModalCloseButton, Textarea, Image, Box } from '@chakra-ui/react'
import { Select } from '@chakra-ui/select'
import { Input } from '@chakra-ui/input'
import { FormikValues, useFormik } from 'formik'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control'
import * as Yup from 'yup'
import { format } from 'date-fns'
import { useGetMeContext } from '@/contexts'
import {
    useGetPharmacyUsers,
    useGetUserTasksCategories,
    useGetUsers,
    useUploadFiles,
    useGetTasksCategories
} from '@/hooks'
import { Select as ReactSelect } from 'chakra-react-select'
import { MdAttachFile, MdClose } from 'react-icons/md'
import { AiOutlineLoading } from 'react-icons/ai'
import { DateTimePicker } from '@/components'
import { useEditTask } from '@/hooks/useEditTasks'
import { useGetPharmacyUserDetailsByPharmacyId } from '@/hooks/useGetPharmacyUserDetailsByPharmacyId'

const EditTask = ({ refetch, row }: { refetch: () => void, row: any }) => {
    const t = useTranslation()
    const lang = useLocale();
    const { me } = useGetMeContext()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [fileNames, setFileNames] = useState(row?.attachedFileIds ?? [])

    const [{ data, loading }, { data: clients }, { data: tasks }] = [
        useGetPharmacyUsers({
            pharmacyId: Number(me?.pharmacyId),
            type: 'EMPLOYEE',
            enabled: isOpen
        }),
        useGetUsers({}, isOpen),
        row.manuallyCreated ? useGetUserTasksCategories(isOpen) : useGetTasksCategories(isOpen)
    ]

    const { data: pharmacyUser } = useGetPharmacyUserDetailsByPharmacyId({
        oAuthId: me.oAuthId
    });

    const { editTask, editTaskQuery } = useEditTask()

    useEffect(() => {
        if (editTaskQuery?.data) {
            refetch()
            onClose()
            formik.resetForm()
            setFileNames([])
        }
    }, [editTaskQuery?.data])

    const handleDateChange = (val) => {
        formik.setFieldValue('dueDate', val)
    }

    const validationSchema = Yup.object().shape({
        title: row?.manuallyCreated ? Yup.string().required(t('tasks.requiredTitle')) : Yup.string().nullable(),
        // type: Yup.string().required(t('tasks.requiredType')),
        priority: row?.manuallyCreated ? Yup.string().required(t('tasks.requiredPriority')) : Yup.string().nullable(),
        assignedTo: Yup.array().required(t('tasks.requiredAssignedTo'))
    })

    useEffect(() => {
        setFileNames(row?.attachedFileIds)
    }, [row?.attachedFileIds])

    const handleSave = async (variables: any) => {
        editTask({
            variables: {
                ...variables,
                taskId: row.taskId,
                assignedTo: variables.assignedTo.map(({ value }) => value),
                attachedFileIds: variables?.attachedFileIds?.map((item) => item.imageId)
            }
        })
    }

    const formik = useFormik<FormikValues>({
        initialValues: {
            title: row?.title,
            description: row?.description,
            status: row?.status?.replace(/[-\s]/g, ''),
            assignedTo: row.assignedTo?.map((user) => ({
                label: user.fullName,
                value: user.oAuthId
            })),
            oAuthId: row?.oAuthId,
            priority: row?.priority,
            type: row?.typeOfTask,
            attachedFileIds: row?.attachedFileIds,
            createdAt: format(new Date(row?.createdAt), 'yyyy-MM-dd'),
            dueDate: row?.dueDate ? new Date(row?.dueDate) : null
        },
        onSubmit: handleSave,
        validationSchema,
        enableReinitialize: true
    })

    const { isLoading, data: uploadedFiles, uploadFiles } = useUploadFiles()

    const handleFile = async (e: any) => {
        await uploadFiles(e?.target?.files, 'task')
        setFileNames((prev) => [...prev, e.target.files[0].name])
    }

    let taskTypeOptions
    if (row.manuallyCreated) {
        taskTypeOptions = (tasks?.userTaskCategories ?? []).map((cat) => ({ label: lang === 'en' ? cat.name : cat.nameFr, value: cat.id }))
    } else {
        taskTypeOptions = (tasks?.taskCategories ?? []).map((cat) => ({label: lang === 'en' ? cat.name : cat.nameFr, value: cat.id }))
    }

    const updateFilesSelected = (index: number) => {
        const newFileNames = [...fileNames]
        newFileNames.splice(index, 1)
        setFileNames(newFileNames)
        const newAttachedFileIds = [...formik.values.attachedFileIds]

        newAttachedFileIds.splice(index, 1)
        formik.setFieldValue('attachedFileIds', newAttachedFileIds)
    }

    useEffect(() => {
        if (!isLoading && uploadedFiles) {
            const updatedAttachedFileIds = uploadedFiles.map(item => ({
                imageId: item
            }))
            formik.setFieldValue('attachedFileIds', [...(new Set([...formik.values.attachedFileIds, ...updatedAttachedFileIds]) as any)])
        }
    }, [isLoading, uploadedFiles])

    const customFormat = (date) => {
        return date ? format(date, 'dd/MM/yyyy HH:mm a') : '' // Return empty string if date is null
    }

    const handleBoxClick = () => {
        const dateTimePickerInput = document.getElementById('datetimepicker-input')
        if (dateTimePickerInput) {
            dateTimePickerInput.click()
        }
    }

    return (
        <>
            <Image src="/edit.svg" cursor='pointer' alt="edit icon" minWidth={5} minHeight={5} width={5} height={5} onClick={onOpen} />

            {isOpen && (
                <Modal isOpen={isOpen} onClose={onClose} size="xl">
                    <ModalOverlay bg="blackAlpha.700"></ModalOverlay>
                    <ModalContent>
                        <ModalHeader>{t('tasks.editTask')}</ModalHeader>
                        <ModalCloseButton />

                        <ModalBody>
                            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                <GridItem colSpan={2}>
                                    <FormControl id="title" isInvalid={!!formik.errors.title}>
                                        <FormLabel>{t('tasks.title')}</FormLabel>
                                        <Input disabled={!row.manuallyCreated} type="text" placeholder={t('tasks.title')} value={formik.values.title} onChange={formik.handleChange} />
                                        <FormErrorMessage>{!!formik.errors.title && formik.errors.title}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={2}>
                                    <FormControl id="description" isInvalid={!!formik.errors.description}>
                                        <FormLabel>{t('tasks.description')}</FormLabel>
                                        <Textarea disabled={!row.manuallyCreated} placeholder={t('tasks.description')} value={formik.values.description} onChange={formik.handleChange} />
                                        <FormErrorMessage>{!!formik.errors.description && formik.errors.description}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <FormControl id="type" isInvalid={!!formik.errors.type}>
                                        <FormLabel>{t('tasks.taskType')}</FormLabel>
                                        <Select disabled={!row.manuallyCreated} value={formik.values.type} onChange={formik.handleChange}>
                                            {taskTypeOptions?.map(({ label, value }) => (
                                                <option key={value} value={value}>
                                                    {/* {['pharmacy', 'prescription', 'medication'].includes(label) ? t(`tasks.transfer_${label}`) : t(`tasks.${label}`)} */}
                                                    {label}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{!!formik.errors.type && formik.errors.type}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <FormControl id="createdAt" isInvalid={!!formik.errors.createdAt}>
                                        <FormLabel>{t('tasks.createdAt')}</FormLabel>
                                        <Input disabled={!row.manuallyCreated} type="date" placeholder={t('tasks.createdAt')} value={formik.values.createdAt} onChange={formik.handleChange} />
                                        <FormErrorMessage>{!!formik.errors.createdAt && formik.errors.createdAt}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <FormControl id="priority" isInvalid={!!formik.errors.priority}>
                                        <FormLabel>{t('tasks.priority')}</FormLabel>
                                        <Select disabled={!row.manuallyCreated} placeholder={t('tasks.priority')} value={formik.values.priority} onChange={formik.handleChange}>
                                            <option value="high">{t('priority.high')}</option>
                                            <option value="medium">{t('priority.medium')}</option>
                                            <option value="standard">{t('priority.standard')}</option>
                                        </Select>
                                        <FormErrorMessage>{!!formik.errors.priority && formik.errors.priority}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <FormControl id="status" isInvalid={!!formik.errors.status}>
                                        <FormLabel>{t('common.status')}</FormLabel>
                                        <Select disabled={!row.manuallyCreated} placeholder={t('common.status')} value={formik.values.status} onChange={formik.handleChange}>
                                            <option value="todo">{t('tasks.toDo')}</option>
                                            <option value="inprogress">{t('tasks.inProgress')}</option>
                                            <option value="completed">{t('tasks.completed')}</option>
                                            {pharmacyUser?.getPharmacyUserDetailsByPharmacyId.isAdmin && (<option value="canceled">{t('tasks.cancelled')}</option>)}
                                        </Select>
                                        <FormErrorMessage>{!!formik.errors.status && formik.errors.status}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={2}>
                                    <FormControl id="dueDate" isInvalid={!!formik.errors.oAuthId}>
                                        <FormLabel>{t('tasks.dueDate')}</FormLabel>
                                        <Box
                                            position="relative"
                                            borderRadius={4}
                                            borderWidth={1}
                                            borderColor="gray.200"
                                            fontSize={14}
                                            p={2}
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleBoxClick}
                                        >
                                            <div>
                                                <DateTimePicker
                                                    disabled={!row.manuallyCreated}
                                                    locale={lang}
                                                    id="datetimepicker-input"
                                                    value={formik.values.dueDate ? new Date(formik.values.dueDate) : null}
                                                    onChange={handleDateChange}
                                                    showTimeSelect
                                                    timeIntervals={60}
                                                    placeHolderText={t('tasks.selectDateAndTime')}
                                                    dateFormat="dd/MM/yyyy HH:mm a"
                                                    customInput={<input value={customFormat(formik.values.dueDate)} placeholder={t('tasks.selectDateAndTime')} />}
                                                />
                                            </div>
                                        </Box>
                                        <FormErrorMessage>{!!formik.errors.dueDate && formik.errors.dueDate}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={2}>
                                    <FormControl id="oAuthId" isInvalid={!!formik.errors.oAuthId}>
                                        <FormLabel>{t('tasks.patient')}</FormLabel>
                                        <Select disabled={!row.manuallyCreated} value={formik.values.oAuthId} onChange={formik.handleChange}>
                                            <option>{t('common.select')}</option>
                                            {clients?.users?.map(({ oAuthId, firstName, lastName }) => (
                                                <option key={oAuthId} value={oAuthId}>
                                                    {firstName} {lastName}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{!!formik.errors.client && formik.errors.client}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={2}>
                                    <FormControl id="assignedTo" isInvalid={!!formik.errors.assignedTo}>
                                        <FormLabel>{t('tasks.establishmentEmployee')}</FormLabel>
                                        <ReactSelect
                                            placeholder={t('common.selectOptions')}
                                            isMulti
                                            isDisabled={loading}
                                            isLoading={loading}
                                            value={formik.values.assignedTo ?? []}
                                            onChange={(options) => {
                                                formik.setFieldValue('assignedTo', options)
                                            }}
                                            options={data?.getPharmacyUsers
                                                ?.map(({ userDetails, oAuthId }) => ({ label: `${userDetails.firstName} ${userDetails.lastName}`, value: oAuthId }))
                                                ?.sort((a, b) => (a?.label > b?.label ? 1 : -1))}
                                        />
                                        <FormErrorMessage>{!!formik.errors.assignedTo && formik.errors.assignedTo}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>

                                <GridItem colSpan={2}>
                                    <FormLabel htmlFor="attach-file" disabled={!row.manuallyCreated}>
                                        <Flex alignItems="center" gridGap={1} mb={2} cursor="pointer">
                                            <Text>
                                                {t('common.attachFile')} ({t('common.optional')})
                                            </Text>
                                            {!isLoading ? <MdAttachFile /> : <AiOutlineLoading className="spin" />}
                                        </Flex>

                                        <Input type="file" id="attach-file" disabled={isLoading || !row.manuallyCreated} hidden onChange={handleFile} />
                                    </FormLabel>

                                    {fileNames.map((name, index) => (
                                        <Flex key={name} alignItems="center" gridGap={1} cursor="pointer" onClick={() => updateFilesSelected(index)}>
                                            <Text fontSize={12}>
                                                {index + 1}. {name?.filename ? name?.filename : name}
                                            </Text>
                                            <MdClose />
                                        </Flex>
                                    ))}
                                </GridItem>
                            </Grid>
                        </ModalBody>

                        <ModalFooter>
                            <Button variant="solid" size="sm" colorScheme="blue" isLoading={editTaskQuery.loading} onClick={() => formik.handleSubmit()}>
                                {t('common.save')}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </>
    )
}

export default EditTask
