import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { MdCheck, MdClose, MdModeEdit } from 'react-icons/md'
import { useGetAllDrugsInfo } from '@/hooks/useGetAllDrugsInfo'
import {
  ButtonGroup,
  Flex,
  IconButton,
  Text,
  Box
} from '@chakra-ui/react'
import { IDrug, ISelectValueProps } from '@/utils/types'

export interface IEditDrug {
  defaultDrug: IDrug
  onSubmit: (value: ISelectValueProps) => void
}
export const EditDrug = ({ defaultDrug, onSubmit }: IEditDrug) => {
  const [inputValue] = useState('')
  const [value, setValue] = useState<ISelectValueProps>({
    label: `${defaultDrug?.name} ${
      defaultDrug?.strength ? defaultDrug?.strength : ''
    }`,
    value: defaultDrug
  })

  const [isEditing, setIsEditing] = useState(false)
  const allDrugs = useGetAllDrugsInfo({ query: inputValue })

  const handleChange = (newValue: any, actionMeta: any) => {
    setValue(newValue)
  }

  const handleEdit = () => setIsEditing(true)

  const handleSave = () => {
    setIsEditing(false)
    onSubmit(value)
  }

  const handleCancel = () => setIsEditing(false)

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {!isEditing && <Text>{value.label}</Text>}
      {isEditing && (
        <Box w="300px">
          <CreatableSelect
            isLoading={allDrugs?.loading}
            options={allDrugs?.data?.drugs?.drugDetails?.map((a) => ({
              label: `${a.name} ${a.strength ? a.strength : ''}`,
              value: a
            }))}
            defaultValue={{
              label: `${defaultDrug?.name} ${
                defaultDrug?.strength ? defaultDrug?.strength : ''
              }`,
              value: defaultDrug
            }}
            onChange={handleChange}
          />
        </Box>
      )}
      {isEditing ? (
        <ButtonGroup justifyContent="center" size="sm" ml={2}>
          <IconButton
            aria-label="check"
            icon={<MdCheck />}
            onClick={handleSave}
          />
          <IconButton
            aria-label="uncheck"
            icon={<MdClose />}
            onClick={handleCancel}
          />
        </ButtonGroup>
      ) : (
        <Flex justifyContent="center" ml={2}>
          <IconButton
            aria-label="uncheck"
            size="sm"
            color="gray.500"
            fontSize="md"
            icon={<MdModeEdit />}
            onClick={handleEdit}
          />
        </Flex>
      )}
    </Box>
  )
}
