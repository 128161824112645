import { gql, useQuery } from '@apollo/client'

const ORDERS_FULFILLED = gql`
  query {
    ordersFulfilled {
      orderId
      oAuthId
      orderItems {
        orderItemId
        prescriptionId
        refillQuantity
        fillDate
        priceInCents
      }
      state
      orderType
      comment
      addressId
      time
      stripeSource
      insuranceId
      createdAt
      userFirstName
      userLastName
    }
  }
`

export const useGetOrdersFulfilled = () =>
  useQuery(ORDERS_FULFILLED, {
    fetchPolicy: 'network-only'
  })
