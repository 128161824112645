import * as Yup from 'yup'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import moment from 'moment'
import MomentLocaleUtils from 'react-day-picker/moment'
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select as ChakaraSelect
} from '@chakra-ui/react'
import { IDrug, IPrescriber } from '@/utils/types'
import { useFormik } from 'formik'
import { useGetAllDrugsInfo } from '@/hooks/useGetAllDrugsInfo'
import { UserPrescriptionsContext, UserProfileContext } from '@/contexts'
import { useGetPrescribers } from '@/hooks/useGetPrescribers'
import { useAddPrescription } from '@/hooks/useAddPrescription'
import { useGetLatestPrescriptionId } from '@/hooks/useGetLatestPrescriptionId'
import { useTranslation } from '@/utils/i18n'
import { useTranslatePosology } from '@/hooks/useTranslatePosology'
import { Loader } from '..'

export interface IAddPrescriptionModalProps {
  isOpen: boolean
  onClose: () => void
}

const validationSchema = Yup.object().shape({
  oAuthId: Yup.string().required(),
  prescriptionId: Yup.number().required(),
  drugId: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required()
  }),
  license: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required()
  }),
  itemType: Yup.string().required(),
  dispenseType: Yup.string().required(),
  prescribedDate: Yup.date().required(),
  unitsPerRefill: Yup.number().required().min(1)
})

export const AddPrescriptionModal = ({ isOpen, onClose }: IAddPrescriptionModalProps) => {
  const t = useTranslation()
  const { refetchAll } = useContext(UserPrescriptionsContext)
  const { userId } = useContext(UserProfileContext)
  const [drugInputValue, setDrugInputValue] = useState('')
  const { translatePosology } = useTranslatePosology()
  const [prescriberInputValue, setPrescriberInputValue] = useState('')
  const { addPrescription, addPrescriptionQuery } = useAddPrescription()

  const handleOnPrescriptionSubmit = async (values) => {
    const input = {
      ...values,
      drugId: values.drugId.value,
      posology: translatePosology(values.posology),
      license: values.license.value,
      dispenseType: `${values.dispenseType}-based`,
      prescribedDate: values.prescribedDate.toString(),
      durationValidUntil: values.durationValidUntil.toString()
    }
    await addPrescription({
      variables: {
        prescriptionInput: input
      }
    })
    onClose()
    formik.resetForm()
    refetchAll()
  }

  const { id, loading } = useGetLatestPrescriptionId()
  const formik = useFormik({
    initialValues: {
      oAuthId: userId,
      prescriptionId: 0,
      drugId: null,
      license: null,
      itemType: 'writtenScript',
      dispenseType: 'renewal',
      classification: 'chronic',
      posology: '',
      prescribedDate: new Date(),
      unitsPerRefill: 0,
      quantityTotalQuantity: 0,
      quantityTotalDuration: 0,
      renewalRefillDuration: 0,
      renewalNumberOfRenewals: 0,
      durationRefillDuration: 0,
      durationValidUntil: new Date(),
      requireOriginal: true,
      verifyingPharmacist: true,
      transfer: false
    },
    validationSchema: validationSchema,
    onSubmit: handleOnPrescriptionSubmit
  })

  useEffect(() => {
    id && formik.setFieldValue('prescriptionId', id + 1)
  }, [id])

  const allDrugs = useGetAllDrugsInfo({ query: drugInputValue })
  const allPrescriber = useGetPrescribers({
    query: prescriberInputValue
  })
  const handleDrugChange = (newValue) => {
    formik.setFieldValue('drugId', {
      label: newValue.label,
      value: newValue.value
    })
  }

  const handlePrescriberChange = (newValue) => {
    formik.setFieldValue('license', {
      label: newValue.label,
      value: newValue.value
    })
  }

  const handlePrescribedDateChange = (day: Date) => {
    formik.setFieldValue('prescribedDate', moment(day).toDate())
  }

  const handleDurationValidUntilChange = (day: Date) => {
    formik.setFieldValue('durationValidUntil', moment(day).toDate())
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        {loading ? (
          <Loader fullWidth height="100px" />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <ModalHeader>{t('profile.addRx')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <FormControl id="prescriptionId" isInvalid={!!formik.errors.prescriptionId}>
                  <FormLabel>Rx #</FormLabel>
                  <Input type="text" value={formik.values.prescriptionId} onChange={formik.handleChange} />
                </FormControl>
                <FormControl id="drugId" isInvalid={!!formik.errors.drugId}>
                  <FormLabel>{t('prescription.drug')}</FormLabel>
                  <Select
                    isLoading={allDrugs?.loading}
                    options={allDrugs?.data?.drugs?.drugDetails?.map((a: IDrug) => ({
                      label: `${a.name} ${a.strength}`,
                      value: a.drugId
                    }))}
                    placeholder={t('prescription.dinNameOrIngredient')}
                    value={formik.values.drugId}
                    onInputChange={(newValue: string) => {
                      setDrugInputValue(newValue)
                    }}
                    onChange={handleDrugChange}
                  />
                  <FormErrorMessage>{!!formik.errors.drugId && t('prescription.drugRequired')}</FormErrorMessage>
                </FormControl>
                <FormControl id="itemType" isInvalid={!!formik.errors.itemType}>
                  <ChakaraSelect value={formik.values.itemType} onChange={formik.handleChange}>
                    <option value="writtenScript">{t('common.writtenScript')}</option>
                    <option value="verbalScript">{t('common.verbalScript')}</option>
                  </ChakaraSelect>
                </FormControl>
              </Grid>
              <Grid templateColumns="repeat(1, 1fr)" gap={4} my={4}>
                <FormControl id="posology" p={4} bg="gray.50">
                  <FormLabel>{t('prescription.posology')}</FormLabel>
                  <Input name="posology" bg="white" type="text" value={formik.values.posology} onChange={formik.handleChange} />
                  <FormHelperText>{translatePosology(formik.values.posology)}</FormHelperText>
                </FormControl>
              </Grid>
              <FormControl id="prescribedDate" isInvalid={!!formik.errors.prescribedDate}>
                <FormLabel>{t('prescription.prescribeDate')}</FormLabel>
                <Box className="daypicker">
                  <DayPickerInput
                    value={formik.values.prescribedDate}
                    onDayChange={handlePrescribedDateChange}
                    formatDate={() => MomentLocaleUtils.formatDate(formik.values.prescribedDate, 'LL', t('common.locale'))}
                    parseDate={MomentLocaleUtils.parseDate}
                    format="LL"
                    dayPickerProps={{
                      selectedDays: formik.values.prescribedDate,
                      disabledDays: {
                        daysOfWeek: [0, 6]
                      },
                      localeUtils: MomentLocaleUtils,
                      locale: t('common.locale')
                    }}
                  />
                </Box>
              </FormControl>
              <Grid templateColumns="repeat(2, 1fr)" gap={4} my={4} bg="gray.50" p={4}>
                <FormControl id="dispenseType" isInvalid={!!formik.errors.dispenseType}>
                  <FormLabel>{t('prescription.typeOfPrescription')}</FormLabel>

                  <ChakaraSelect value={formik.values.dispenseType} onChange={formik.handleChange} bg="white">
                    <option value="quantity">{t('prescription.quantityBased')}</option>
                    <option value="renewal">{t('prescription.renewalBased')}</option>
                    <option value="duration">{t('prescription.durationBased')}</option>
                  </ChakaraSelect>
                </FormControl>
                <FormControl id="unitsPerRefill" isInvalid={!!formik.errors.unitsPerRefill}>
                  <FormLabel>{t('prescription.unitsPerRefill')}</FormLabel>
                  <NumberInput bg="white" min={0} onChange={(valueString) => formik.setFieldValue('unitsPerRefill', +valueString)} value={formik.values.unitsPerRefill} name="unitsPerRefill">
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                {formik.values.dispenseType === 'renewal' && (
                  <>
                    <FormControl id="renewalRefillDuration">
                      <FormLabel>{t('prescription.refillDurationDays')}</FormLabel>
                      <NumberInput
                        bg="white"
                        min={0}
                        onChange={(valueString) => formik.setFieldValue('renewalRefillDuration', +valueString)}
                        value={formik.values.renewalRefillDuration}
                        name="renewalRefillDuration"
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                    <FormControl id="renewalNumberOfRenewals">
                      <FormLabel>{t('prescription.numberOfRenewals')}</FormLabel>
                      <NumberInput
                        bg="white"
                        min={0}
                        onChange={(valueString) => formik.setFieldValue('renewalNumberOfRenewals', +valueString)}
                        value={formik.values.renewalNumberOfRenewals}
                        name="renewalNumberOfRenewals"
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  </>
                )}
                {formik.values.dispenseType === 'quantity' && (
                  <>
                    <FormControl id="quantityTotalQuantity">
                      <FormLabel>{t('prescription.totalQuantity')}</FormLabel>
                      <NumberInput
                        bg="white"
                        min={0}
                        onChange={(valueString) => formik.setFieldValue('quantityTotalQuantity', +valueString)}
                        value={formik.values.quantityTotalQuantity}
                        name="quantityTotalQuantity"
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                    <FormControl id="quantityTotalDuration">
                      <FormLabel>{t('prescription.totalDuration')}</FormLabel>
                      <NumberInput
                        bg="white"
                        min={0}
                        onChange={(valueString) => formik.setFieldValue('quantityTotalDuration', +valueString)}
                        value={formik.values.quantityTotalDuration}
                        name="quantityTotalDuration"
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  </>
                )}
                {formik.values.dispenseType === 'duration' && (
                  <>
                    <FormControl id="durationRefillDuration">
                      <FormLabel>{t('prescription.refillDurationDays')}</FormLabel>
                      <NumberInput
                        bg="white"
                        min={0}
                        onChange={(valueString) => formik.setFieldValue('durationRefillDuration', +valueString)}
                        value={formik.values.durationRefillDuration}
                        name="durationRefillDuration"
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                    <FormControl id="durationValidUntil">
                      <FormLabel>{t('prescription.validUntil')}</FormLabel>
                      <Box className="daypicker">
                        <DayPickerInput
                          style={{ background: '#fff' }}
                          value={formik.values.durationValidUntil}
                          onDayChange={handleDurationValidUntilChange}
                          formatDate={() => MomentLocaleUtils.formatDate(formik.values.durationValidUntil, 'LL', t('common.locale'))}
                          parseDate={MomentLocaleUtils.parseDate}
                          format="LL"
                          dayPickerProps={{
                            selectedDays: formik.values.durationValidUntil,
                            disabledDays: {
                              daysOfWeek: [0, 6]
                            },
                            localeUtils: MomentLocaleUtils,
                            locale: t('common.locale')
                          }}
                        />
                      </Box>
                    </FormControl>
                  </>
                )}
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <FormControl id="license">
                  <FormLabel>{t('prescription.prescriber')}</FormLabel>
                  <Select
                    placeholder={t('common.select')}
                    isLoading={allPrescriber?.loading}
                    options={allPrescriber?.data?.prescribers?.map((a: IPrescriber) => ({
                      label: `${a.firstName} ${a.lastName} (${a.license})`,
                      value: a.license
                    }))}
                    value={formik.values.license}
                    onInputChange={(newValue: string) => {
                      setPrescriberInputValue(newValue)
                    }}
                    onChange={handlePrescriberChange}
                  />
                </FormControl>
                <FormControl id="classify">
                  <FormLabel>{t('prescription.classifyOptional')}</FormLabel>

                  <ChakaraSelect value={formik.values.classification} onChange={formik.handleChange} bg="white">
                    <option value="chronic">{t('prescription.chronic')}</option>
                    <option value="prn">PRN</option>
                  </ChakaraSelect>
                </FormControl>
              </Grid>
              <HStack spacing={4} my={4} bg="blue.50" p={4} borderRadius="md">
                <Checkbox name="requireOriginal" isChecked={formik.values.requireOriginal} onChange={formik.handleChange}>
                  {t('prescription.originalScriptObtained')}
                </Checkbox>
                <Checkbox name="verifyingPharmacist" isChecked={formik.values.verifyingPharmacist} onChange={formik.handleChange}>
                  {t('prescription.verifiedByPharmacist')}
                </Checkbox>
                <Checkbox name="transfer" isChecked={formik.values.transfer} onChange={formik.handleChange}>
                  {t('prescription.transferIn')}
                </Checkbox>
              </HStack>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                {t('common.close')}
              </Button>
              <Button colorScheme="blue" type="submit" isLoading={addPrescriptionQuery?.loading}>
                {t('common.save')}
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  )
}
