import _ from 'lodash'
import React, { useCallback, useContext, useMemo } from 'react'
import { getPrescriptionStatus } from './prescriptionCard'
import { IPrescription, ISelectValueProps } from '@/utils/types'
import { Loader } from '../loader'
import { RenewRx } from './modal/renewRx'
import { useEditOrderItem, useEditPrescription, useEditRefillQty, useGetPrescriptionById, useRenewRx } from '@/hooks'
import { useUser } from '@auth0/nextjs-auth0'

import { UserPrescriptionsContext } from '@/contexts'
import { Button, Flex, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack, Badge, Grid, Box, Checkbox } from '@chakra-ui/react'
import { EditDate, EditDrug, EditQty, EditScript, EditStopped, EditClassification, OrderItemsTable, EditText } from './modal'
import { useTranslation } from '@/utils/i18n'
import { EditPosology } from './modal/editPosology'
import { useAddDrug } from '@/hooks/useAddDrug'

export interface IPrescriptionModal {
  isOpen: boolean
  onClose: () => void
  prescriptionId: string
}

export const PrescriptionModal = ({ isOpen, onClose, prescriptionId }: IPrescriptionModal) => {
  const t = useTranslation()
  const user = useUser()
  const { refetchAll } = useContext(UserPrescriptionsContext)
  const { data, loading, refetch } = useGetPrescriptionById({
    prescriptionId: +prescriptionId
  })

  const { editPrescription } = useEditPrescription()
  const { addDrug } = useAddDrug()

  const { renew } = useRenewRx()

  const { editOrderItem } = useEditOrderItem()
  const { editRefillQty } = useEditRefillQty()

  const prescription: IPrescription = useMemo(() => data?.getPrescriptionFromPrescriptionId, [data])

  const refetchPrescription = () => {
    refetch({
      prescriptionId: +prescriptionId
    })
  }

  const updatePrescription = async (prescription: any) => {
    await editPrescription({
      variables: {
        ...prescription,
        prescriptionId: +prescriptionId
      }
    })
    refetchPrescription()
    refetchAll()
  }

  const renewPrescription = useCallback(
    async (data: any) => {
      await renew({
        variables: {
          ...data,
          oAuthId: user.user?.sub,
          prescriptionId: +prescriptionId
        }
      })
      refetchPrescription()
    },
    [prescriptionId, user.user?.sub]
  )

  const updateRefillQty = useCallback(
    async (data: any) => {
      await editRefillQty({
        variables: {
          ...data,
          prescriptionId: +prescriptionId
        }
      })
      refetchPrescription()
      refetchAll()
    },
    [prescriptionId]
  )
  const updateOrderItem = useCallback(
    async (orderItem: any) => {
      prescription?.orderItems[0] &&
        (await editOrderItem({
          variables: {
            ...orderItem,
            orderItemId: prescription?.orderItems[0].orderItemId
          }
        }))
      refetchPrescription()
      refetchAll()
    },
    [prescription?.orderItems[0]]
  )

  const handleDrugEdit = async (nextValue: ISelectValueProps) => {
    if (nextValue?.value?.drugId) {
      await updatePrescription({
        drugId: nextValue.value.drugId
      })
    } else if (nextValue?.value) {
      addDrug({
        variables: {
          drugInput: {
            nameEnCapitalized: nextValue.value
          }
        }
      }).then(async (result) => {
        await updatePrescription({
          drugId: result?.data?.addDrug?.drugId
        })
      })
    }
  }

  const handleEditScript = async (nextValue: ISelectValueProps) =>
    await updatePrescription({
      itemType: nextValue.value
    })

  const handleEditClassification = async (nextValue: ISelectValueProps) =>
    await updatePrescription({
      classification: nextValue.value
    })

  const handleEditStopped = async (status, reason) => {
    status
      ? await updatePrescription({
          stoppedStatus: status,
          stoppedReason: reason
        })
      : await updatePrescription({
          stoppedStatus: status
        })
  }

  const handleEditGST = async (e) => {
    await updatePrescription({
      gst: e.target.checked,
      qst: !!prescription?.settings?.taxable?.qst
    })
  }

  const handleEditQST = async (e) => {
    await updatePrescription({
      qst: e.target.checked,
      gst: !!prescription?.settings?.taxable?.gst
    })
  }

  const handleRenewPrescription = async () => {
    await renewPrescription({
      refillQuantity: prescription.orderItems?.length > 0 ? _.orderBy(prescription.orderItems, ['orderId'], ['desc'])[0]?.refillQuantity : prescription.unitsPerRefill
    })
  }

  const handleRenewWithQty = async (qty) => {
    await renewPrescription({
      refillQuantity: qty
    })
  }

  const handleKillRenewal = async () => {
    updateRefillQty({
      refillQuantity: 0
    })
  }

  const handleEditFillDate = async (date: Date) => {
    await updateOrderItem({
      fillDate: date.toString()
    })
  }

  const handleEditExpirationDate = async (date: Date) => {
    await updatePrescription({
      validUntil: date.toString()
    })
  }

  const handleEditPrescribeDate = async (date: Date) => {
    await updatePrescription({
      prescribedDate: date.toString()
    })
  }

  const handleEditPrescribeQty = async (qty, dur) => {
    await updatePrescription({
      unitsPerRefill: qty,
      refillDuration: dur
    })
  }

  const handleEditFillQty = async (qty, dur) => {
    await updateRefillQty({
      refillQuantity: qty
    })
  }

  const handleEditPrescribeRENQty = async (qty, dur) => {
    await updatePrescription({
      numberOfRefills: qty
    })
  }

  const handleEditPosology = async (v) => {
    await updatePrescription({ posology: v })
  }

  const handleEditNotes = async (v) => {
    await updatePrescription({ note: v })
  }

  const renderTitle = (p: IPrescription) => {
    return (
      <VStack alignItems="start" spacing={0}>
        <Text fontSize="md" fontWeight="normal" color="gray.500" mb={2}>
          Rx {p?.externalPrescriptionNumber ?? p?.prescriptionId}
        </Text>
        <HStack spacing={2} justifyContent="space-between" w="full">
          <VStack alignItems="start">
            <EditDrug defaultDrug={p?.drug} onSubmit={handleDrugEdit} />
            <HStack spacing={1}>
              <Badge colorScheme="blue" variant="solid">
                {t(`prescription.${p?.drug.form}`)}
              </Badge>
              <Badge colorScheme="gray" variant="solid">
                {t(`prescription.${p?.dispenseType}`)}
              </Badge>
            </HStack>
          </VStack>
          <HStack spacing={2}>
            <EditScript defaultValue={t(`common.${p?.itemType}`)} onChange={handleEditScript} />
          </HStack>
        </HStack>
      </VStack>
    )
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay bg="blackAlpha.700"></ModalOverlay>
        <ModalContent overflow="hidden">
          <Box height="50px" bg={getPrescriptionStatus(prescription)}></Box>
          {loading ? (
            <Loader fullWidth props={{ p: 10 }} />
          ) : (
            <>
              <ModalHeader>{renderTitle(prescription)} </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex justifyContent="space-between">
                  <HStack spacing={2} alignItems="center">
                    <EditClassification defaultValue={prescription?.classification ? t(`prescription.${prescription?.classification}`) : t('prescription.none')} onChange={handleEditClassification} />
                    <Box pt={5}>
                      <EditStopped value={prescription?.stoppedStatus} onChange={handleEditStopped} />
                    </Box>
                  </HStack>
                  <HStack>
                    <Checkbox isChecked={!!prescription?.settings?.taxable?.gst} colorScheme="blue" onChange={handleEditGST}>
                      GST
                    </Checkbox>
                    <Checkbox isChecked={!!prescription?.settings?.taxable?.qst} colorScheme="blue" onChange={handleEditQST}>
                      QST
                    </Checkbox>
                    <RenewRx onRenew={handleRenewPrescription} onRenewWithQty={handleRenewWithQty} />
                    <Button colorScheme="red" variant="outline" disabled={!prescription?.orderItems?.length} ml={2} onClick={handleKillRenewal}>
                      {t('prescription.killRenewal')}
                    </Button>
                  </HStack>
                </Flex>
                <Grid templateColumns="repeat(3, 1fr)" gap={4} mt={8}>
                  <EditDate label={t('prescription.fillDate')} isNotServed={!prescription?.orderItems[0]?.fillDate} defaultDate={prescription?.orderItems[0]?.fillDate} onChange={handleEditFillDate} />
                  <EditDate label={t('prescription.expirationDate')} defaultDate={prescription?.validUntil} onChange={handleEditExpirationDate} />
                  <EditDate label={t('prescription.prescribeDate')} defaultDate={prescription?.prescribedDate} onChange={handleEditPrescribeDate} />
                  <EditQty
                    label={t('prescription.unitsPerRefillDuration')}
                    value={`${prescription?.unitsPerRefill} (${prescription?.prescribeDuration})`}
                    fieldLabel1={t('prescription.unitsPerRefill')}
                    defaultValue1={prescription?.unitsPerRefill}
                    fieldLabel2={t('prescription.duration')}
                    defaultValue2={prescription?.prescribeDuration}
                    onChange={handleEditPrescribeQty}
                  />
                  <EditQty
                    value={
                      prescription?.remainingREN === 99999
                        ? '∞'
                        : `${prescription?.remainingREN ? prescription?.remainingREN : '-'} (${prescription?.remainingQuantity ? prescription?.remainingQuantity : '-'})`
                    }
                    label={t('prescription.remainingQty')}
                    editable={false}
                  />
                  <EditQty
                    label={t('prescription.fillQty')}
                    value={`${prescription?.fillQuantity ? prescription?.fillQuantity : t('common.notServed')}`}
                    fieldLabel1={t('prescription.quantity')}
                    defaultValue1={prescription?.fillQuantity}
                    onChange={handleEditFillQty}
                    editable={!!prescription?.fillQuantity}
                  />
                  {prescription?.dispenseType === 'renewal' ? (
                    <EditQty
                      label={t('prescription.prescribeREN')}
                      value={`${prescription?.numberOfRefills} (${prescription?.totalQuantity})`}
                      fieldLabel1={t('prescription.numberOfRefills')}
                      defaultValue1={prescription?.numberOfRefills}
                      onChange={handleEditPrescribeRENQty}
                    />
                  ) : (
                    <EditQty label="Fraction" value={`${prescription?.fraction}`} editable={false} />
                  )}
                  <Box></Box>
                  <EditQty label={t('prescription.duration')} value={`${prescription?.refillDuration}`} editable={false} />
                </Grid>
                <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={8}>
                  <EditPosology defaultValue={prescription?.posology} onChange={handleEditPosology} />
                  <EditText label={t('prescription.notes')} onChange={handleEditNotes} type="textarea" defaultValue={prescription?.note} />
                </Grid>
                <OrderItemsTable orderItems={prescription?.orderItems} />
              </ModalBody>

              <ModalFooter></ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
