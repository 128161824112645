import React from 'react'
import { HStack, Text, VStack } from '@chakra-ui/react'

export interface ILabelInfo {
  label: string
  value: any
  props?: any
  size?: 'sm' | 'md'
  orientation?: 'vertical' | 'horizontal'
}

export const LabelInfo = ({
  label,
  value,
  props,
  size = 'sm',
  orientation = 'vertical'
}: ILabelInfo) => {
  return orientation === 'vertical' ? (
    <VStack alignItems="start" spacing={0}>
      <Text fontSize={size === 'sm' ? 'xs' : 'sm'} color="gray.400">
        {label}
      </Text>
      <Text
        fontWeight="medium"
        fontSize={size === 'sm' ? 'sm' : 'md'}
      >
        {typeof value === 'function' ? value() : value || '-'}
      </Text>
    </VStack>
  ) : (
    <HStack spacing={4}>
      <Text fontSize={size === 'sm' ? 'xs' : 'sm'} color="gray.400">
        {label}
      </Text>
      <Text
        fontWeight="medium"
        fontSize={size === 'sm' ? 'sm' : 'md'}
      >
        {typeof value === 'function' ? value() : value || '-'}
      </Text>
    </HStack>
  )
}
