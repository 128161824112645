import { useGetDeliveries } from '@/hooks/useGetDeliveries'
import { useGetOrdersFulfilled } from '@/hooks/useGetFulfilledOrders'
import { useTranslation } from '@/utils/i18n'
import { IOrder } from '@/utils/types'
import { ButtonGroup, Button } from '@chakra-ui/button'
import {
  Tag,
  Box,
  Link,
  Text,
  HStack,
  useDisclosure,
  Badge
} from '@chakra-ui/react'
import moment from 'moment'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { MdOutlineOpenInNew } from 'react-icons/md'
import { ColDef, DataTable } from '..'
import { OrderModal } from '../userOrders/orderModal'

export const Orders = () => {
  const t = useTranslation()
  const [showDeliveries, setShowDeliveries] = useState<boolean>(true)
  const deliveriesQuery = useGetDeliveries()
  const ordersFulfilledQuery = useGetOrdersFulfilled()
  const router = useRouter()
  const [selectedOrderId, setSelectedOrderId] = useState<string>()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const orders: IOrder[] = showDeliveries
    ? deliveriesQuery?.data?.deliveries
    : ordersFulfilledQuery?.data?.ordersFulfilled

  const isLoading = showDeliveries
    ? deliveriesQuery?.loading
    : ordersFulfilledQuery?.loading

  const renderOrderType = (row: IOrder) => {
    return (
      <Badge
        colorScheme={
          row?.orderType === 'pickup'
            ? 'pink'
            : row?.orderType === 'mail'
            ? 'blue'
            : 'green'
        }
      >
        {t(`orders.${row?.orderType}`)}
      </Badge>
    )
  }

  const renderUser = (row: IOrder) => {
    return (
      <HStack
        spacing={1}
        cursor="pointer"
        _hover={{ color: 'blue.500' }}
        onClick={() => {
          router.push(`/users/${row?.oAuthId?.substring(6)}`)
        }}
      >
        <Text>
          {row?.userFirstName} {row?.userLastName}
        </Text>
        <MdOutlineOpenInNew />
      </HStack>
    )
  }

  const cols: ColDef<any>[] = [
    {
      colName: t('orders.orderId'),
      render: (row: IOrder) => (
        <Link
          textDecoration="underline"
          _hover={{ color: 'blue.500' }}
        >{`#${row?.orderId}`}</Link>
      ),
      key: 'order_id',
      compareFn: (a: IOrder, b: IOrder) => {
        if (a?.orderId < b?.orderId) {
          return -1
        }
        if (a?.orderId > b?.orderId) {
          return 1
        }
        return 0
      }
    },
    {
      colName: t('orders.orderPlaced'),
      render: (row: IOrder) => moment(row?.createdAt).format('LLL'),
      key: 'order_date',
      compareFn: (a: IOrder, b: IOrder) => {
        if (
          moment(a?.createdAt).toDate() <
          moment(b?.createdAt).toDate()
        ) {
          return -1
        }
        if (
          moment(a?.createdAt).toDate() >
          moment(b?.createdAt).toDate()
        ) {
          return 1
        }
        return 0
      }
    },

    {
      colName: t('orders.name'),
      render: renderUser,
      key: 'order_user',
      compareFn: (a: IOrder, b: IOrder) => {
        if (a?.userFirstName < b?.userFirstName) {
          return -1
        }
        if (a?.userFirstName > b?.userFirstName) {
          return 1
        }
        return 0
      }
    },
    {
      colName: t('orders.orderType'),
      render: renderOrderType,
      key: 'order_type',
      compareFn: (a: IOrder, b: IOrder) => {
        if (a?.orderType < b?.orderType) {
          return -1
        }
        if (a?.orderType > b?.orderType) {
          return 1
        }
        return 0
      }
    },
    {
      colName: t('orders.deliveryTime'),
      render: (row: IOrder) => moment(row?.time).format('LLL'),
      key: 'order_delivery_time',
      compareFn: (a: IOrder, b: IOrder) => {
        if (moment(a?.time).toDate() < moment(b?.time).toDate()) {
          return -1
        }
        if (moment(a?.time).toDate() > moment(b?.time).toDate()) {
          return 1
        }
        return 0
      }
    }
  ]

  return (
    <Box>
      <Box mx={4}>
        <ButtonGroup size="sm" isAttached variant="outline">
          <Button
            mr="-px"
            bg={showDeliveries ? 'blue.50' : 'white'}
            color={showDeliveries ? 'blue.600' : 'gray.600'}
            onClick={(e) => {
              setShowDeliveries(true)
            }}
          >
            <HStack spacing={2}>
              <Text> {t('orders.deliveries')}</Text>
              {deliveriesQuery?.data && (
                <Tag size="sm" colorScheme="blue" variant="solid">
                  {deliveriesQuery?.data?.deliveries?.length}
                </Tag>
              )}
            </HStack>
          </Button>
          <Button
            mr="-px"
            bg={!showDeliveries ? 'blue.50' : 'white'}
            color={!showDeliveries ? 'blue.600' : 'gray.600'}
            onClick={(e) => {
              setShowDeliveries(false)
            }}
          >
            <HStack spacing={2}>
              <Text> {t('orders.upcomingOrders')}</Text>
              {ordersFulfilledQuery?.data && (
                <Tag size="sm" colorScheme="blue" variant="solid">
                  {
                    ordersFulfilledQuery?.data?.ordersFulfilled
                      ?.length
                  }
                </Tag>
              )}
            </HStack>
          </Button>
        </ButtonGroup>
      </Box>
      <Box bg="white" mx={4} mt={4} borderWidth={1} borderRadius="md">
        <DataTable
          rows={orders?.map((o) => ({ ...o, id: o.orderId }))}
          cols={cols}
          isLoading={isLoading}
          rowPadding={3}
          defaultPageSize={25}
          onRowItemClick={(row: IOrder) => {
            setSelectedOrderId(row?.orderId)
            onOpen()
          }}
        />
      </Box>
      {isOpen && selectedOrderId && (
        <OrderModal
          orderId={selectedOrderId}
          onClose={onClose}
          isOpen={isOpen}
        />
      )}
    </Box>
  )
}
