import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'
import { useSuccessToast } from './useSuccessToast'

const ADD_DRUG = gql`
  mutation addDrug($drugInput: DrugInput!) {
    addDrug(drugInput: $drugInput) {
      drugId
    }
  }
`

export const useAddDrug = () => {
  const t = useTranslation()
  const [addDrug, addDrugQuery] = useMutation(ADD_DRUG)
  useErrorToast({ error: addDrugQuery?.error })
  useSuccessToast({
    success: !!addDrugQuery?.data,
    message: t('common.drugAdded')
  })
  return { addDrug, addDrugQuery }
}
