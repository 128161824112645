import React, { useContext, useMemo } from 'react'
import { Loader } from '@/components/loader'
import { useRouter } from 'next/router'
import { UserProfileTabList } from './userProfileTabList'
import { UserTopProfile } from './userTopProfile'

import {
  UserDocuments,
  UserInsurance,
  UserMedicalHistory,
  UserMore,
  UserOrders,
  UserPrescriptions
} from '@/components'

import {
  Box,
  Button,
  TabPanels,
  TabPanel,
  Tabs,
  Text
} from '@chakra-ui/react'
import {
  UserInsuranceContext,
  UserOrdersContext,
  UserPrescriptionsContext,
  UserProfileContext
} from '@/contexts'
import { useTranslation } from '@/utils/i18n'

export const PatientProfile = () => {
  const { user, isLoading, currentTab } = useContext(
    UserProfileContext
  )
  const t = useTranslation()
  const { prescriptions } = useContext(UserPrescriptionsContext)
  const { orders, isLoading: isOrdersLoading } = useContext(
    UserOrdersContext
  )

  const { insurances, isLoading: isInsuranceLoading } = useContext(
    UserInsuranceContext
  )

  const router = useRouter()

  if (isLoading) {
    return <Loader fullWidth props={{ h: '100vh' }} />
  }

  const handleOnBackClick = () => {
    router.back()
  }

  const allergies = useMemo(() => {
    return user?.allergies?.filter(
      (a) => a.allergyGENcode !== 'noAllergies'
    )
  }, [user])

  const intolerances = useMemo(() => {
    return user?.intolerances?.filter(
      (a) => a.intoleranceGENcode !== 'noIntolerances'
    )
  }, [user])

  return (
    <Box p={4}>
      <Button
        variant="outline"
        size="sm"
        bg="white"
        onClick={handleOnBackClick}
        mb={4}
      >
        {t('common.back')}
      </Button>
      <Box
        borderWidth={1}
        borderColor="gray.200"
        bg="white"
        borderRadius={10}
      >
        <UserTopProfile user={user} />
      </Box>
      <Box
        borderWidth={1}
        borderColor="gray.200"
        bg="white"
        mt={10}
        position="relative"
        overflow="hidden"
        borderRadius={10}
      >
        <Tabs
          size="md"
          index={currentTab || 0}
          variant="enclosed"
          onChange={(index) => {
            router.push(`?tab=${index}`)
          }}
        >
          <UserProfileTabList
            tabItems={[
              {
                name: t('profile.prescriptions'),
                badge: prescriptions?.length
              },
              {
                name: t('profile.orders'),
                badge: orders?.length
              },
              {
                name: t('profile.insurance'),
                badge: insurances?.length
              },
              {
                name: t('profile.medicalHistory'),
                badge:
                  allergies?.length +
                  user?.pathologies?.length +
                  intolerances?.length
              },
              { name: t('profile.documents') },
              { name: t('profile.more') }
            ]}
          />
          <TabPanels p={10}>
            <TabPanel p={0}>
              <UserPrescriptions />
            </TabPanel>
            <TabPanel>
              {isOrdersLoading ? (
                <Box justifyContent="center" display="flex">
                  <Text fontSize="sm">{t('common.loading')}</Text>
                </Box>
              ) : (
                <UserOrders orders={orders} />
              )}
            </TabPanel>
            <TabPanel>
              {isInsuranceLoading ? (
                <Box justifyContent="center" display="flex">
                  <Text fontSize="sm">{t('common.loading')}</Text>
                </Box>
              ) : (
                <UserInsurance />
              )}
            </TabPanel>
            <TabPanel>
              {currentTab === 3 && <UserMedicalHistory />}
            </TabPanel>
            <TabPanel>
              <UserDocuments />
            </TabPanel>
            <TabPanel>
              <UserMore />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  )
}
