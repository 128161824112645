import moment from 'moment'
import React, { useContext, useEffect } from 'react'
import { Box, Divider, HStack, Text, VStack } from '@chakra-ui/react'
import { IOrder } from '@/utils/types'
import { useGetSettlement } from '@/hooks/useGetSettlement'
import { PharmaciesContext } from '@/contexts/PharmacyProvider'
import { useTranslation } from '@/utils/i18n'

export interface IReceiptProps {
  ref: any
  order: IOrder
  totalPrice: number
  totalQST: number
  totalGST: number
}

export const Receipt = React.forwardRef(
  ({ order, totalPrice, totalGST, totalQST }: IReceiptProps, ref) => {
  const t = useTranslation()
    const { data, loading, refetch } = useGetSettlement({
      orderId: order?.orderId
    })

    useEffect(() => {
      if (order) {
        refetch({ orderId: order?.orderId })
      }
    }, [order])

    const { selectedPharmacy } = useContext(PharmaciesContext)
    const settlement =
      data?.getSettlement.settlement.auths[
        data?.getSettlement.settlement.auths.length - 1
      ]
    const brand =
      settlement?.card.type === 'MC' ? 'Mastercard' : 'Visa'
    const last4 = settlement?.card.lastDigits

    if (loading || !data?.getSettlement.settlement.auths.length) {
      return <Text p={4}>{t('common.notAvailable')}</Text>
    }

    return (
      <Box ref={ref as any} w="full" bg="gray.50" p={4}>
        <Box borderWidth={1} bg="white">
          <Box p={4}>
            <Text
              fontSize="md"
              fontWeight="semibold"
              color="gray.800"
            >
              {selectedPharmacy?.pharmacyName}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {' '}
              {selectedPharmacy?.pharmacyAddress}
            </Text>{' '}
            {/* <Text fontSize="sm" color="gray.500">
              {' '}
              1-888-350-7882
            </Text> */}
          </Box>
          <Divider />
          <Box p={4} fontSize="sm">
            <Text>
              {' '}
              Commande # {order.orderId} / Order # {order.orderId}
            </Text>{' '}
            {settlement && (
              <Text>
                {t('orders.receiptPaymentMethod')}: {brand} {t('orders.receiptPaymentEndingIn')} {last4}
              </Text>
            )}
            {settlement && (
              <Text>
                ${(settlement.amount / 100).toFixed(2)} (
                {settlement.status})
                <Text>
                {t('orders.receiptPaymentOn')} {moment(settlement.txnTime).format('LLL')}
                </Text>
              </Text>
            )}
          </Box>
          <Divider />
          <Box p={4}>
            <Box
              w="full"
              display="flex"
              justifyContent="space-between"
              fontSize="sm"
              color="gray.500"
            >
              <HStack
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start'
                }}
              >
                <Text w={20}>{t('orders.receiptItemQty')}</Text>
                <Text>{t('orders.receiptItemName')}</Text>
              </HStack>
              <Text>{t('orders.receiptPrice')}</Text>
            </Box>
            {order.orderItems.map((item) => {
              return (
                <Box
                  py={2}
                  key={item.orderItemId}
                  w="full"
                  display="flex"
                  justifyContent="space-between"
                >
                  <HStack
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start'
                    }}
                  >
                    <Text w={20}>{item.refillQuantity}</Text>
                    <Text fontWeight="semibold">
                      {item?.prescription
                        ? item?.prescription.drug.name
                        : null}
                    </Text>
                  </HStack>
                  <Text>${(item.priceInCents / 100).toFixed(2)}</Text>
                </Box>
              )
            })}
            <Divider />
            <VStack
              alignItems="end"
              justifyContent="space-between"
              mt={2}
              spacing={1}
            >
              <Text fontSize="lg" fontWeight="semibold">
              {t('orders.receiptTotal')}: $
                {(totalPrice + totalGST + totalQST).toFixed(2)}
              </Text>
              <Text fontSize="sm">
              {t('orders.receiptSubTotal')}: ${totalPrice.toFixed(2)}
              </Text>
              <Text fontSize="sm">GST: ${totalGST}</Text>
              <Text fontSize="sm">QST: ${totalQST}</Text>
            </VStack>
          </Box>
          <Divider />
          <Box p={4} fontSize="sm">
            <Text>GST/HST: 845297563</Text>
            <Text>PST/RST/QST: 1041387129</Text>
          </Box>
        </Box>
      </Box>
    )
  }
)
