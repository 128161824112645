import React, { useState } from 'react'
import Select from 'react-select'
import {
  Box,
  ButtonGroup,
  HStack,
  IconButton,
  Text
} from '@chakra-ui/react'
import { IOrder, IOrderTypeEnum } from '@/utils/types'
import moment from 'moment'
import { MdCheck, MdClose } from 'react-icons/md'
import { useTranslation } from '@/utils/i18n'
export interface IEditOrderType {
  order: IOrder
  onChange: (orderType: IOrderTypeEnum) => void
}
export const EditOrderType = ({
  order,
  onChange
}: IEditOrderType) => {
  const t = useTranslation()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const [orderType, setOrderType] = useState<IOrderTypeEnum>(
    order?.orderType
  )

  const handleChangeOrderType = (v) => {
    setOrderType(v.value)
    if (v.value !== order?.orderType) {
      setShowConfirm(true)
    } else {
      setShowConfirm(false)
    }
  }

  const handleSave = () => {
    setShowConfirm(false)
    onChange(orderType)
  }

  const handleCancel = () => {
    setOrderType(order?.orderType)
    setShowConfirm(false)
  }
  return (
    <Box>
      <HStack spacing={2}>
        <Box w="150px">
          <Select
            value={{
              label: t(`orders.${orderType}`),
              value: orderType
            }}
            options={[
              { label: t('orders.delivery'), value: 'delivery' },
              { label: t('orders.mail'), value: 'mail' },
              { label: t('orders.pickup'), value: 'pickup' }
            ]}
            onChange={handleChangeOrderType}
          />
        </Box>
        {showConfirm && (
          <ButtonGroup justifyContent="center" size="sm" ml={2}>
            <IconButton
              aria-label="check"
              icon={<MdCheck />}
              onClick={handleSave}
            />
            <IconButton
              aria-label="uncheck"
              icon={<MdClose />}
              onClick={handleCancel}
            />
          </ButtonGroup>
        )}
        <Text fontSize="sm" color="gray.500">
          {t('orders.placedOn')}{' '}
          {moment(order?.createdAt).format('LLL')}
        </Text>
      </HStack>
    </Box>
  )
}
