import React, { useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Input
} from '@chakra-ui/react'
import { useTranslation } from '@/utils/i18n'

export interface IRenewRxProps {
  onRenewWithQty: (qty: number) => void
  onRenew: () => void
}
export const RenewRx = ({
  onRenewWithQty,
  onRenew
}: IRenewRxProps) => {
  const t = useTranslation()
  const [qty, setQty] = useState<number>(0)

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      onRenewWithQty(qty)
    }
  }
  return (
    <Box ml={2}>
      <Popover placement="bottom-end">
        <ButtonGroup isAttached colorScheme="blue" variant="solid">
          <Button mr="-px" onClick={onRenew}>
            {t('prescription.renew')}
          </Button>
          <PopoverTrigger>
            <IconButton
              aria-label="Add to friends"
              icon={<MdKeyboardArrowDown />}
            />
          </PopoverTrigger>
        </ButtonGroup>

        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>
            {t('prescription.renewWithCustom')}
          </PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Input
              type="number"
              value={qty}
              onChange={(e) => setQty(+e.target.value)}
              placeholder="quantity"
              onKeyDown={handleOnKeyDown}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
