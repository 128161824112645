import React from 'react'
import Select from 'react-select'
import { Box } from '@chakra-ui/react'
import { ISelectValueProps } from '@/utils/types'
import { useTranslation } from '@/utils/i18n'

export interface IEditScript {
  onChange: (valye: ISelectValueProps) => void
  defaultValue: string
}

export const EditScript = ({
  onChange,
  defaultValue
}: IEditScript) => {
  const t = useTranslation()
  return (
    <Box minW="150px" fontSize="small">
      <Select
        defaultValue={{
          label: defaultValue,
          value: defaultValue
        }}
        options={[
          { label: t('common.writtenScript'), value: 'writtenScript' },
          { label: t('common.verbalScript'), value: 'verbalScript' }
        ]}
        onChange={onChange}
      />
    </Box>
  )
}
