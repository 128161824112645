import { Box } from '@chakra-ui/react'
import React from 'react'
import { UserAddress } from './userAddress'

export const UserMore = () => {
  return (
    <Box>
      <UserAddress />
    </Box>
  )
}
