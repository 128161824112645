import { Box, ButtonGroup, Flex, IconButton, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdCheck, MdClose, MdModeEdit } from 'react-icons/md'

export interface IEditPriceProps {
  price: number
  onSubmit: (price: number) => void
}

export const EditPrice = ({ price, onSubmit }: IEditPriceProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [value, setValue] = useState<string>(String(price)) // Change type to string

  const handleInputOnChange = (stringValue: string) => {
    setValue(stringValue)
  }

  const handleSave = () => {
    setIsEditing(false)

    onSubmit(Math.round(Number(value) * 100))
  }

  const handleCancel = () => setIsEditing(false)

  const handleEdit = () => setIsEditing(true)

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') handleSave()
  }

  return (
    <Box display="flex" alignItems="center">
      {!isEditing && (
        <Text fontSize="md" fontWeight="medium" mr={2}>
          ${value}
        </Text>
      )}
      {isEditing && (
        <NumberInput precision={2} step={0.1} placeholder="price" value={String(value)} onChange={handleInputOnChange} onKeyUp={handleKeyUp}>
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      )}
      {isEditing ? (
        <ButtonGroup justifyContent="center" size="sm" ml={2}>
          <IconButton aria-label="check" icon={<MdCheck />} onClick={handleSave} />
          <IconButton aria-label="uncheck" icon={<MdClose />} onClick={handleCancel} />
        </ButtonGroup>
      ) : (
        <Flex justifyContent="center" ml={2}>
          <IconButton aria-label="uncheck" size="sm" color="gray.500" fontSize="md" icon={<MdModeEdit />} onClick={handleEdit} />
        </Flex>
      )}
    </Box>
  )
}
