import { gql, useQuery } from '@apollo/client'

const LATEST_PRESCRIPTION_ID = gql`
  query getLatestPrescriptionId {
    getLatestPrescriptionId
  }
`

export const useGetLatestPrescriptionId = () => {
  const { data, error, loading } = useQuery(LATEST_PRESCRIPTION_ID, {
    fetchPolicy: 'network-only'
  })

  return {
    id: data?.getLatestPrescriptionId,
    error,
    loading
  }
}
