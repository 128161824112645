import { useDeleteAllergyOrIntolerance } from '@/hooks/useDeleteAllergyOrIntolerance'
import { useDeletePathology } from '@/hooks/useDeletePathology'
import { useGetGENCodeName } from '@/hooks/useGetGENCodeName'
import { useGetPathologyInfo } from '@/hooks/useGetPathologyInfo'
import {
  Box,
  VStack,
  Text,
  HStack,
  IconButton
} from '@chakra-ui/react'
import React from 'react'
import { MdClose } from 'react-icons/md'
import { useConfirm } from '../dialogProvider/useConfirm'
import ContentLoader from 'react-content-loader'
import { useTranslation } from '@/utils/i18n'

export interface IGenericViewProps {
  userId: string
  gencode?: string
  pathologyId?: number
  descriptor: string
  type: 'allergy' | 'intolerance' | 'pathology'
  userRefetch: () => void
  removable?: boolean
}
export const GenericView = ({
  userId,
  gencode,
  pathologyId,
  descriptor,
  type,
  userRefetch,
  removable = true
}: IGenericViewProps) => {
  const t = useTranslation()
  const { confirm } = useConfirm()
  const { data, loading } =
    type === 'pathology'
      ? useGetPathologyInfo({ pathologyId })
      : useGetGENCodeName({
          GENcode: gencode as string
        })

  const {
    deleteAllergyIntolerance,
    deleteAllergyIntoleranceQuery
  } = useDeleteAllergyOrIntolerance()

  const {
    deletePathology,
    deletePathologyQuery
  } = useDeletePathology()

  const handleRemove = () =>
    type === 'pathology'
      ? deletePathology({
          variables: {
            oAuthId: userId,
            pathologyId: pathologyId
          }
        }).then((d) => userRefetch())
      : deleteAllergyIntolerance({
          variables:
            type === 'allergy'
              ? {
                  oAuthId: userId,
                  allergyGENcode: gencode
                }
              : {
                  oAuthId: userId,
                  intoleranceGENcode: gencode
                }
        }).then((d) => userRefetch())

  if (loading) {
    return (
      <Box w="full">
        <HStack
          spacing={6}
          height="85px"
          alignItems="start"
          justifyContent="space-between"
          borderWidth={1}
          bg="white"
          borderRadius={4}
          p={2}
        >
          <ContentLoader
            speed={2}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect
              x="2"
              y="12"
              rx="0"
              ry="0"
              width="107"
              height="17"
            />
            <rect
              x="1"
              y="40"
              rx="0"
              ry="0"
              width="155"
              height="17"
            />
          </ContentLoader>
        </HStack>
      </Box>
    )
  }

  return (
    <Box w="full">
      <HStack
        spacing={6}
        alignItems="start"
        justifyContent="space-between"
        borderWidth={1}
        bg="white"
        borderRadius={4}
        p={2}
      >
        <VStack alignItems="start" spacing={0} mb={1} ml={1}>
          <Text fontSize="md" color="gray.800" fontWeight="semibold">
            {type === 'pathology'
              ? data?.getPathology?.pathologyNameEn
              : data?.getGenericInfo?.genericNameEn}
          </Text>
          <Text fontSize="sm" color="gray.500">
            {descriptor}
          </Text>
        </VStack>
        {removable && (
          <IconButton
            aria-label="remove"
            icon={<MdClose />}
            onClick={() => {
              confirm({
                title: t('medicalHistory.areYouSureYouWantToDelete'),
                confirmText: t('common.delete'),
                onConfirm: () => handleRemove(),
                colorScheme: 'red'
              })
            }}
            isLoading={
              type === 'pathology'
                ? deletePathologyQuery?.loading
                : deleteAllergyIntoleranceQuery?.loading
            }
            size="sm"
          />
        )}
      </HStack>
    </Box>
  )
}
