import { gql, useMutation } from '@apollo/client'
import { useErrorToast, useSuccessToast } from '.'
import { useTranslation } from '../utils'

const CREATE_NEW_USER = gql`
  mutation createUser($userInput: UserInput!) {
    createUser(userInput: $userInput) {
      firstName
      lastName
    }
  }
`
export const useCreateNewUser = () => {
  const t = useTranslation()
  const [createNewUser, createNewUserQuery] = useMutation(CREATE_NEW_USER)
  useErrorToast({
    error: createNewUserQuery?.error,
    message: t('common.failToCreateANewUser')
  })

  useSuccessToast({
    success: !!createNewUserQuery?.data,
    message: t('common.userCreated')
  })

  return {
    createNewUser,
    createNewUserQuery
  }
}
