import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from './useErrorToast'
import { useSuccessToast } from './useSuccessToast'

const EDIT_TASK = gql`
  mutation EditTask(
    $taskId: Int!
    $title: String
    $oAuthId: String
    $type: Int
    $payload: JSON
    $priority: TaskPriority
    $description: String
    $assignedTo: JSON
    $attachedFileIds: JSON
    $dueDate: String
    $status: TaskStatus
  ) {
    editTask(
      taskId: $taskId
      title: $title
      oAuthId: $oAuthId
      type: $type
      payload: $payload
      priority: $priority
      description: $description
      assignedTo: $assignedTo
      attachedFileIds: $attachedFileIds
      dueDate: $dueDate
      status: $status
    ) {
      taskId
    }
  }
`

export const useEditTask = () => {
  const t = useTranslation()
  const [editTask, editTaskQuery] = useMutation(EDIT_TASK)
  useErrorToast({ error: editTaskQuery?.error })
  useSuccessToast({
    success: !!editTaskQuery?.data,
    message: t('common.taskUpdatedSuccessfully')
  })
  return { editTask, editTaskQuery }
}
