import React, { useState } from 'react'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { BiSearch } from 'react-icons/bi'
import _ from 'lodash'
import { useTranslation } from '@/utils/i18n'

export interface ISearchBox {
  searchValue: string
  placeholder?: string
  onSearch: (query: string) => void
  fullWidth?: boolean
  variant?: 'outline' | 'solid'
  inputGroupProps?: Record<string, any>
}
export const SearchBox = ({ onSearch, fullWidth = false, placeholder, variant = 'outline' }: ISearchBox) => {
  const t = useTranslation()
  const [debounceSearch] = useState<any>(() => _.debounce(onSearch, 600))
  const [input, setInput] = useState<string>('')

  const handleInputOnChange = (e) => {
    setInput(e.target.value)
    debounceSearch(e.target.value)
  }
  return (
    <InputGroup backgroundColor={variant === 'outline' ? 'white' : 'gray.50'} borderRadius="md" width={fullWidth ? 'full' : '200px'} size="sm" overflow="hidden">
      <InputLeftElement pointerEvents="none" children={<BiSearch color="gray.300" />} />
      <Input type="text" borderRadius="md" placeholder={placeholder || t('common.search')} value={input} onChange={handleInputOnChange} />
    </InputGroup>
  )
}
