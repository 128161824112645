import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const SETTLEMENT = gql`
  query($orderId: String!) {
    getSettlement(orderId: $orderId) {
      settlement
    }
  }
`

export interface IUseGetSettlementProps {
  orderId: string
}

export const useGetSettlement = ({ orderId }: IUseGetSettlementProps) => {
  const settlementQuery = useQuery(SETTLEMENT, {
    variables: {
      orderId
    },
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: settlementQuery?.error })

  return settlementQuery
}
