import React, { useState } from 'react'
import { useTranslation } from '@/utils/i18n'
import { Box, Button, Checkbox, CheckboxGroup, Divider, HStack, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react'
import { MdFilterAlt } from 'react-icons/md'

export type Element<T> = {
  value: T;
  text: string;
}

export interface IUseMultiSelectFilterProps<T> {
  elements: Element<T>[];
  filterPropertyDisplay?: string;
  selectedElements: Element<T>[];
  isLoading: boolean;
  requiresAtLeastOne?: boolean
  onFilterChange: (elements: Element<T>[]) => void;
}

export const useMultiSelectFilter = <T extends unknown>({ elements, selectedElements, isLoading, requiresAtLeastOne, filterPropertyDisplay, onFilterChange }: IUseMultiSelectFilterProps<T>) => {
  const t = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const Filter = () => {
    return (
      <Menu isOpen={isOpen} closeOnSelect={false} onClose={() => setIsOpen(false)}>
        <MenuButton size="sm" bg="white" borderWidth={1} onClick={() => setIsOpen(true)}>
          <HStack>
            <MdFilterAlt />
            {
              filterPropertyDisplay && <Text>{filterPropertyDisplay}</Text>
            }
          </HStack>
        </MenuButton>
        <MenuList>
          
            <Box p={2}>
              <Checkbox disabled={isLoading} isChecked={elements.length === selectedElements.length} onChange={(_) => {
                if (elements.length === selectedElements.length) {
                  onFilterChange([]);
                } else {
                  onFilterChange([...elements])
                }
              }}>
                {t('common.selectAll')}
              </Checkbox>
              <Divider mt={2}/>
            </Box>
            <Box maxHeight={300} overflow="scroll">

              {
                elements.map(ele => {
                  return (
                    <Box key={JSON.stringify(ele.value)} p={2}>
                      <Checkbox
                        value={JSON.stringify(ele.value)}
                        disabled={isLoading}
                        isChecked={selectedElements.some(({ value }) => ele.value === value)}
                        onChange={(e) => {
                          if (selectedElements.some(({ value }) => ele.value === value)) {
                            const sub = selectedElements.filter(selEle => selEle.value !== ele.value);
                            if (requiresAtLeastOne) {
                              onFilterChange(sub.length === 0 ? elements : sub)
                            } else {
                              onFilterChange(sub)
                            }

                          } else {
                            onFilterChange([...selectedElements, ele])
                          }
                        }}
                      >
                        {ele.text}
                      </Checkbox>
                    </Box>
                  )
                })
              }

            </Box>
        </MenuList>
      </Menu>
    )
  }

  return { Filter }
}
