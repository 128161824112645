/* eslint-disable node/no-callback-literal */
import _ from 'lodash'
import AsyncSelect from 'react-select/async'
import React, { useState } from 'react'
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/react'
import { useEditUser } from '@/hooks/useEditUser'
import { useFormik } from 'formik'
import { useGetAllergies } from '@/hooks/useGetAllAllergies'
import { useGetAllPathologies } from '@/hooks/useGetAllPathologies'
import { useTranslation } from '@/utils/i18n'

export interface IMedicalInfoPopoverProps {
  userId: string
  onRefetch: () => void
  type: 'allergy' | 'intolerance' | 'pathology'
}
export const MedicalInfoPopover = ({
  userId,
  onRefetch,
  type
}: IMedicalInfoPopoverProps) => {
  const t = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const open = () => setIsOpen(!isOpen)
  const close = () => setIsOpen(false)
  const [inputValue, setInputValue] = useState<string>('')
  const suggestions = type === 'pathology'
    ? useGetAllPathologies({ query: inputValue })
    : useGetAllergies({ query: inputValue })

  const { editUser, editUserQuery } = useEditUser()

  const handleFormSubmit = (values) => {
    const v = { descriptor: values.descriptor, oAuthId: userId }
    editUser({
      variables:
        type === 'allergy'
          ? {
              allergyGENcode: values.allergyGENcode,
              ...v
            }
          : type === 'intolerance'
          ? {
              intoleranceGENcode: values.intoleranceGENcode,
              ...v
            }
          : {
              pathologyId: +values.pathologyId,
              ...v
            }
    }).then((result) => {
      onRefetch()
      formik.resetForm()
      setInputValue('')
      close()
    })
  }

  const formik = useFormik({
    initialValues:
      type === 'allergy'
        ? {
            allergyGENcode: '',
            descriptor: ''
          }
        : type === 'intolerance'
        ? {
            intoleranceGENcode: '',
            descriptor: ''
          }
        : {
            pathologyId: '',
            descriptor: ''
          },
    onSubmit: handleFormSubmit
  })

  const handleInputChange = (newValue: any) => {
    setInputValue(newValue)
    return newValue
  }

  const debounceInput = _.debounce(handleInputChange, 300)

  const loadOptions = (inputValue, callback) => {
    suggestions?.data?.allergies?.length &&
      callback(
        suggestions?.data?.allergies?.map((a) => ({
          label: a.genericNameEn + ' | ' + a.genericNameFr,
          value: a.GENcode
        }))
      )

    suggestions?.data?.pathologies?.length &&
      callback(
        suggestions?.data?.pathologies?.map((p) => ({
          label: p.pathologyNameEn + ' | ' + p.pathologyNameFr,
          value: p.pathologyId
        }))
      )
  }

  const handleChange = (newValue: any, actionMeta: any) => {
    formik.setFieldValue(
      type === 'allergy'
        ? 'allergyGENcode'
        : type === 'intolerance'
        ? 'intoleranceGENcode'
        : 'pathologyId',
      newValue?.value
    )
  }

  const getTitle = () => {
    return type === 'allergy'
      ? t('profile.addAllergey')
      : type === 'intolerance'
      ? t('profile.addIntolerance')
      : t('profile.addPathology')
  }

  return (
    <Popover placement="auto" isOpen={isOpen} onClose={close}>
      <PopoverTrigger>
        <Button onClick={() => open()}>{getTitle()}</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{getTitle()}</PopoverHeader>
        <PopoverBody>
          <form onSubmit={formik.handleSubmit}>
            <FormControl id="email">
              <FormLabel>{getTitle()}</FormLabel>
              <AsyncSelect
                isClearable
                placeholder={t('common.selectOption')}
                onChange={handleChange}
                onInputChange={debounceInput}
                loadOptions={loadOptions}
              />
            </FormControl>
            <FormControl id="email" my={2}>
              <FormLabel>Description</FormLabel>
              <Input
                type="text"
                name="descriptor"
                onChange={formik.handleChange}
                value={formik.values.descriptor}
              />
              <FormHelperText>
                {`${type === 'pathology' ? t('medicalHistory.notePart1a') : t('medicalHistory.notePart1an')} ${t(`medicalHistory.${type}`)} ${t('medicalHistory.notePart2')}`}
              </FormHelperText>
            </FormControl>
            <Button
              colorScheme="blue"
              variant="solid"
              type="submit"
              isLoading={editUserQuery?.loading}
            >
              {t('common.add')}
            </Button>
          </form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
