import * as Yup from 'yup'
import React from 'react'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack
} from '@chakra-ui/react'
import { IAddress } from '@/utils/types'
import { useFormik } from 'formik'
import { useTranslation } from '@/utils/i18n'
import { provinces } from '../../../utils'

export interface IAddUpdateAddressProps {
  isOpen: boolean
  onClose: () => void
  address?: IAddress
  onSubmit: (value: any) => void
  type: 'update' | 'new'
}

const validationSchema = Yup.object().shape({
  label: Yup.string().required('Required'),
  streetAddressLineOne: Yup.string().required('Required'),
  streetAddressLineTwo: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  province: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required')
})

export const AddUpdateAddress = ({ isOpen, onClose, address, onSubmit, type }: IAddUpdateAddressProps) => {
  const t = useTranslation()
  const handleFormSubmit = (values) => {
    const { defaultAddress, ...restValues } = values
    type === 'update' ? onSubmit(values) : onSubmit(restValues)
    onClose()
    formik.resetForm()
  }
  const formik = useFormik({
    initialValues: {
      label: address?.label,
      streetAddressLineOne: address?.streetAddressLineOne,
      streetAddressLineTwo: address?.streetAddressLineTwo,
      city: address?.city,
      province: address?.province,
      postalCode: address?.postalCode,
      defaultAddress: address?.defaultAddress
    },
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit
  })
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{type === 'update' ? t('profile.editAddress') : t('profile.addNewAddress')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form>
            <VStack spacing={2}>
              <FormControl id="label" isInvalid={!!formik.errors.label}>
                <FormLabel>{t('profile.label')}</FormLabel>
                <Input type="text" name="label" placeholder={t('profile.addressLabel')} value={formik.values.label} onChange={formik.handleChange} />
                <FormErrorMessage>{formik.errors.label}</FormErrorMessage>
              </FormControl>
              <FormControl id="streetAddressLineOne" isInvalid={!!formik.errors.streetAddressLineOne}>
                <FormLabel>{t('profile.address1')}</FormLabel>
                <Input type="text" name="streetAddressLineOne" value={formik.values.streetAddressLineOne} placeholder={t('common.address')} onChange={formik.handleChange} />
                <FormErrorMessage>{formik.errors.streetAddressLineOne}</FormErrorMessage>
              </FormControl>
              <FormControl id="streetAddressLineTwo" isInvalid={!!formik.errors.streetAddressLineTwo}>
                <FormLabel>{t('profile.address2')}</FormLabel>
                <Input type="text" name="streetAddressLineTwo" placeholder={t('profile.apartmentOrUnit')} value={formik.values.streetAddressLineTwo} onChange={formik.handleChange} />
                <FormErrorMessage>{formik.errors.streetAddressLineTwo}</FormErrorMessage>
              </FormControl>
              <Grid templateColumns="repeat(2, 1fr)" gap={4} w="full">
                <FormControl id="city" isInvalid={!!formik.errors.city}>
                  <FormLabel>{t('common.city')}</FormLabel>
                  <Input type="text" name="city" value={formik.values.city} placeholder={t('profile.exampleMontreal')} onChange={formik.handleChange} />
                  <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                </FormControl>
                <FormControl id="province" isInvalid={!!formik.errors.province}>
                  <FormLabel>Province</FormLabel>
                  <Select placeholder={t('common.select')} value={formik.values.province} onChange={formik.handleChange}>
                    {provinces.map(({ label, value }) => (
                      <option key={value} value={label}>
                        {t(`provinces.${value}`)}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formik.errors.province}</FormErrorMessage>
                </FormControl>
                <FormControl id="postalCode" isInvalid={!!formik.errors.postalCode}>
                  <FormLabel>{t('common.postalCode')}</FormLabel>
                  <Input type="text" name="postalCode" placeholder="XXX XXX" value={formik.values.postalCode} onChange={formik.handleChange} />
                  <FormErrorMessage>{formik.errors.postalCode}</FormErrorMessage>
                </FormControl>
                {type === 'update' && (
                  <FormControl id="defaultAddress">
                    <FormLabel>{t('common.defaultAddress')}</FormLabel>
                    <Select name="defaultAddress" value={formik.values.defaultAddress} onChange={formik.handleChange}>
                      <option value="notDefault">{t('common.none')}</option>
                      <option value="defaultMail">{t('common.mail')}</option>
                      <option value="defaultDelivery">{t('common.delivery')}</option>
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </VStack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              formik.submitForm()
            }}
          >
            {t('common.save')}
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              onClose()
            }}
          >
            {t('common.cancel')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
