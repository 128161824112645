import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

type Props = { fileUrl: string }

const PDFViewer = ({ fileUrl }: Props) => {
  return (
      <div style={{ maxWidth: '600px', maxHeight: '800px', overflow: 'auto' }}>
        <Document file={fileUrl}>
          <Page pageNumber={1} scale={0.25}/>
        </Document>
    </div>
  )
}

export default PDFViewer
