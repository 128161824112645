import { gql, useLazyQuery, useQuery } from '@apollo/client'

const USER_PHARMACIES = gql`
  query {
    getUserPharmacies {
      pharmacyId
      oAuthId
      pharmacyName
      pharmacyAddress
    }
  }
`

export const useGetUserPharmacies = () => {
  return useQuery(USER_PHARMACIES, {
    fetchPolicy: 'network-only'
  })
}

export const useLazyGetUserPharmacies = () => useLazyQuery(USER_PHARMACIES)
