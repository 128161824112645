import React, { useEffect } from 'react'
import { useRouter } from 'next/router'

export const SignIn = () => {
  const router = useRouter()

  useEffect(() => {
    router.push('/api/auth/login')
  }, [])

  return <></>
}
