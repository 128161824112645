import React from 'react'
import { Grid, GridItem, Button, Modal, ModalContent, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { useTranslation } from '@/utils/i18n'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { FormikValues, useFormik } from 'formik'
import * as Yup from 'yup'
import { useAddDrug, useEditDrug } from '@/hooks'

type Props = {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  entity?: Record<string, any>
}

const CreateDrug = ({ isOpen, onClose, entity, refetch }: Props) => {
  const t = useTranslation()

  const [{ addDrug, addDrugQuery }, { editDrug, editDrugQuery }] = [useAddDrug(), useEditDrug()]

  const loading = addDrugQuery?.loading || editDrugQuery?.loading

  const validationSchema = Yup.object().shape({
    nameEnCapitalized: Yup.string().required(t('newUser.requiredName')),
    strengthEnLowercase: Yup.string().required(t('drugs.requiredStrength'))
  })

  const handleSubmit = async (variables) => {
    const mutate = !entity ? addDrug : editDrug

    try {
      await mutate({
        variables
      })
      onClose?.()
      formik?.resetForm()
      refetch?.()
    } catch (error) {
      return console.error(error?.message)
    }
  }

  const handleSave = async (values: any) => {
    const variables = !entity
      ? {
          drugInput: values,
          defaultPosology: values.defaultPosology
        }
      : {
          drugId: entity?.drugId,
          defaultPosology: values.defaultPosology,
          ...values
        }
    handleSubmit(variables)
  }

  const formik = useFormik<FormikValues>({
    initialValues: {
      nameEnCapitalized: '',
      nameFrCapitalized: '',
      strengthEnLowercase: '',
      strengthFrLowercase: '',
      drugIdentificationNumber: '',
      formEnLowercase: '',
      formFrLowercase: '',
      defaultPosology: '',
      videoUrl: '',
      ...(entity ?? {})
    },
    onSubmit: handleSave,
    validationSchema
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay bg="blackAlpha.700"></ModalOverlay>
      <ModalContent overflow="hidden">
        <ModalHeader>
          {t(!entity ? 'drugs.createDrug' : 'common.edit')} {entity && entity.name}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <GridItem colSpan={1}>
              <FormControl id="nameEnCapitalized" isInvalid={!!formik.errors.nameEnCapitalized}>
                <FormLabel>{t('drugs.nameEnCapitalized')}</FormLabel>
                <Input type="text" placeholder={t('common.drugName')} value={formik.values.nameEnCapitalized} onChange={formik.handleChange} />
                <FormErrorMessage>{!!formik.errors.nameEnCapitalized && formik.errors.nameEnCapitalized}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl id="nameFrCapitalized" isInvalid={!!formik.errors.nameFrCapitalized}>
                <FormLabel>{t('drugs.nameFrCapitalized')}</FormLabel>
                <Input type="text" placeholder={t('common.drugName')} value={formik.values.nameFrCapitalized} onChange={formik.handleChange} />
                <FormErrorMessage>{!!formik.errors.nameFrCapitalized && formik.errors.nameFrCapitalized}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl id="strengthEnLowercase" isInvalid={!!formik.errors.strengthEnLowercase}>
                <FormLabel>{t('drugs.strengthEnLowercase')}</FormLabel>
                <Input type="text" placeholder={t('drugs.strengthEnLowercase')} value={formik.values.strengthEnLowercase} onChange={formik.handleChange} />
                <FormErrorMessage>{!!formik.errors.strengthEnLowercase && formik.errors.strengthEnLowercase}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl id="strengthFrLowercase" isInvalid={!!formik.errors.strengthFrLowercase}>
                <FormLabel>{t('drugs.strengthFrLowercase')}</FormLabel>
                <Input type="text" placeholder={t('drugs.strengthFrLowercase')} value={formik.values.strengthFrLowercase} onChange={formik.handleChange} />
                <FormErrorMessage>{!!formik.errors.strengthFrLowercase && formik.errors.strengthFrLowercase}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl id="formEnLowercase" isInvalid={!!formik.errors.formEnLowercase}>
                <FormLabel>{t('drugs.formEnLowercase')}</FormLabel>
                <Input type="text" placeholder={t('drugs.formEnLowercase')} value={formik.values.formEnLowercase} onChange={formik.handleChange} />
                <FormErrorMessage>{!!formik.errors.formEnLowercase && formik.errors.formEnLowercase}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl id="formFrLowercase" isInvalid={!!formik.errors.formFrLowercase}>
                <FormLabel>{t('drugs.formFrLowercase')}</FormLabel>
                <Input type="text" placeholder={t('drugs.formFrLowercase')} value={formik.values.formFrLowercase} onChange={formik.handleChange} />
                <FormErrorMessage>{!!formik.errors.formFrLowercase && formik.errors.formFrLowercase}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl id="drugIdentificationNumber" isInvalid={!!formik.errors.drugIdentificationNumber}>
                <FormLabel>{t('drugs.dinOrNpn')}</FormLabel>
                <Input type="text" placeholder={t('drugs.dinOrNpnText')} value={formik.values.drugIdentificationNumber} onChange={formik.handleChange} />
                <FormErrorMessage>{!!formik.errors.drugIdentificationNumber && formik.errors.drugIdentificationNumber}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl id="defaultPosology" isInvalid={!!formik.errors.defaultPosology}>
                <FormLabel>{t('drugs.defaultPosology')}</FormLabel>
                <Input type="text" placeholder={t('drugs.defaultPosology')} value={formik.values.defaultPosology} onChange={formik.handleChange} />
                <FormErrorMessage>{!!formik.errors.defaultPosology && formik.errors.defaultPosology}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl id="videoUrl" isInvalid={!!formik.errors.videoUrl}>
                <FormLabel>{t('drugs.videoUrl')}</FormLabel>
                <Input type="text" placeholder={t('drugs.videoUrl')} value={formik.values.videoUrl} onChange={formik.handleChange} />
                <FormErrorMessage>{!!formik.errors.videoUrl && formik.errors.videoUrl}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button variant="solid" size="sm" colorScheme="blue" isLoading={loading} onClick={() => formik.handleSubmit()}>
            {t('common.save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CreateDrug
