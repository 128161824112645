import { useEffect, useMemo, useState } from 'react'

export interface IUsePaginate<T> {
  page?: number
  size?: number
  data: T[]
  serverSide?: boolean
  totalCount?: number
  onPageUpdate?: (page: number) => void
  onSizeUpdate?: (size: number) => void
}
export const usePaginate = <T>({ page = 0, size = 10, data, totalCount, serverSide, onPageUpdate, onSizeUpdate }: IUsePaginate<T>) => {
  const [pages, setPages] = useState<Array<T[]>>([])
  const [currentPageData, setCurrentPageData] = useState<T[]>([])
  const estimatedPages = useMemo(() => {
    return serverSide && totalCount && Math.ceil(totalCount / size)
  }, [serverSide, totalCount])
  const [currentSize, setCurrentSize] = useState<number>(size)
  const [currentPage, setCurrentPage] = useState<number>(page)
  const chunk = (arr: T[], size: number) =>
    arr.reduce(
      (acc: Array<T[]>, e: T, i: number) => (
        // eslint-disable-next-line no-sequences
        i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc
      ),
      []
    )

  useEffect(() => {
    !data?.length && setCurrentPageData([])
    currentSize && data?.length && setPages(chunk(data, currentSize))
  }, [currentSize, data])

  useEffect(() => {
    !estimatedPages && pages?.length > 0 && setCurrentPageData(pages[currentPage])
    estimatedPages && setCurrentPageData(data)
  }, [currentPage, pages])

  useEffect(() => {
    onPageUpdate && onPageUpdate(currentPage)
  }, [currentPage])

  useEffect(() => {
    onSizeUpdate && onSizeUpdate(currentSize)
  }, [currentSize])

  return {
    hasNextPage: !estimatedPages ? currentPage < pages.length - 1 : currentPage < estimatedPages - 1,
    hasFirstPage: currentPage !== 0,
    currentPage,
    hasPreviousPage: currentPage > 0,
    hasLastPage: !estimatedPages ? currentPage !== pages.length - 1 : currentPage !== estimatedPages - 1,
    pages,
    currentPageData,
    next: () => {
      !estimatedPages && currentPage < pages.length && setCurrentPage((prev) => prev + 1)
      estimatedPages && currentPage < estimatedPages && setCurrentPage((prev) => prev + 1)
    },
    previous: () => {
      currentPage > 0 && setCurrentPage((prev) => prev - 1)
    },
    firstPage: () => {
      currentPage !== 0 && setCurrentPage(0)
    },
    lastPage: () => {
      !estimatedPages && currentPage !== pages.length - 1 && setCurrentPage(pages.length - 1)
      estimatedPages && currentPage !== estimatedPages && setCurrentPage(estimatedPages - 1)
    },
    updateSize: (size: number) => setCurrentSize(size),
    gotoPage: (pageNumber: number) => setCurrentPage(pageNumber),
    totalCount: totalCount || data?.length || '-',
    pageSize: currentSize
  }
}
