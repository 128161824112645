import { useGetInsuranceById } from '@/hooks/useGetInsuranceById'
import { useGetTransferById } from '@/hooks/useGetTransferById'
import { useTranslation } from '@/utils/i18n'
import { IInsurance, ITask, ITransfer } from '@/utils/types'
import { Box, Text, VStack } from '@chakra-ui/layout'
import moment from 'moment'
import React from 'react'
import { LabelInfo } from '..'
import { TaskImages } from './taskImages'
import { TransferTask } from './transferTask'

export interface IInsuranceTaskProps {
  task: ITask
}

const InsuranceTask = ({ task }: IInsuranceTaskProps) => {
  const t = useTranslation()
  const { data } = useGetInsuranceById({
    insuranceId: task?.data?.insuranceId
  })

  const insurance: IInsurance = data?.getInsurance
  return (
    <Box>
      <Text>
        {t('tasks.insurance')} #{insurance?.insuranceId}
      </Text>
      {insurance && <TaskImages imgIds={insurance?.imageIds} />}
    </Box>
  )
}

const MedicalInfo = ({ task }: IInsuranceTaskProps) => {
  const t = useTranslation()

  return (
    <VStack alignItems="start" spacing={2} p={4} bg="gray.50">
      <Text fontSize="md" fontWeight="semibold" color="gray.700">
        {t('tasks.medicalInfo')}
      </Text>
      <LabelInfo
        size="md"
        label={t('tasks.allergies')}
        value={task?.data?.allergies || t('common.none')}
        orientation="horizontal"
      />
      <LabelInfo
        size="md"
        label={t('tasks.intolerances')}
        value={task?.data?.intolerances || t('common.none')}
        orientation="horizontal"
      />
      <LabelInfo
        size="md"
        label={t('tasks.pregnant')}
        value={
          task?.data?.medicalConditions?.pregnant || t('common.no')
        }
        orientation="horizontal"
      />
      <LabelInfo
        size="md"
        label={t('tasks.breastfeeding')}
        value={
          task?.data?.medicalConditions?.breastfeeding ||
          t('common.no')
        }
        orientation="horizontal"
      />
      <LabelInfo
        size="md"
        label={t('tasks.medicalConditions')}
        value={
          task?.data?.medicalConditions?.medicalConditions ||
          t('common.none')
        }
        orientation="horizontal"
      />
      <LabelInfo
        size="md"
        label={t('tasks.rxOrOTC')}
        value={
          task?.data?.medicalConditions?.rxOrOTC || t('common.none')
        }
        orientation="horizontal"
      />
    </VStack>
  )
}

const PrescriptionTask = ({ task }: IInsuranceTaskProps) => {
  const { data } = useGetTransferById({
    transferId: task?.data?.transferId
  })
  const t = useTranslation()

  const transfer: ITransfer = data?.getTransfer

  return (
    <VStack alignItems="start" spacing={2} p={4} bg="gray.50">
      <Text fontSize="sm" fontWeight="medium">
        {t('tasks.establishment')}
      </Text>
      <Box>
        <LabelInfo
          size="md"
          label={t('tasks.phoneNumber')}
          value={transfer?.pharmacyPhoneNumber || t('common.none')}
          orientation="horizontal"
        />
        <LabelInfo
          size="md"
          label={t('tasks.notes')}
          value={transfer?.comments || t('common.none')}
          orientation="horizontal"
        />
        {transfer && <TaskImages imgIds={transfer?.imageIds} />}
      </Box>
    </VStack>
  )
}

const TaskAddressUpdate = ({ task }: IInsuranceTaskProps) => {
  const t = useTranslation()

  const data = task?.data

  return (
    <VStack alignItems="start" spacing={2} p={4} bg="gray.50">
      <LabelInfo
        size="md"
        label={t('tasks.createdAt')}
        value={moment(data?.createdAt).format('LLL')}
        orientation="horizontal"
      />
      <Box>
        <LabelInfo
          size="md"
          label={t('tasks.address')}
          value={
            data?.address
              ? `${data?.address?.label} 
              ${data?.address?.streetAddressLineOne},
              ${data?.address?.city},${data?.address?.province},
              ${data?.address?.postalCode}`
              : t('common.none')
          }
          orientation="horizontal"
        />
      </Box>
    </VStack>
  )
}

const TaskDeleteInsurance = ({ task }: IInsuranceTaskProps) => {
  const t = useTranslation()
  const data = task?.data

  return (
    <VStack alignItems="start" spacing={2} p={4} bg="gray.50">
      <Box>
        <LabelInfo
          size="md"
          label={t('tasks.insuranceName')}
          value={data?.insuranceName}
          orientation="horizontal"
        />
      </Box>
    </VStack>
  )
}

const TaskPhotoIdVerifier = ({ task }: IInsuranceTaskProps) => {
  const t = useTranslation()

  const data = task?.data
  return (
    <VStack alignItems="start" spacing={2} p={4} bg="gray.50">
      <Box>
        <LabelInfo
          size="md"
          label={t('tasks.idToVerify')}
          value={data?.verifiedPhotoId}
          orientation="horizontal"
        />
        {data?.photoId && <TaskImages imgIds={[data?.photoId]} />}
      </Box>
    </VStack>
  )
}

const TaskDetailOrder = ({ task }: IInsuranceTaskProps) => {
  const t = useTranslation()

  const order = task?.data?.order

  return (
    <VStack alignItems="start" spacing={2} p={4} bg="gray.50">
      <LabelInfo
        size="md"
        label={t('tasks.createdAt')}
        value={moment(order?.createdAt).format('LLL')}
        orientation="horizontal"
      />
      <LabelInfo
        size="md"
        label={t('tasks.for')}
        value={moment(order?.time).format('LLL')}
        orientation="horizontal"
      />
      <LabelInfo
        size="md"
        label={t('tasks.type')}
        value={order?.orderType}
        orientation="horizontal"
      />
      <LabelInfo
        size="md"
        label={t('common.comment')}
        value={order?.comment}
        orientation="horizontal"
      />
    </VStack>
  )
}

const TaskOrderPaid = ({ task }: IInsuranceTaskProps) => {
  const t = useTranslation()

  const data = task?.data

  return (
    <VStack alignItems="start" spacing={2} p={4} bg="gray.50">
      <LabelInfo
        size="md"
        label={t('tasks.orderPaid')}
        value={`${t('tasks.order')} #${data?.orderId}`}
        orientation="horizontal"
      />
    </VStack>
  )
}

const TaskNewNotification = ({ task }: IInsuranceTaskProps) => {
  const t = useTranslation()

  const data = task?.data

  return (
    <VStack alignItems="start" spacing={2} p={4} bg="gray.50">
      <LabelInfo
        size="md"
        label={t('tasks.notificationSent')}
        value={`${data?.notification}`}
        orientation="horizontal"
      />
    </VStack>
  )
}

export const Tasks = (task: any) => ({
  insurance: <InsuranceTask task={task} />,
  transfer: <TransferTask transferId={task?.data?.transferId} />,
  toVerifyByPharmacist: <PrescriptionTask task={task} />,
  order: <TaskDetailOrder task={task} />,
  paid: <TaskOrderPaid task={task} />,
  medicalInfo: <MedicalInfo task={task} />,
  newUser: <MedicalInfo task={task} />,
  idVerification: <TaskPhotoIdVerifier task={task} />,
  addressUpdate: <TaskAddressUpdate task={task} />,
  addressDeleted: <TaskAddressUpdate task={task} />,
  addressAdded: <TaskAddressUpdate task={task} />,
  deleteInsurance: <TaskDeleteInsurance task={task} />,
  newNotification: <TaskNewNotification task={task} />
})
