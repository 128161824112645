import React from 'react'
import { AddUpdateAddress } from './addUpdateAddress'
import {
  Badge,
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IAddress } from '@/utils/types'
import {
  MdArchive,
  MdDelete,
  MdModeEdit,
  MdMoreHoriz
} from 'react-icons/md'
import { useConfirm } from '@/components/dialogProvider/useConfirm'
import { useTranslation } from '@/utils/i18n'

export interface IAddressBoxProps {
  address: IAddress
  onEdit: (a: any, addressId: number) => void
  onDelete: (addressId: number) => void
  onArchive: (addressId: number) => void
}
export const AddressBox = ({
  address,
  onEdit,
  onDelete,
  onArchive
}: IAddressBoxProps) => {
  const t = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { confirm } = useConfirm()

  const handleEdit = () => {
    onOpen()
  }

  const handleDelete = () => {
    confirm({
      title: t('common.areYouSureYouWantToDeleteThis'),
      onConfirm: () => onDelete(address?.addressId),
      colorScheme: 'red',
      confirmText: t('common.delete')
    })
  }

  const handleArchive = () => {
    confirm({
      title: t('common.areYouSureYouWantToArchiveThis'),
      onConfirm: () => onArchive(address?.addressId),
      colorScheme: 'red',
      confirmText: t('common.archive')
    })
  }

  return (
    <>
      <Box
        borderWidth={1}
        borderColor="gray.200"
        p={3}
        bg="gray.50"
        borderRadius={'md'}
      >
        <Flex justifyContent="space-between">
          <HStack spacing={1}>
            <Text fontSize="sm" color="gray.500">
              #{address?.addressId}
            </Text>
          </HStack>
          <HStack>
            <Menu>
              <MenuButton
                as={IconButton}
                variant="outline"
                bg="white"
                aria-label="Options"
                icon={<MdMoreHoriz />}
                size="sm"
                fontSize="lg"
              />
              <MenuList>
                <MenuItem icon={<MdModeEdit />} onClick={handleEdit}>
                  {t('common.edit')}
                </MenuItem>
                <MenuItem icon={<MdDelete />} onClick={handleDelete}>
                  {t('common.delete')}
                </MenuItem>
                <MenuItem
                  icon={<MdArchive />}
                  onClick={handleArchive}
                >
                  {t('common.archive')}
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
        <Text>{address?.streetAddressLineOne}</Text>
        <Text>{address?.streetAddressLineTwo}</Text>
        <HStack spacing={1}>
          <Text>{address?.city}</Text>,
          <Text>{address?.province}</Text>
          <Text>{address?.postalCode}</Text>
        </HStack>
        <HStack spacing={2} mt={2}>
          <Badge colorScheme="blue" variant="solid">
            {address?.label}
          </Badge>
          {address?.defaultAddress !== 'notDefault' && (
            <Badge colorScheme="purple" variant="solid">
              {address?.defaultAddress}
            </Badge>
          )}
        </HStack>
      </Box>
      <AddUpdateAddress
        address={address}
        isOpen={isOpen}
        type="update"
        onSubmit={(v) => onEdit(v, address?.addressId)}
        onClose={onClose}
      />
    </>
  )
}
