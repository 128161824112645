import React, { useState } from 'react'
import { ColDef, DataTable, SearchBox } from '@/components'
import { useRouter } from 'next/router'
import { useUsers } from '@/hooks/useUsers'
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { MdAdd, MdCake, MdEmail, MdLocalPhone, MdMoreHoriz } from 'react-icons/md'
import { IUserProps } from '@/utils/types'
import { useTranslation } from '@/utils/i18n'
import { NewUserModal } from './newUserModal'
import { useGetMeContext } from '@/contexts'

export const Users = () => {
  const [searchText, setSearchText] = useState<string>('')
  const router = useRouter()
  const isSuperAdminRoute = router.asPath.startsWith('/super-admin')

  const { data, loading } = useUsers({ query: searchText, isSuperAdmin: isSuperAdminRoute })
  const t = useTranslation()
  const { me } = useGetMeContext()

  const users = data?.[isSuperAdminRoute ? 'allSystemUsers' : 'users']

  const { isOpen, onClose, onOpen } = useDisclosure()

  const renderUser = (row: IUserProps) => {
    return (
      <Box width="full" borderBottomWidth={1} borderColor="gray.100" p={4} cursor="pointer" _hover={{ bg: 'blue.50' }}>
        <Flex justifyContent="space-between" alignItems="center">
          <HStack
            spacing={4}
            onClick={() => {
              router.push(`/users/${row?.oAuthId?.substring(6)}`)
            }}
          >
            <Avatar fontWeight="normal" color="white" name={`${row?.firstName} ${row?.lastName}`} src={row?.avatar} />
            <VStack alignItems="start">
              <HStack spacing={2}>
                <Text fontSize="md" fontWeight="semibold" cursor="pointer">{`${row?.firstName} ${row?.lastName}`}</Text>
              </HStack>

              <HStack spacing={4} fontSize="sm" color="gray.500">
                <HStack>
                  <MdLocalPhone />
                  <Text>{row?.phoneNumber}</Text>
                </HStack>
                <HStack>
                  <MdEmail />
                  <Text>{row?.email}</Text>
                </HStack>
                <HStack>
                  <MdCake />
                  <Text>{row?.birthday}</Text>
                </HStack>
              </HStack>
            </VStack>
          </HStack>
          <Menu>
            <MenuButton as={IconButton} size="sm" fontSize="lg" aria-label="Options" icon={<MdMoreHoriz />}
                        variant="outline" />
            <MenuList fontSize="md">
              <MenuItem onClick={() => router.push(`/messages/${row?.oAuthId.substring(6)}`)}>Message</MenuItem>
              <MenuItem
                onClick={() => router.push(isSuperAdminRoute ? `/super-admin/establishments/${me?.pharmacyId}/edit-user/${row.oAuthId}` : `/users/${row?.oAuthId.substring(6)}/edit`)}>
                {t('profile.editUser')}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
    )
  }

  const cols: ColDef<any>[] = [
    {
      colName: 'Name',
      render: renderUser,
      key: 'user_email'
    }
  ]

  const handleSearchQuery = (query: string) => {
    setSearchText(query)
  }

  return (
    <Box p={2}>
      <Box px={4} pb={2}>
        <Flex alignItems="center" justifyContent="space-between">
          <SearchBox searchValue={searchText} onSearch={handleSearchQuery} />
          <Button
            leftIcon={<MdAdd />}
            variant="solid"
            size="sm"
            onClick={() => {
              onOpen()
            }}
            colorScheme="blue"
          >
            {t('profile.newUser')}
          </Button>
        </Flex>
      </Box>
      <Box borderColor="gray.200" borderWidth={1} bg="white" borderRadius={10} overflow="hidden" m={4}>
        <DataTable
          rows={users ? [...users].sort((a, b) => a.lastName?.localeCompare(b.lastName)).sort((a, b) => a.firstName?.localeCompare(b.firstName)) : null}
          cols={cols}
          showHeader={false}
          sidePadding={false}
          showRowBorder={false}
          isLoading={loading}
          paginationPosition="center"
        />
      </Box>
      {isOpen && <NewUserModal isOpen={isOpen} onClose={onClose} />}
    </Box>
  )
}
